@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/* BASE COLOR
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
@media screen and (min-width: 1120px) {
  * {
    margin: 0;
    padding: 0; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #1c2a40;
    background: #FFF;
    font-size: 14.5px;
    line-height: 1.8;
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: 1120px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    padding: 0 40px; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 1119px) {
  * {
    margin: 0;
    padding: 0; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #1c2a40;
    background: #FFF;
    font-size: 14px;
    line-height: 1.8;
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    padding: 0 40px; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 768px) {
  * {
    margin: 0;
    padding: 0; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #1c2a40;
    background: #FFF;
    font-size: 13px;
    line-height: 1.8;
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0;
    padding: 0 16px; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

#site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 24px 40px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  z-index: 9999; }
  @media screen and (max-width: 1119px) {
    #site-header {
      padding: 16px; } }
  @media screen and (min-width: 1120px) {
    #site-header.active {
      padding-top: 10px;
      padding-bottom: 10px;
      position: fixed;
      background: #FFF;
      box-shadow: 0 0 32px rgba(28, 42, 64, 0.08);
      animation: shows .6s forwards; }
    @keyframes shows {
      0% {
        transform: translateY(-110%); }
      100% {
        transform: translateY(0); } }
      #site-header.active .gnavi {
        transform: translateY(1px); }
        #site-header.active .gnavi__list li a:before {
          bottom: -25px; }
      #site-header.active .subnavi {
        top: 20px; }
        #site-header.active .subnavi > li:first-child a {
          border: #1c2a40 1px solid; } }
  #site-header .logo {
    width: 100px; }
    @media screen and (max-width: 1119px) {
      #site-header .logo {
        width: 72px;
        transform: translateY(-1px); } }
    #site-header .logo img {
      width: 100%; }
  #site-header .open {
    display: none; }
    @media screen and (max-width: 1119px) {
      #site-header .open {
        display: block;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 44px;
        height: 44px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        background: #1c2a40;
        border-radius: 50%;
        cursor: pointer;
        z-index: 1; }
        #site-header .open:before, #site-header .open:after {
          content: '';
          width: 18px;
          height: 1px;
          background: #f1f4f9;
          position: absolute;
          top: 42%;
          left: 50%;
          transform: translateX(-50%); }
        #site-header .open:after {
          top: auto;
          bottom: 42%;
          transform: translateX(-50%); } }
  #site-header .gnavi {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    padding: 0 0 0 7vw; }
    @media screen and (max-width: 1119px) {
      #site-header .gnavi {
        padding: 0;
        opacity: 0;
        position: relative;
        z-index: 2;
        transition: opacity 800ms; }
        #site-header .gnavi.active {
          opacity: 1; } }
    @media screen and (min-width: 1120px) {
      #site-header .gnavi .toggle {
        display: block !important; } }
    @media screen and (max-width: 1119px) {
      #site-header .gnavi .toggle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #FFF;
        z-index: 9000; } }
    #site-header .gnavi .toggle__close {
      display: none; }
      @media screen and (max-width: 1119px) {
        #site-header .gnavi .toggle__close {
          display: block;
          position: absolute;
          top: 16px;
          right: 16px;
          width: 44px;
          height: 44px;
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          align-items: center;
          justify-content: center;
          background: #f1f4f9;
          border-radius: 50%;
          cursor: pointer;
          z-index: 2; }
          #site-header .gnavi .toggle__close:before, #site-header .gnavi .toggle__close:after {
            content: '';
            width: 20px;
            height: 1px;
            background: #1c2a40;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) rotate(45deg); }
          #site-header .gnavi .toggle__close:after {
            transform: translateX(-50%) translateY(-50%) rotate(-45deg); } }
    #site-header .gnavi__list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      justify-content: flex-start;
      transform: translateY(1px); }
      @media screen and (max-width: 1119px) {
        #site-header .gnavi__list {
          transform: translateY(0);
          align-items: center;
          justify-content: center;
          padding: 72px 0 0;
          height: calc(100% - 150px); } }
      #site-header .gnavi__list li {
        list-style: none;
        position: relative;
        margin: 0 4.4vw 0 0; }
        @media screen and (max-width: 1119px) {
          #site-header .gnavi__list li {
            margin: 0;
            width: 100%; } }
        #site-header .gnavi__list li:last-child {
          margin: 0; }
        #site-header .gnavi__list li.none {
          display: none; }
          @media screen and (max-width: 1119px) {
            #site-header .gnavi__list li.none {
              display: block; } }
        #site-header .gnavi__list li a {
          font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
          font-weight: 500;
          -webkit-font-feature-settings: "palt";
          font-feature-settings: "palt";
          letter-spacing: 0.04em;
          display: block;
          color: #1c2a40;
          text-align: center;
          font-size: 13.5px;
          line-height: 1;
          letter-spacing: .032em;
          position: relative; }
          @media screen and (max-width: 1119px) {
            #site-header .gnavi__list li a {
              padding: 0 16px;
              font-size: 18px; } }
          #site-header .gnavi__list li a:after {
            content: attr(data-eng);
            display: block;
            font-size: 8.5px;
            letter-spacing: .12em;
            margin: 12px 0 0; }
            @media screen and (max-width: 1119px) {
              #site-header .gnavi__list li a:after {
                display: none; } }
          #site-header .gnavi__list li a:before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -8px;
            width: 0;
            height: 2px;
            background: #1c2a40;
            -webkit-transition: all 120ms 0ms ease-in-out;
            -moz-transition: all 120ms 0ms ease-in-out;
            -ms-transition: all 120ms 0ms ease-in-out;
            -o-transition: all 120ms 0ms ease-in-out;
            transition: all 120ms 0ms ease-in-out; }
            @media screen and (max-width: 1119px) {
              #site-header .gnavi__list li a:before {
                display: none; } }
          #site-header .gnavi__list li a:hover:before {
            width: 60px;
            left: 50%;
            transform: translateX(-50%); }
    #site-header .gnavi__sp {
      display: none; }
      @media screen and (max-width: 1119px) {
        #site-header .gnavi__sp {
          padding: 16px;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          display: block; }
          #site-header .gnavi__sp > li {
            margin: 0 0 16px; }
            #site-header .gnavi__sp > li:last-child {
              margin: 0; }
            #site-header .gnavi__sp > li a {
              display: block;
              background: #f1f4f9;
              color: #1c2a40;
              font-size: 15px;
              text-align: center;
              padding: 12px 16px; }
              #site-header .gnavi__sp > li a i {
                display: inline-block;
                margin: 0 8px 0 0; } }
  #site-header .subnavi {
    position: absolute;
    top: 34px;
    right: 40px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 1119px) {
      #site-header .subnavi {
        display: none; } }
    #site-header .subnavi > li a {
      font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      background: #FFF;
      border: #FFF 1px solid;
      color: #1c2a40;
      display: block;
      padding: 10px 32px;
      position: relative;
      z-index: 1;
      transition: border-color 200ms linear; }
      #site-header .subnavi > li a:before {
        content: '';
        width: 0;
        height: 100%;
        background: #1c2a40;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        -webkit-transition: all 200ms 0ms ease-in-out;
        -moz-transition: all 200ms 0ms ease-in-out;
        -ms-transition: all 200ms 0ms ease-in-out;
        -o-transition: all 200ms 0ms ease-in-out;
        transition: all 200ms 0ms ease-in-out; }
      #site-header .subnavi > li a:hover {
        color: #FFF;
        border-color: #1c2a40; }
        #site-header .subnavi > li a:hover:before {
          width: 100%; }
      #site-header .subnavi > li a.reserv {
        background: #1c2a40;
        color: #FFF;
        border-color: #1c2a40;
        transition: border-color 200ms linear, background 200ms linear; }
        #site-header .subnavi > li a.reserv:before {
          display: none; }
        #site-header .subnavi > li a.reserv:hover {
          border-color: #283d5c;
          background: #283d5c; }
      #site-header .subnavi > li a i {
        display: inline-block;
        margin: 0 8px 0 0; }

#addmap {
  position: relative; }
  #addmap iframe {
    width: 100%;
    height: 340px;
    vertical-align: bottom;
    filter: grayscale(1); }
    @media screen and (max-width: 1119px) {
      #addmap iframe {
        height: 240px; } }
    @media screen and (max-width: 768px) {
      #addmap iframe {
        height: 180px; } }

#site-footer {
  padding: 0 0 80px;
  background: #1c2a40;
  color: #FFF; }
  @media screen and (max-width: 1119px) {
    #site-footer {
      padding: 0 0 56px; } }
  @media screen and (max-width: 768px) {
    #site-footer {
      padding: 0 0 48px; } }
  #site-footer #pagetop {
    background: #22334e;
    display: block;
    text-align: center;
    padding: 14px 0;
    margin: 0 0 80px;
    -webkit-transition: all 120ms 0ms ease-in-out;
    -moz-transition: all 120ms 0ms ease-in-out;
    -ms-transition: all 120ms 0ms ease-in-out;
    -o-transition: all 120ms 0ms ease-in-out;
    transition: all 120ms 0ms ease-in-out; }
    @media screen and (max-width: 1119px) {
      #site-footer #pagetop {
        margin: 0 0 56px; } }
    @media screen and (max-width: 768px) {
      #site-footer #pagetop {
        margin: 0 0 48px; } }
    #site-footer #pagetop:hover {
      background: #283d5c; }
  #site-footer a {
    color: #FFF; }
  #site-footer .flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    position: relative; }
    @media screen and (max-width: 1119px) {
      #site-footer .flex {
        align-items: flex-start; } }
    @media screen and (max-width: 768px) {
      #site-footer .flex {
        display: block; } }
  #site-footer .company {
    width: 420px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 1119px) {
      #site-footer .company {
        width: 320px; } }
    @media screen and (max-width: 768px) {
      #site-footer .company {
        width: auto; } }
    #site-footer .company figure {
      width: 120px; }
      @media screen and (max-width: 768px) {
        #site-footer .company figure {
          width: 80px; } }
      #site-footer .company figure img {
        width: 100%; }
    #site-footer .company dl {
      width: calc(100% - 120px);
      padding: 0 0 0 40px; }
      @media screen and (max-width: 768px) {
        #site-footer .company dl {
          padding: 0 0 0 16px;
          width: calc(100% - 80px); } }
      #site-footer .company dl dt {
        margin: 0 0 10px;
        padding: 0 0 10px;
        border-bottom: rgba(255, 255, 255, 0.12) 1px solid; }
        @media screen and (max-width: 768px) {
          #site-footer .company dl dt {
            margin: 0 0 6px;
            padding: 0 0 6px; } }
      #site-footer .company dl dd {
        font-size: 12px; }
        @media screen and (max-width: 768px) {
          #site-footer .company dl dd {
            font-size: 11px; } }
  #site-footer .fnav {
    width: calc(100% - 420px);
    padding: 0 64px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 1119px) {
      #site-footer .fnav {
        width: calc(100% - 320px);
        padding: 0 56px; } }
    @media screen and (max-width: 768px) {
      #site-footer .fnav {
        width: auto;
        margin: 24px 0 0;
        padding: 0;
        display: block;
        border: rgba(255, 255, 255, 0.16) 1px solid;
        border-radius: 4px; } }
    #site-footer .fnav li {
      width: 50%;
      margin: 6px 0 0; }
      @media screen and (max-width: 1119px) {
        #site-footer .fnav li {
          font-size: 13px; } }
      @media screen and (max-width: 768px) {
        #site-footer .fnav li {
          width: auto;
          border-bottom: rgba(255, 255, 255, 0.16) 1px solid;
          margin: 0; }
          #site-footer .fnav li:last-child {
            border: none; } }
      #site-footer .fnav li:nth-child(-n+2) {
        margin-top: 0; }
      #site-footer .fnav li a {
        display: inline-block; }
        @media screen and (max-width: 768px) {
          #site-footer .fnav li a {
            display: block;
            padding: 10px 16px; } }
        #site-footer .fnav li a:before {
          content: '+';
          display: inline-block;
          margin: 0 8px 0 0;
          opacity: .48; }
  #site-footer .sns {
    position: absolute;
    font-size: 13px;
    top: 0;
    right: 0; }
    @media screen and (max-width: 768px) {
      #site-footer .sns {
        position: relative;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center;
        margin: 24px 0; } }
    #site-footer .sns li {
      margin: 0 0 16px; }
      @media screen and (max-width: 768px) {
        #site-footer .sns li {
          margin: 0 16px 0 0; } }
      #site-footer .sns li:last-child {
        margin: 0; }
      #site-footer .sns li a {
        border: rgba(255, 255, 255, 0.16) 1px solid;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 50%;
        display: block;
        color: rgba(255, 255, 255, 0.56);
        -webkit-transition: all 200ms 0ms ease-in-out;
        -moz-transition: all 200ms 0ms ease-in-out;
        -ms-transition: all 200ms 0ms ease-in-out;
        -o-transition: all 200ms 0ms ease-in-out;
        transition: all 200ms 0ms ease-in-out; }
        #site-footer .sns li a:hover {
          border-color: #FFF;
          color: #FFF;
          transform: scale(1.1); }
  #site-footer .copy {
    display: block;
    margin: 56px 0 0;
    font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em;
    font-size: 10px;
    text-align: center;
    opacity: .64; }
    @media screen and (max-width: 768px) {
      #site-footer .copy {
        margin: 24px 0 0; } }

#secondview {
  height: 400px;
  position: relative;
  background: #FFF; }
  @media screen and (max-width: 1119px) {
    #secondview {
      height: 240px; } }
  @media screen and (max-width: 768px) {
    #secondview {
      height: 200px; } }
  #secondview.meijyu-bread {
    background: #008f76 url("../../../images/meijyu/white_dotto.png") repeat;
    background-size: 16px;
    height: 300px;
    color: #FFF; }
    #secondview.meijyu-bread h1 {
      top: 50%; }
    @media screen and (max-width: 1119px) {
      #secondview.meijyu-bread {
        height: 200px;
        margin: 64px 0 0; } }
    @media screen and (max-width: 768px) {
      #secondview.meijyu-bread {
        height: 150px;
        margin: 0; }
        #secondview.meijyu-bread h1 {
          top: 45%; } }
    #secondview.meijyu-bread a {
      color: #FFF !important; }
  #secondview .bgset {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1; }
    #secondview .bgset__bg {
      position: absolute;
      top: -20%;
      left: 0;
      width: 100%;
      height: 140%;
      background-size: cover;
      background-position: center;
      opacity: .88; }
      @media screen and (max-width: 1119px) {
        #secondview .bgset__bg {
          top: -30%;
          height: 160%; } }
      @media screen and (max-width: 768px) {
        #secondview .bgset__bg {
          top: -40%;
          height: 180%; } }
  #secondview h1 {
    position: absolute;
    left: 50%;
    top: 48%;
    transform: translateX(-50%);
    line-height: 1;
    text-align: center;
    font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em;
    font-size: 14px;
    z-index: 2; }
    @media screen and (max-width: 1119px) {
      #secondview h1 {
        top: 50%;
        transform: translateX(-50%) translateY(-50%); } }
    @media screen and (max-width: 768px) {
      #secondview h1 {
        top: calc(50% + 8px);
        font-size: 12px; } }
    #secondview h1:before {
      content: attr(data-eng);
      font-size: 48px;
      display: block;
      margin: 0 0 14px; }
      @media screen and (max-width: 1119px) {
        #secondview h1:before {
          font-size: 36px; } }
      @media screen and (max-width: 768px) {
        #secondview h1:before {
          font-size: 28px;
          margin: 0 0 10px; } }
  #secondview .bread {
    position: absolute;
    bottom: 24px;
    right: 40px;
    z-index: 3; }
    @media screen and (max-width: 1119px) {
      #secondview .bread {
        bottom: 16px;
        right: 16px; } }
    @media screen and (max-width: 768px) {
      #secondview .bread {
        right: auto;
        left: 16px;
        bottom: 13px; } }
    #secondview .bread ol {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-content: flex-start; }
      #secondview .bread ol li {
        font-size: 10px;
        letter-spacing: .12em; }
        @media screen and (max-width: 768px) {
          #secondview .bread ol li {
            font-size: 9px; } }
        #secondview .bread ol li a {
          color: #1c2a40; }
        #secondview .bread ol li:after {
          content: "\f054";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          display: inline-block;
          margin: 0 12px;
          font-size: 8px;
          transform: translateY(-1px); }
        #secondview .bread ol li:first-child i {
          display: inline-block;
          margin: 0 8px 0 0; }
        #secondview .bread ol li:last-child:after {
          display: none; }

.site-index .firstview {
  overflow: hidden;
  position: relative; }
  .site-index .firstview #fvslide {
    position: relative;
    opacity: 0;
    transition: opacity 1200ms linear; }
    .site-index .firstview #fvslide.swiper-container-initialized {
      opacity: 1; }
      .site-index .firstview #fvslide.swiper-container-initialized .swiper-wrapper .swiper-slide .bg {
        transform: scale(1); }
    .site-index .firstview #fvslide .swiper-wrapper .swiper-slide {
      background: #FFF;
      width: 100%;
      height: 100vh;
      position: relative;
      overflow: hidden; }
      @media screen and (min-width: 769px) {
        .site-index .firstview #fvslide .swiper-wrapper .swiper-slide {
          max-height: 100vh !important; } }
      .site-index .firstview #fvslide .swiper-wrapper .swiper-slide .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        transform: scale(1.1);
        transition: transform 4000ms linear; }
        @media screen and (max-width: 1119px) {
          .site-index .firstview #fvslide .swiper-wrapper .swiper-slide .bg {
            background-position: right center; }
            .site-index .firstview #fvslide .swiper-wrapper .swiper-slide .bg.sp1 {
              background-image: url("../../../images/index/fv_01_sp.jpg") !important; }
            .site-index .firstview #fvslide .swiper-wrapper .swiper-slide .bg.sp2 {
              background-image: url("../../../images/index/fv_02_sp.jpg") !important; }
            .site-index .firstview #fvslide .swiper-wrapper .swiper-slide .bg.sp3 {
              background-image: url("../../../images/index/fv_03_sp.jpg") !important; } }
        .site-index .firstview #fvslide .swiper-wrapper .swiper-slide .bg img {
          display: none; }
    .site-index .firstview #fvslide .controll {
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 100; }
      @media screen and (max-width: 1119px) {
        .site-index .firstview #fvslide .controll {
          right: 20px; } }
      @media screen and (max-width: 768px) {
        .site-index .firstview #fvslide .controll {
          right: 16px; } }
      .site-index .firstview #fvslide .controll__next, .site-index .firstview #fvslide .controll__prev {
        width: 80px;
        height: 80px;
        border: #FFF 1px solid;
        border-radius: 50%;
        cursor: pointer;
        -webkit-transition: all 400ms 0ms cubic-bezier(0.85, 0, 0.15, 1);
        -moz-transition: all 400ms 0ms cubic-bezier(0.85, 0, 0.15, 1);
        -ms-transition: all 400ms 0ms cubic-bezier(0.85, 0, 0.15, 1);
        -o-transition: all 400ms 0ms cubic-bezier(0.85, 0, 0.15, 1);
        transition: all 400ms 0ms cubic-bezier(0.85, 0, 0.15, 1);
        position: relative; }
        @media screen and (max-width: 1119px) {
          .site-index .firstview #fvslide .controll__next, .site-index .firstview #fvslide .controll__prev {
            width: 64px;
            height: 64px; } }
        @media screen and (max-width: 768px) {
          .site-index .firstview #fvslide .controll__next, .site-index .firstview #fvslide .controll__prev {
            width: 48px;
            height: 48px; } }
        .site-index .firstview #fvslide .controll__next:after, .site-index .firstview #fvslide .controll__prev:after {
          content: '';
          width: 22px;
          height: 9px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          background-image: url("../../../images/index/control_prev.png");
          background-position: -0 0;
          background-repeat: no-repeat;
          background-size: 44px;
          -webkit-transition: all 400ms 0ms cubic-bezier(0.85, 0, 0.15, 1);
          -moz-transition: all 400ms 0ms cubic-bezier(0.85, 0, 0.15, 1);
          -ms-transition: all 400ms 0ms cubic-bezier(0.85, 0, 0.15, 1);
          -o-transition: all 400ms 0ms cubic-bezier(0.85, 0, 0.15, 1);
          transition: all 400ms 0ms cubic-bezier(0.85, 0, 0.15, 1); }
          @media screen and (max-width: 768px) {
            .site-index .firstview #fvslide .controll__next:after, .site-index .firstview #fvslide .controll__prev:after {
              width: 18px;
              height: 7px;
              background-size: 36px; } }
        .site-index .firstview #fvslide .controll__next:hover, .site-index .firstview #fvslide .controll__prev:hover {
          background-color: #f1f4f9;
          border-color: rgba(255, 255, 255, 0); }
          .site-index .firstview #fvslide .controll__next:hover:after, .site-index .firstview #fvslide .controll__prev:hover:after {
            background-position: -22px 0; }
            @media screen and (max-width: 768px) {
              .site-index .firstview #fvslide .controll__next:hover:after, .site-index .firstview #fvslide .controll__prev:hover:after {
                background-position: -18px 0; } }
      .site-index .firstview #fvslide .controll__next {
        margin: 0 0 24px; }
        @media screen and (max-width: 768px) {
          .site-index .firstview #fvslide .controll__next {
            margin: 0 0 16px; } }
        .site-index .firstview #fvslide .controll__next:after {
          background-image: url("../../../images/index/control_next.png");
          background-position: -22px 0; }
          @media screen and (max-width: 768px) {
            .site-index .firstview #fvslide .controll__next:after {
              background-position: -18px 0; } }
        .site-index .firstview #fvslide .controll__next:hover:after {
          background-position: 0 0; }
  .site-index .firstview__title {
    position: absolute;
    top: 52%;
    left: 9.4%;
    transform: translateY(-50%);
    z-index: 200;
    font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em;
    letter-spacing: .06em;
    font-weight: 100;
    font-size: calc(42px + 36 * (100vw - 450px)/ 900);
    line-height: 1.44;
    opacity: 0;
    transition: opacity 1000ms linear; }
    @media screen and (max-width: 1119px) {
      .site-index .firstview__title {
        left: 6%;
        font-size: calc(40px + 32 * (100vw - 450px)/ 900); } }
    @media screen and (max-width: 768px) {
      .site-index .firstview__title {
        font-size: calc(32px + 24 * (100vw - 450px)/ 900); } }
    .site-index .firstview__title.fade {
      opacity: 1; }
    .site-index .firstview__title .indent {
      text-indent: -0.4em;
      display: inline-block; }
    .site-index .firstview__title .tume {
      text-indent: -0.12em;
      display: inline-block; }
      .site-index .firstview__title .tume--harf {
        text-indent: -0.06em; }
  .site-index .firstview__copy {
    position: absolute;
    left: 40px;
    bottom: 40px;
    z-index: 100;
    font-size: 9px; }
    @media screen and (max-width: 1119px) {
      .site-index .firstview__copy {
        left: 20px;
        bottom: 20px; } }
    @media screen and (max-width: 768px) {
      .site-index .firstview__copy {
        font-size: 8px;
        left: 16px;
        bottom: 16px; } }
  .site-index .firstview__scroll {
    position: absolute;
    right: 80px;
    bottom: 0;
    z-index: 100;
    font-size: 11px;
    width: 120px;
    padding: 10px 0 0;
    color: #FFF;
    letter-spacing: .24em;
    transform-origin: bottom right;
    mix-blend-mode: exclusion;
    transform: rotate(90deg); }
    @media screen and (max-width: 1119px) {
      .site-index .firstview__scroll {
        right: 50px;
        width: 100px; } }
    @media screen and (max-width: 768px) {
      .site-index .firstview__scroll {
        font-size: 9px;
        right: 40px;
        width: 80px; } }
    .site-index .firstview__scroll:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      top: 0;
      background: #FFF;
      animation: sc 2s cubic-bezier(0.85, 0, 0.15, 1) infinite; }

@keyframes sc {
  0% {
    width: 0;
    left: 0; }
  50% {
    width: 100%;
    left: 0; }
  100% {
    width: 0;
    left: 100%; } }
  .site-index .firstview__dammy {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FFF;
    z-index: 200; }
  .site-index .firstview #fvtxt {
    display: none; }

.site-index .first.wrap {
  padding-top: 32px; }
  @media screen and (max-width: 768px) {
    .site-index .first.wrap {
      padding-top: 16px; } }

.site-index .first .context {
  text-align: center;
  margin: 0 0 80px; }
  @media screen and (max-width: 1119px) {
    .site-index .first .context {
      margin: 0 0 64px; } }
  @media screen and (max-width: 768px) {
    .site-index .first .context {
      margin: 0 16px 32px; } }

.site-index .first .clum {
  position: relative;
  background: #f1f4f9;
  padding: 96px 0;
  margin: 0;
  z-index: 3; }
  @media screen and (max-width: 1119px) {
    .site-index .first .clum {
      padding: 72px 0; } }
  @media screen and (max-width: 768px) {
    .site-index .first .clum {
      padding: 0 0 48px; } }
  .site-index .first .clum:before {
    content: '';
    width: 1px;
    height: 80px;
    background: #cfdaea;
    position: absolute;
    top: -40px;
    left: 50%;
    z-index: 3;
    transform: translateX(-50%); }
    @media screen and (max-width: 768px) {
      .site-index .first .clum:before {
        display: none; } }
  .site-index .first .clum__bg {
    position: absolute;
    top: 96px;
    right: 0;
    width: calc(50%);
    height: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden; }
    @media screen and (max-width: 768px) {
      .site-index .first .clum__bg {
        position: relative;
        top: 0;
        left: 0;
        width: auto;
        height: 240px; } }
    .site-index .first .clum__bg__item {
      position: absolute;
      top: -5%;
      left: 0;
      width: 100%;
      height: 110%;
      background-size: cover;
      background-position: center; }
      @media screen and (max-width: 768px) {
        .site-index .first .clum__bg__item {
          top: -20%;
          height: 140%; } }
  .site-index .first .clum__text {
    width: 50%;
    padding: 88px 60px 40px 0;
    position: relative; }
    @media screen and (max-width: 1119px) {
      .site-index .first .clum__text {
        padding: 72px 40px 24px 0; } }
    @media screen and (max-width: 768px) {
      .site-index .first .clum__text {
        width: auto;
        padding: 32px 0 0; } }
    .site-index .first .clum__text dl dt {
      font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      line-height: 1.66;
      font-size: 28px;
      margin: 0 0 22px;
      position: relative; }
      @media screen and (max-width: 1119px) {
        .site-index .first .clum__text dl dt {
          font-size: 24px; } }
      @media screen and (max-width: 768px) {
        .site-index .first .clum__text dl dt {
          font-size: 18px;
          margin: 0 0 16px;
          text-align: center; } }
      .site-index .first .clum__text dl dt:before {
        content: '';
        width: 40px;
        height: 1px;
        background: #1c2a40;
        top: -40px;
        left: 0;
        position: absolute; }
        @media screen and (max-width: 768px) {
          .site-index .first .clum__text dl dt:before {
            display: none; } }
    .site-index .first .clum__text dl dd {
      text-align: justify;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        .site-index .first .clum__text dl dd {
          font-size: 13.5px;
          margin: 0 15px; }
          .site-index .first .clum__text dl dd.btnarea {
            margin: 24px 0 0; }
          .site-index .first .clum__text dl dd br {
            display: none; } }
  .site-index .first .clum__eng {
    position: absolute;
    left: calc(1vw + 40px);
    bottom: -56px;
    font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em;
    font-size: calc(110px + 20 * (100vw - 450px)/ 900);
    letter-spacing: 0em;
    line-height: 1;
    color: #d6dfed;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      .site-index .first .clum__eng {
        display: none; } }

.site-index .second {
  position: relative; }
  @media screen and (max-width: 768px) {
    .site-index .second {
      padding-top: 0 !important; } }
  .site-index .second:before {
    content: '';
    width: 100%;
    height: 520px;
    background: #f1f4f9;
    position: absolute;
    left: 0;
    bottom: 0; }
    @media screen and (max-width: 768px) {
      .site-index .second:before {
        display: none; } }
  .site-index .second .display {
    width: auto;
    max-width: 1360px; }
    @media screen and (max-width: 768px) {
      .site-index .second .display {
        max-width: 100%; } }
  .site-index .second .context {
    text-align: center;
    margin: 0 0 32px; }
    @media screen and (max-width: 1119px) {
      .site-index .second .context {
        margin: 0 0 24px; } }
    @media screen and (max-width: 768px) {
      .site-index .second .context {
        margin: 0 16px 20px; } }
  .site-index .second .list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    padding: 0 0 12px; }
    @media screen and (max-width: 768px) {
      .site-index .second .list {
        padding: 0;
        display: block; } }
    .site-index .second .list li {
      list-style: none;
      width: 31.5%;
      background: #FFF;
      box-shadow: 0 8px 16px rgba(28, 42, 64, 0.04); }
      @media screen and (max-width: 1119px) {
        .site-index .second .list li {
          width: 32%; } }
      @media screen and (max-width: 768px) {
        .site-index .second .list li {
          width: auto;
          margin: 0 0 16px;
          box-shadow: 0 8px 16px rgba(28, 42, 64, 0.12); }
          .site-index .second .list li:last-child {
            margin: 0; } }
      .site-index .second .list li figure img {
        width: 100%; }
      .site-index .second .list li dl {
        padding: 24px 20px; }
        @media screen and (max-width: 768px) {
          .site-index .second .list li dl {
            padding: 16px; } }
        .site-index .second .list li dl dt {
          font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
          font-weight: 500;
          -webkit-font-feature-settings: "palt";
          font-feature-settings: "palt";
          letter-spacing: 0.04em;
          text-align: center;
          font-weight: 700;
          font-size: 16.5px;
          margin: 0 0 16px;
          padding: 0 0 16px;
          border-bottom: #e8e8ea 1px dashed; }
          @media screen and (max-width: 1119px) {
            .site-index .second .list li dl dt {
              font-size: 15px; } }
          .site-index .second .list li dl dt:before {
            content: attr(data-eng);
            font-size: 10px;
            letter-spacing: .24em;
            color: #7f9ac8;
            display: block; }
        .site-index .second .list li dl dd {
          text-align: justify;
          font-size: 14px; }
          @media screen and (max-width: 1119px) {
            .site-index .second .list li dl dd {
              font-size: 13px; } }

.site-index .third {
  position: relative;
  background: url("../../../images/index/lip.jpg") no-repeat right center;
  background-size: cover; }
  .site-index .third .lip {
    width: 800px;
    margin: 0 auto;
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 1119px) {
      .site-index .third .lip {
        width: auto; } }
    .site-index .third .lip img {
      width: 100%; }
  .site-index .third .description {
    border-top: #e8e8ea 1px solid;
    padding: 40px 0 0;
    margin: 32px 0 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .site-index .third .description {
        margin: 24px 0 0;
        padding: 0;
        display: block;
        border: none; } }
    .site-index .third .description dt {
      width: 200px;
      font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      line-height: 1;
      font-size: 26px;
      white-space: nowrap; }
      @media screen and (max-width: 1119px) {
        .site-index .third .description dt {
          font-size: 24px; } }
      @media screen and (max-width: 768px) {
        .site-index .third .description dt {
          width: auto;
          text-align: center;
          font-size: 20px; } }
    .site-index .third .description dd {
      width: calc(100% - 200px - 180px);
      padding: 0 0 0 40px;
      text-align: justify; }
      @media screen and (max-width: 768px) {
        .site-index .third .description dd {
          width: auto;
          margin: 16px 0 0;
          padding: 0 15px; } }
      .site-index .third .description dd.more {
        width: 180px; }
        @media screen and (max-width: 768px) {
          .site-index .third .description dd.more {
            width: auto; } }
        .site-index .third .description dd.more a {
          display: block;
          border: #1c2a40 1px solid;
          color: #1c2a40;
          font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
          font-weight: 500;
          -webkit-font-feature-settings: "palt";
          font-feature-settings: "palt";
          letter-spacing: 0.04em;
          font-size: 15px;
          text-align: center;
          padding: 12px 0;
          position: relative;
          z-index: 1; }
          .site-index .third .description dd.more a:before {
            content: '';
            width: 0;
            height: 100%;
            background: #1c2a40;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            -webkit-transition: all 140ms 0ms ease-in-out;
            -moz-transition: all 140ms 0ms ease-in-out;
            -ms-transition: all 140ms 0ms ease-in-out;
            -o-transition: all 140ms 0ms ease-in-out;
            transition: all 140ms 0ms ease-in-out; }
          .site-index .third .description dd.more a:hover {
            color: #FFF; }
            .site-index .third .description dd.more a:hover:before {
              width: 100%; }

.site-index .news {
  position: relative;
  z-index: 2;
  background: #1c2a40;
  color: #FFF;
  padding: 80px 0; }
  @media screen and (max-width: 1119px) {
    .site-index .news {
      padding: 56px 0; } }
  @media screen and (max-width: 768px) {
    .site-index .news {
      padding: 32px 0; } }
  .site-index .news .display.flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-index .news .display.flex {
        display: block; } }
  .site-index .news .newsbox {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-index .news .newsbox {
        width: 100%;
        padding: 0;
        display: block; } }
  .site-index .news .leftside {
    width: 280px;
    font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em; }
    @media screen and (max-width: 768px) {
      .site-index .news .leftside {
        width: 100%; } }
    .site-index .news .leftside h3 {
      line-height: 1;
      font-size: 14px;
      margin: 0 0 16px; }
      @media screen and (max-width: 768px) {
        .site-index .news .leftside h3 {
          font-size: 12px; } }
      .site-index .news .leftside h3:before {
        content: attr(data-eng);
        font-size: 36px;
        display: inline-block;
        margin: 0 24px 0 0; }
        @media screen and (max-width: 768px) {
          .site-index .news .leftside h3:before {
            font-size: 20px; } }
    .site-index .news .leftside a {
      display: inline-block;
      color: #FFF;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        .site-index .news .leftside a {
          position: absolute;
          top: 0;
          right: 16px;
          font-size: 13px; } }
      .site-index .news .leftside a:hover {
        text-decoration: underline; }
      .site-index .news .leftside a i {
        display: inline-block;
        font-size: 13px;
        margin: 0 0 0 12px; }
  .site-index .news ol {
    width: calc(100% - 280px);
    height: 130px;
    padding: 0 40px 0 0;
    overflow: auto; }
    @media screen and (max-width: 1119px) {
      .site-index .news ol {
        height: 150px; } }
    @media screen and (max-width: 768px) {
      .site-index .news ol {
        width: 100%;
        height: 164px;
        padding: 15px;
        border: rgba(255, 255, 255, 0.2) 1px solid;
        border-radius: 4px; } }
    .site-index .news ol li {
      border-bottom: rgba(255, 255, 255, 0.06) 1px solid;
      padding: 0 0 24px;
      margin: 0 0 24px; }
      @media screen and (max-width: 1119px) {
        .site-index .news ol li {
          margin: 0 0 15px;
          padding: 0 0 15px; } }
      .site-index .news ol li:last-child {
        margin: 0; }
      .site-index .news ol li a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        color: #FFF; }
        @media screen and (max-width: 1119px) {
          .site-index .news ol li a {
            display: block; } }
        .site-index .news ol li a:hover h4 {
          text-decoration: underline; }
      .site-index .news ol li span.time {
        width: 14%;
        font-size: 12px;
        background: #283d5c;
        color: #FFF;
        padding: 2px 0;
        text-align: center; }
        @media screen and (max-width: 1119px) {
          .site-index .news ol li span.time {
            width: 115px;
            display: inline-block;
            font-size: 12px;
            padding: 1px 12px;
            margin: 2px 0 0; } }
      .site-index .news ol li h4 {
        width: 86%;
        font-size: 16px;
        padding: 0 0 0 32px; }
        @media screen and (max-width: 1119px) {
          .site-index .news ol li h4 {
            width: auto;
            font-size: 14px;
            padding: 8px 0 0;
            clear: both; } }

.site-index .sns {
  padding: 80px 0;
  background: #f1f4f9; }
  @media screen and (max-width: 1119px) {
    .site-index .sns {
      padding: 56px 0; } }
  @media screen and (max-width: 768px) {
    .site-index .sns {
      padding: 32px 0; } }
  .site-index .sns .socials {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-index .sns .socials {
        display: block; } }
    .site-index .sns .socials li {
      width: 48.5%;
      font-size: 12px; }
      .site-index .sns .socials li iframe {
        width: 100%; }
      @media screen and (max-width: 768px) {
        .site-index .sns .socials li {
          width: auto;
          margin: 0 0 16px; }
          .site-index .sns .socials li:last-child {
            margin: 0; } }

.site-contact .localnav {
  background: #f1f4f9;
  padding: 20px;
  border-radius: 4px; }
  @media screen and (max-width: 1119px) {
    .site-contact .localnav {
      padding: 15px; } }
  .site-contact .localnav__list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .site-contact .localnav__list {
        display: block; } }
    .site-contact .localnav__list > li {
      width: calc(50% - 10px);
      background: #FFF;
      padding: 20px 40px;
      border-radius: 4px;
      box-shadow: 0 0 16px rgba(28, 42, 64, 0.08); }
      @media screen and (max-width: 1119px) {
        .site-contact .localnav__list > li {
          padding: 15px; } }
      @media screen and (max-width: 768px) {
        .site-contact .localnav__list > li {
          width: auto;
          margin: 0 0 16px; }
          .site-contact .localnav__list > li:last-child {
            margin: 0; } }
      .site-contact .localnav__list > li dl dt {
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        padding: 12px 0 0; }
        @media screen and (max-width: 1119px) {
          .site-contact .localnav__list > li dl dt {
            font-size: 16px; } }
      .site-contact .localnav__list > li dl dd {
        margin: 16px 0 0;
        text-align: center; }
        .site-contact .localnav__list > li dl dd > a {
          display: block;
          background: #FFF;
          border: #1c2a40 2px solid;
          color: #1c2a40;
          padding: 10px 0;
          border-radius: 4px;
          font-size: 18px;
          font-weight: 700; }
          .site-contact .localnav__list > li dl dd > a:hover {
            background: #1c2a40;
            color: #FFF;
            box-shadow: 0 0 16px rgba(28, 42, 64, 0.12); }
          .site-contact .localnav__list > li dl dd > a > i {
            display: inline-block;
            margin: 0 8px 0 0; }
            .site-contact .localnav__list > li dl dd > a > i.rvs {
              transform: rotateY(180deg); }
        .site-contact .localnav__list > li dl dd.min {
          margin: 10px 0 0; }
          @media screen and (max-width: 1119px) {
            .site-contact .localnav__list > li dl dd.min {
              font-size: 12px; } }
          .site-contact .localnav__list > li dl dd.min strong {
            display: inline-block;
            background: #1c2a40;
            color: #FFF;
            margin: 0 8px 0 0;
            padding: 0 8px;
            font-size: 12px;
            transform: translateY(-2px);
            border-radius: 3px; }

.site-faq .context {
  text-align: center; }
  @media screen and (max-width: 768px) {
    .site-faq .context {
      margin: 0 15px; } }
  .site-faq .context a {
    color: #1c2a40;
    text-decoration: underline; }

.site-faq .question {
  margin: 40px 0 0; }
  .site-faq .question li {
    margin: 0 0 20px; }
    .site-faq .question li:last-child {
      margin: 0; }
    .site-faq .question li dl {
      border: #e8e8ea 2px solid;
      background: #FFF;
      cursor: pointer;
      border-radius: 4px;
      box-shadow: 0 0 15px rgba(28, 42, 64, 0.08); }
      .site-faq .question li dl dt, .site-faq .question li dl dd {
        padding: 15px 20px;
        font-size: 15px; }
        @media screen and (max-width: 768px) {
          .site-faq .question li dl dt, .site-faq .question li dl dd {
            padding: 15px;
            font-size: 13px; } }
      .site-faq .question li dl dt {
        font-size: 16px;
        font-weight: 700;
        position: relative;
        padding-left: 44px;
        padding-right: 44px;
        transition: background 200ms , box-shadow 200ms; }
        @media screen and (max-width: 768px) {
          .site-faq .question li dl dt {
            font-size: 14px; } }
        .site-faq .question li dl dt:before {
          content: "\f059";
          font-family: "Font Awesome 5 Free";
          font-weight: 400;
          position: absolute;
          top: 13px;
          left: 20px;
          font-size: 17px; }
          @media screen and (max-width: 768px) {
            .site-faq .question li dl dt:before {
              left: 16px; } }
        .site-faq .question li dl dt:after {
          content: "\f078";
          font-weight: 900;
          font-family: "Font Awesome 5 Free";
          position: absolute;
          top: 18px;
          right: 20px;
          font-size: 12px;
          opacity: .4;
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out; }
      .site-faq .question li dl dd {
        border-top: #e8e8ea 2px dashed;
        transition: border-color 200ms; }
      .site-faq .question li dl.active {
        border: #1c2a40 2px solid;
        box-shadow: 0 4px 15px rgba(28, 42, 64, 0.16); }
        .site-faq .question li dl.active dt {
          background: #f1f4f9; }
          .site-faq .question li dl.active dt:after {
            transform: rotate(-180deg); }
        .site-faq .question li dl.active dd {
          border-color: #FFF; }

.site-faq .attentionlist {
  border: #f1f4f9 8px solid;
  margin: 32px 0 0; }
  @media screen and (max-width: 768px) {
    .site-faq .attentionlist {
      margin: 24px 0 0; } }
  .site-faq .attentionlist li {
    padding: 24px;
    padding-left: 56px;
    border-bottom: #f1f4f9 2px solid;
    font-size: 15px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .site-faq .attentionlist li {
        padding: 15px;
        padding-left: 48px;
        font-size: 13.5px; } }
    .site-faq .attentionlist li:before {
      content: "\f058";
      font-weight: 900;
      font-size: 18px;
      font-family: "Font Awesome 5 Free";
      position: absolute;
      left: 24px;
      top: 50%;
      color: #b3c4de;
      transform: translateY(-50%); }
      @media screen and (max-width: 768px) {
        .site-faq .attentionlist li:before {
          left: 16px; } }

.site-agree .context {
  text-align: center;
  margin: 0 0 32px; }
  @media screen and (max-width: 768px) {
    .site-agree .context {
      margin: 0 15px 24px; } }

.site-agree .block {
  background: #f5f7fb;
  padding: 24px 32px; }
  @media screen and (max-width: 768px) {
    .site-agree .block {
      padding: 15px; } }
  .site-agree .block.mb {
    margin-bottom: 40px; }
    @media screen and (max-width: 768px) {
      .site-agree .block.mb {
        margin-bottom: 24px; } }
  .site-agree .block h3.borders {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 12px;
    margin: 0 0 15px;
    border-bottom: #FFF 4px solid; }
    @media screen and (max-width: 768px) {
      .site-agree .block h3.borders {
        font-size: 16px; } }
  .site-agree .block .btnarea {
    margin: 24px 0 0;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .site-agree .block .btnarea {
        margin: 15px 0 0; }
        .site-agree .block .btnarea a {
          display: block;
          padding: 10px 0; } }
  .site-agree .block .center {
    text-align: center;
    margin: 20px 0 0; }
    @media screen and (max-width: 768px) {
      .site-agree .block .center {
        margin: 12px 0 0; } }
    .site-agree .block .center a {
      font-size: 16px;
      color: #1c2a40;
      text-decoration: underline; }
      .site-agree .block .center a:hover {
        opacity: .64; }

.site-about .gallery {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 0 0 56px -24px;
  justify-content: flex-start; }
  @media screen and (max-width: 768px) {
    .site-about .gallery {
      margin: 0 0 32px -15px; } }
  .site-about .gallery li {
    width: 33.3%;
    padding: 0 0 0 24px;
    margin: 24px 0 0; }
    .site-about .gallery li img {
      width: 100%; }
    .site-about .gallery li figcaption {
      margin: 8px 0 0;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .site-about .gallery li figcaption {
          font-size: 12px; } }
    @media screen and (min-width: 1120px) {
      .site-about .gallery li:nth-child(-n+3) {
        margin-top: 0; } }
    @media screen and (max-width: 1119px) {
      .site-about .gallery li {
        width: 50%; }
        .site-about .gallery li:nth-child(-n+2) {
          margin-top: 0; } }
    @media screen and (max-width: 768px) {
      .site-about .gallery li {
        padding: 0 0 0 15px; } }

.site-about .message {
  border: #f1f4f9 16px solid;
  padding: 32px 48px; }
  @media screen and (max-width: 1119px) {
    .site-about .message {
      padding: 32px; } }
  @media screen and (max-width: 768px) {
    .site-about .message {
      border: none;
      padding: 0 15px; } }
  .site-about .message > dl {
    margin: 0 auto; }
    .site-about .message > dl dd {
      font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      font-size: 15.5px;
      line-height: 2;
      text-align: justify;
      margin: 0 0 24px; }
      @media screen and (max-width: 1119px) {
        .site-about .message > dl dd {
          font-size: 14px;
          line-height: 1.8; } }
    .site-about .message > dl dt {
      font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      text-align: right;
      font-size: 13px;
      margin: 32px 0 0; }
      .site-about .message > dl dt small {
        font-size: 16px; }
        @media screen and (max-width: 768px) {
          .site-about .message > dl dt small {
            font-size: 15px; } }
      .site-about .message > dl dt strong {
        font-size: 24px;
        line-height: 1;
        display: inline-block;
        margin: 6px 0 0 6px; }
        @media screen and (max-width: 768px) {
          .site-about .message > dl dt strong {
            font-size: 22px; } }

.site-about .titlemessage {
  text-align: center;
  font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.04em;
  font-size: 16px;
  margin: 0 0 24px; }
  @media screen and (max-width: 768px) {
    .site-about .titlemessage {
      margin: 15px;
      margin-top: 0;
      font-size: 13px; } }

.site-news.meijyu-style h2.borders:before, .site-news.meijyu-style h3.borders:before {
  background: #008f76; }

.site-news.meijyu-style .list li dl dd.time {
  background: #008f76; }

.site-news.meijyu-style .minlist li a dl dd.time {
  background: #008f76; }

.site-news.meijyu-style .site-clum__container .pager span {
  border-color: #008f76;
  background: #008f76; }

.site-news h2.borders,
.site-news h3.borders {
  font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.04em;
  font-size: 22px;
  border-bottom: #e8e8ea 1px solid;
  margin: 0 0 20px;
  padding: 0 0 4px 14px;
  position: relative;
  line-height: 1.5; }
  @media screen and (max-width: 768px) {
    .site-news h2.borders,
    .site-news h3.borders {
      font-size: 17px; } }
  .site-news h2.borders:before,
  .site-news h3.borders:before {
    content: '';
    width: 5px;
    height: 70%;
    border-radius: 999px;
    position: absolute;
    top: 10%;
    left: 0;
    background: #1c2a40; }

.site-news .list li {
  list-style: none;
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: #e8e8ea 1px solid; }
  @media screen and (max-width: 768px) {
    .site-news .list li {
      margin: 0 0 15px;
      padding: 0 0 15px; } }
  .site-news .list li > a {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    color: #1c2a40; }
  .site-news .list li:last-child {
    margin: 0;
    padding: 0;
    border: none; }
  .site-news .list li .bg {
    width: 250px;
    height: 140px;
    background-size: cover;
    background-position: center; }
    @media screen and (max-width: 768px) {
      .site-news .list li .bg {
        width: 100px;
        height: 75px; } }
  .site-news .list li dl {
    width: calc(100% - 250px);
    padding: 0 0 0 24px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start; }
    @media screen and (max-width: 768px) {
      .site-news .list li dl {
        width: calc(100% - 100px);
        padding: 0 0 0 15px;
        display: block; } }
    .site-news .list li dl dt {
      font-size: 20px;
      font-weight: 700;
      width: calc(100% - 100px);
      padding: 0 0 0 20px; }
      @media screen and (max-width: 768px) {
        .site-news .list li dl dt {
          font-size: 15px;
          width: auto;
          padding: 4px 0 0; } }
    .site-news .list li dl dd.time {
      background: #cfdaea;
      color: #FFF;
      font-size: 12px;
      width: 100px;
      padding: 2px 0;
      text-align: center;
      transform: translateY(4px); }
      @media screen and (max-width: 768px) {
        .site-news .list li dl dd.time {
          font-size: 11px;
          transform: translateY(0); } }
    .site-news .list li dl dd.description {
      font-size: 13.5px;
      text-align: justify; }
      @media screen and (max-width: 768px) {
        .site-news .list li dl dd.description {
          display: none; } }

@media screen and (max-width: 1119px) {
  .site-news .minlist {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; } }

@media screen and (max-width: 768px) {
  .site-news .minlist {
    display: block; } }

.site-news .minlist li {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: #e8e8ea 1px solid; }
  @media screen and (max-width: 1119px) {
    .site-news .minlist li {
      width: calc(50% - 10px); } }
  @media screen and (max-width: 768px) {
    .site-news .minlist li {
      width: auto;
      margin: 0 0 15px;
      padding: 0 0 15px; } }
  .site-news .minlist li:last-child {
    margin: 0;
    padding: 0;
    border: none; }
  .site-news .minlist li a {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    color: #1c2a40; }
    .site-news .minlist li a .bg {
      width: 90px;
      height: 60px;
      background-size: cover;
      background-position: center; }
      @media screen and (max-width: 1119px) {
        .site-news .minlist li a .bg {
          width: 80px;
          height: 50px; } }
    .site-news .minlist li a dl {
      width: calc(100% - 90px);
      padding: 0 0 0 20px; }
      @media screen and (max-width: 1119px) {
        .site-news .minlist li a dl {
          width: calc(100% - 80px); } }
      .site-news .minlist li a dl dt {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.66;
        margin: 8px 0 0; }
        @media screen and (max-width: 1119px) {
          .site-news .minlist li a dl dt {
            font-size: 13.5px; } }
      .site-news .minlist li a dl dd.time {
        background: #cfdaea;
        color: #FFF;
        font-size: 11px;
        width: 88px;
        padding: 1px 0;
        text-align: center;
        transform: translateY(4px); }
      .site-news .minlist li a dl dd.description {
        font-size: 13.5px;
        text-align: justify; }

.site-news .btnarea.s a {
  min-width: 1px; }
  @media screen and (max-width: 1119px) {
    .site-news .btnarea.s a {
      display: block; } }

.site-news .details .articletitle {
  border-top: #cfdaea 6px solid;
  background: #f1f4f9;
  padding: 20px 24px;
  margin: 0 0 20px; }
  @media screen and (max-width: 768px) {
    .site-news .details .articletitle {
      padding: 15px;
      margin: 0 0 24px; } }
  @media screen and (max-width: 768px) {
    .site-news .details .articletitle {
      margin: 0 0 16px; } }
  .site-news .details .articletitle h2 {
    line-height: 1.56;
    font-size: 24px;
    font-weight: 700;
    margin: 8px 0 0; }
    @media screen and (max-width: 768px) {
      .site-news .details .articletitle h2 {
        font-size: 17px; } }
  .site-news .details .articletitle .date {
    font-weight: 700;
    color: #FFF;
    background: #cfdaea;
    padding: 1px 20px;
    font-size: 12px;
    display: inline-block; }

.site-news .details__edit {
  font-size: 15px;
  line-height: 1.8; }
  @media screen and (max-width: 1119px) {
    .site-news .details__edit {
      font-size: 14px; } }
  @media screen and (max-width: 768px) {
    .site-news .details__edit {
      font-size: 13.5px; } }
  .site-news .details__edit h3 {
    font-size: 24px;
    font-weight: 700;
    border-top: #1c2a40 2px solid;
    border-bottom: #1c2a40 2px solid;
    font-weight: 700;
    padding: 8px 2px;
    margin: 0 0 20px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h3 {
        font-size: 22px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h3 {
        font-size: 20px; } }
  .site-news .details__edit h4 {
    font-size: 22px;
    border-bottom: #e8e8ea 2px solid;
    font-weight: 700;
    padding: 0 0 0 16px;
    margin: 0 0 16px;
    position: relative; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h4 {
        font-size: 20px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h4 {
        font-size: 18px; } }
    .site-news .details__edit h4:before {
      content: '';
      width: 4px;
      height: 80%;
      position: absolute;
      top: 10%;
      left: 0;
      background: #cfdaea;
      border-radius: 2px; }
  .site-news .details__edit h5 {
    font-size: 20px;
    font-weight: 700;
    background: #f1f4f9;
    padding: 4px 15px;
    margin: 0 0 16px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h5 {
        font-size: 18px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h5 {
        font-size: 16px; } }
  .site-news .details__edit h6 {
    position: relative;
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 0 18px;
    margin: 0 0 8px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit h6 {
        font-size: 17px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit h6 {
        font-size: 15px; } }
    .site-news .details__edit h6:before {
      content: '';
      width: 12px;
      height: 12px;
      border: #cfdaea 4px solid;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
  .site-news .details__edit table {
    border: #e8e8ea 1px solid;
    border-collapse: collapse;
    width: 100%;
    margin: 0 0 40px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit table {
        margin: 0 0 32px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit table {
        margin: 0 0 24px; } }
    .site-news .details__edit table tbody tr td, .site-news .details__edit table tbody tr th,
    .site-news .details__edit table thead tr td,
    .site-news .details__edit table thead tr th {
      padding: 15px;
      text-align: left;
      vertical-align: middle;
      border: #e8e8ea 1px solid; }
    .site-news .details__edit table tbody tr th,
    .site-news .details__edit table thead tr th {
      background: #f1f4f9; }
      @media screen and (max-width: 768px) {
        .site-news .details__edit table tbody tr th,
        .site-news .details__edit table thead tr th {
          min-width: 100px; } }
  .site-news .details__edit img {
    max-width: 100%; }
  .site-news .details__edit .avatar {
    width: 88px;
    margin: 0 15px; }
    @media screen and (max-width: 768px) {
      .site-news .details__edit .avatar {
        width: 64px;
        margin: 0; } }
    .site-news .details__edit .avatar img {
      width: 100%;
      border-radius: 50%; }
    .site-news .details__edit .avatar figcaption {
      text-align: center;
      margin: 15px 0 0;
      font-size: 14px; }
      @media screen and (max-width: 768px) {
        .site-news .details__edit .avatar figcaption {
          margin: 8px 0 0;
          font-size: 13px; } }
  .site-news .details__edit iframe {
    width: 100%;
    height: 300px;
    vertical-align: bottom;
    margin: 0 0 40px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit iframe {
        height: 250px;
        margin: 0 0 32px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit iframe {
        height: 180px;
        margin: 0 0 20px; } }
  .site-news .details__edit p,
  .site-news .details__edit ul,
  .site-news .details__edit ol {
    margin: 0 0 40px; }
    @media screen and (max-width: 1119px) {
      .site-news .details__edit p,
      .site-news .details__edit ul,
      .site-news .details__edit ol {
        margin: 0 0 32px; } }
    @media screen and (max-width: 768px) {
      .site-news .details__edit p,
      .site-news .details__edit ul,
      .site-news .details__edit ol {
        margin: 0 0 20px; } }

.site-whitening .localnav__list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 0 0 32px;
  border-radius: 4px;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .site-whitening .localnav__list {
      margin: 0 0 16px; } }
  .site-whitening .localnav__list li {
    width: 25%;
    border-right: #FFF 1px solid; }
    .site-whitening .localnav__list li:last-child {
      border-right: none; }
    @media screen and (max-width: 1119px) {
      .site-whitening .localnav__list li {
        width: 50%; }
        .site-whitening .localnav__list li:nth-child(2n+2) {
          border-right: none; }
        .site-whitening .localnav__list li:nth-child(-n+2) {
          border-bottom: #FFF 1px solid; } }
    .site-whitening .localnav__list li a {
      display: block;
      background: #96aed1;
      color: #FFF;
      font-size: 17px;
      text-align: center;
      padding: 10px 15px;
      transition: background 400ms; }
      @media screen and (max-width: 768px) {
        .site-whitening .localnav__list li a {
          font-size: 14px;
          padding: 10px 0; } }
      .site-whitening .localnav__list li a:hover {
        background: #c1cfe4; }

.site-whitening .context {
  font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.04em;
  font-size: 16px;
  text-align: center;
  margin: 0 0 32px; }
  @media screen and (max-width: 768px) {
    .site-whitening .context {
      margin: 0 15px 16px;
      font-size: 14px; }
      .site-whitening .context br {
        display: none; } }

.site-whitening .list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 0 0 72px; }
  @media screen and (max-width: 1119px) {
    .site-whitening .list {
      margin: 0 0 56px; } }
  @media screen and (max-width: 768px) {
    .site-whitening .list {
      margin: 0 0 48px; } }
  .site-whitening .list li {
    list-style: none;
    width: 31.5%;
    background: #FFF;
    box-shadow: 0 12px 32px rgba(28, 42, 64, 0.06); }
    @media screen and (max-width: 768px) {
      .site-whitening .list li {
        width: auto;
        margin: 0 0 15px;
        box-shadow: 0 6px 15px rgba(28, 42, 64, 0.06); }
        .site-whitening .list li:last-child {
          margin: 0; } }
    .site-whitening .list li figure img {
      width: 100%; }
    .site-whitening .list li dl {
      padding: 24px 20px; }
      .site-whitening .list li dl dt {
        font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.04em;
        text-align: center;
        font-weight: 700;
        font-size: 16.5px;
        margin: 0 0 16px;
        padding: 0 0 16px;
        border-bottom: #e8e8ea 1px dashed; }
        .site-whitening .list li dl dt:before {
          content: attr(data-eng);
          font-size: 10px;
          letter-spacing: .24em;
          color: #7f9ac8;
          display: block; }
      .site-whitening .list li dl dd {
        text-align: justify;
        font-size: 14px; }

.site-whitening .trouble {
  text-align: center;
  position: relative;
  font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.04em;
  font-size: 24px; }
  @media screen and (max-width: 768px) {
    .site-whitening .trouble {
      font-size: 15px; } }
  .site-whitening .trouble__txt {
    display: inline-block;
    background: #FFF;
    padding: 0 24px;
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 768px) {
      .site-whitening .trouble__txt {
        padding: 0 15px; } }
  .site-whitening .trouble:before {
    content: '';
    width: 100%;
    height: 1px;
    background: #1c2a40;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }

.site-whitening .beglist {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 16px 0 0; }
  @media screen and (max-width: 768px) {
    .site-whitening .beglist {
      margin: 8px 0 0;
      display: block; } }
  .site-whitening .beglist li {
    list-style: none;
    width: calc(50% - 10px);
    font-size: 16px;
    margin: 20px 0 0;
    border: #eaeef6 2px solid;
    border-radius: 4px;
    padding: 10px 15px;
    box-shadow: 0 0 16px rgba(28, 42, 64, 0.08); }
    @media screen and (max-width: 768px) {
      .site-whitening .beglist li {
        width: auto;
        margin: 8px 0 0;
        padding-left: 40px;
        position: relative; } }
    .site-whitening .beglist li i {
      color: #b3c4de;
      margin: 0 6px 0 0; }
      @media screen and (max-width: 768px) {
        .site-whitening .beglist li i {
          position: absolute;
          top: 16px;
          left: 15px; } }

.site-whitening .beganswer {
  margin: 64px 0 0;
  padding: 64px 0 72px;
  background: #f1f4f9;
  background: -moz-linear-gradient(left, #cfdaea 0%, #f8fafc 50%, #cfdaea 100%);
  background: -webkit-linear-gradient(left, #cfdaea 0%, #f8fafc 50%, #cfdaea 100%);
  background: linear-gradient(to right, #cfdaea 0%, #f8fafc 50%, #cfdaea 100%);
  position: relative;
  box-shadow: inset 0 32px 32px rgba(207, 218, 234, 0.32); }
  @media screen and (max-width: 768px) {
    .site-whitening .beganswer {
      margin: 24px 0 0;
      padding: 40px 0 32px; } }
  .site-whitening .beganswer:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 24px 20px 0 20px;
    border-color: #FFF transparent transparent transparent;
    position: absolute;
    top: -2px;
    left: 50%;
    transform: translateX(-50%); }
  .site-whitening .beganswer dl {
    position: relative; }
    .site-whitening .beganswer dl dt {
      font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      font-size: 22px;
      line-height: 1.66;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .site-whitening .beganswer dl dt {
          font-size: 16px;
          margin: 0 15px; } }
    .site-whitening .beganswer dl .beafter {
      width: 920px;
      margin: 32px auto;
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      @media screen and (max-width: 1119px) {
        .site-whitening .beganswer dl .beafter {
          width: auto; } }
      .site-whitening .beganswer dl .beafter i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 50px;
        height: 50px;
        background: #FFF;
        border-radius: 50%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        font-size: 24px;
        color: #cfdaea;
        align-items: center;
        justify-content: center; }
        @media screen and (max-width: 768px) {
          .site-whitening .beganswer dl .beafter i {
            width: 20px;
            height: 20px;
            font-size: 10px; } }
      .site-whitening .beganswer dl .beafter__before, .site-whitening .beganswer dl .beafter__after {
        width: 420px;
        border-radius: 8px;
        overflow: hidden;
        border: #FFF 4px solid;
        background: #FFF; }
        @media screen and (max-width: 1119px) {
          .site-whitening .beganswer dl .beafter__before, .site-whitening .beganswer dl .beafter__after {
            width: calc(50% - 48px); } }
        @media screen and (max-width: 768px) {
          .site-whitening .beganswer dl .beafter__before, .site-whitening .beganswer dl .beafter__after {
            width: calc(50% - 15px); } }
        .site-whitening .beganswer dl .beafter__before figcaption, .site-whitening .beganswer dl .beafter__after figcaption {
          padding: 14px 0 10px;
          text-align: center; }
          @media screen and (max-width: 768px) {
            .site-whitening .beganswer dl .beafter__before figcaption, .site-whitening .beganswer dl .beafter__after figcaption {
              padding: 10px 0 6px; } }
        .site-whitening .beganswer dl .beafter__before img, .site-whitening .beganswer dl .beafter__after img {
          width: 100%; }

.site-whitening .secondary {
  position: relative;
  overflow: hidden; }
  .site-whitening .secondary__kussion {
    position: absolute;
    top: -30%;
    left: 0;
    width: 100%;
    height: 160%;
    background-position: center;
    background-size: cover; }
  .site-whitening .secondary:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #101724;
    opacity: .4;
    z-index: 2; }
  .site-whitening .secondary .display {
    z-index: 3; }
  .site-whitening .secondary h3 {
    font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em;
    font-size: 26px;
    text-align: center;
    color: #FFF;
    margin: 0 0 24px; }
    @media screen and (max-width: 768px) {
      .site-whitening .secondary h3 {
        font-size: 17px;
        margin: 0 15% 16px; } }
    .site-whitening .secondary h3 strong {
      font-weight: 400;
      line-height: 1;
      font-size: 40px; }
      @media screen and (max-width: 768px) {
        .site-whitening .secondary h3 strong {
          font-size: 24px; } }
  .site-whitening .secondary .flexbox {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 1119px) {
      .site-whitening .secondary .flexbox {
        display: block; } }
  .site-whitening .secondary .middle {
    color: #FFF;
    font-size: 16px;
    text-align: center;
    background: #1c2a40;
    padding: 20px; }
    .site-whitening .secondary .middle--harf {
      width: calc(50% - 10px); }
      @media screen and (max-width: 1119px) {
        .site-whitening .secondary .middle--harf {
          width: auto;
          margin: 0 0 20px; }
          .site-whitening .secondary .middle--harf:last-of-type {
            margin: 0; } }
      .site-whitening .secondary .middle--harf.cols {
        background: #7a98c5; }
    @media screen and (max-width: 768px) {
      .site-whitening .secondary .middle {
        padding: 15px; } }
    .site-whitening .secondary .middle__ttl {
      border: #FFF 1px solid;
      font-size: 18px;
      display: inline-block;
      padding: 6px 12px;
      margin: 0 16px 0 0;
      transform: translateY(-8px); }
      @media screen and (max-width: 768px) {
        .site-whitening .secondary .middle__ttl {
          font-size: 16px;
          display: block;
          margin: 0 0 6px;
          text-align: center;
          transform: translateY(0); } }
    .site-whitening .secondary .middle dt {
      font-size: 64px;
      font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      margin: 0 0 12px;
      line-height: 1.4; }
      .site-whitening .secondary .middle dt.s {
        font-size: 40px;
        margin: 0 0 8px; }
        @media screen and (max-width: 768px) {
          .site-whitening .secondary .middle dt.s {
            margin: 0 0 6px; } }
      @media screen and (max-width: 768px) {
        .site-whitening .secondary .middle dt {
          font-size: 32px;
          margin: 0 0 8px; } }
      .site-whitening .secondary .middle dt small {
        display: inline-block;
        margin: 0 0 0 6px;
        font-size: 24px; }
        @media screen and (max-width: 768px) {
          .site-whitening .secondary .middle dt small {
            font-size: 20px; } }
    .site-whitening .secondary .middle dd.title {
      font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      letter-spacing: 0em;
      font-size: 26px;
      line-height: 1.4; }
      .site-whitening .secondary .middle dd.title .s {
        font-size: 20px; }
      @media screen and (max-width: 768px) {
        .site-whitening .secondary .middle dd.title {
          font-size: 18px; } }
    .site-whitening .secondary .middle dd.pad {
      padding-top: 22px; }
      @media screen and (max-width: 1119px) {
        .site-whitening .secondary .middle dd.pad {
          padding-top: 0; } }
    .site-whitening .secondary .middle dd.sns {
      font-size: 24px;
      margin: 0 0 8px; }
      .site-whitening .secondary .middle dd.sns small {
        font-size: 13px;
        display: inline-block;
        margin: 0 0 0 8px; }
  .site-whitening .secondary .snst {
    text-align: center;
    width: 320px;
    margin: 24px auto 0;
    background: rgba(0, 0, 0, 0.12);
    border: #FFF 2px solid;
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 0; }
    @media screen and (max-width: 768px) {
      .site-whitening .secondary .snst {
        font-size: 16px;
        display: block;
        width: auto; } }
  .site-whitening .secondary .center {
    text-align: center;
    font-size: 18px;
    margin: 24px 0 0;
    color: #FFF; }

.site-whitening .flexmachine {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 768px) {
    .site-whitening .flexmachine {
      display: block; } }
  .site-whitening .flexmachine__pic {
    width: 400px; }
    @media screen and (max-width: 1119px) {
      .site-whitening .flexmachine__pic {
        width: 320px; } }
    @media screen and (max-width: 768px) {
      .site-whitening .flexmachine__pic {
        width: 88%;
        margin: 0 auto; } }
    .site-whitening .flexmachine__pic img {
      width: 100%; }
  .site-whitening .flexmachine__dl {
    width: calc(100% - 400px);
    padding: 0 0 0 48px; }
    @media screen and (max-width: 1119px) {
      .site-whitening .flexmachine__dl {
        width: calc(100% - 320px);
        padding: 0 0 0 40px; } }
    @media screen and (max-width: 768px) {
      .site-whitening .flexmachine__dl {
        width: auto;
        padding: 0;
        margin: 15px 0 0; } }
    .site-whitening .flexmachine__dl dt {
      font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      font-size: 32px;
      letter-spacing: 0em;
      line-height: 1.56; }
      @media screen and (max-width: 1119px) {
        .site-whitening .flexmachine__dl dt {
          font-size: 24px; } }
      @media screen and (max-width: 768px) {
        .site-whitening .flexmachine__dl dt {
          font-size: 20px;
          text-align: center; } }
    .site-whitening .flexmachine__dl dd {
      margin: 20px 0 0;
      padding: 20px 0 0;
      border-top: #e8e8ea 1px solid;
      font-size: 15px;
      text-align: justify; }
      @media screen and (max-width: 1119px) {
        .site-whitening .flexmachine__dl dd {
          font-size: 13.5px; }
          .site-whitening .flexmachine__dl dd br {
            display: none; } }
      .site-whitening .flexmachine__dl dd.min {
        margin: 4px 0 0;
        padding: 0;
        border: none;
        font-size: 12px; }
        @media screen and (max-width: 768px) {
          .site-whitening .flexmachine__dl dd.min {
            text-align: center; } }

.site-whitening .flexaward {
  margin: 24px 0 0;
  padding: 24px 0 0;
  border-top: #e8e8ea 1px solid;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  @media screen and (max-width: 1119px) {
    .site-whitening .flexaward {
      padding: 15px 0 0;
      margin: 15px 0 0; } }
  .site-whitening .flexaward li {
    width: 23.5%;
    border: #e8e8ea 1px solid; }
    .site-whitening .flexaward li img {
      width: 100%; }

.site-whitening .moon {
  background: #131949;
  padding: 60px 0 0; }
  @media screen and (max-width: 768px) {
    .site-whitening .moon {
      padding-top: 32px; } }
  .site-whitening .moon__pic {
    width: 50%;
    min-width: 880px;
    max-width: 1000px;
    margin: 0 auto; }
    @media screen and (max-width: 1119px) {
      .site-whitening .moon__pic {
        min-width: 1px;
        width: 80%;
        max-width: 100%; } }
    .site-whitening .moon__pic img {
      width: 100%; }
  .site-whitening .moon__content {
    background: #FFF; }
  .site-whitening .moon__ttl {
    font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em;
    font-size: 36px;
    text-align: center;
    line-height: 1; }
    .site-whitening .moon__ttl:after {
      content: attr(data-text);
      margin: 16px 0 0;
      font-size: 14px;
      display: block; }
  .site-whitening .moon__item {
    width: 800px;
    margin: 32px auto 0; }
    @media screen and (max-width: 1119px) {
      .site-whitening .moon__item {
        width: auto; } }
    .site-whitening .moon__item img {
      width: 100%; }
    .site-whitening .moon__item figcaption {
      text-align: center;
      margin: 32px 0 0; }

.site-whitening .lips {
  background: #f1f4f9; }
  .site-whitening .lips__ttl {
    text-align: center;
    font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em;
    font-size: 26px;
    margin: 0 0 24px; }
    @media screen and (max-width: 768px) {
      .site-whitening .lips__ttl {
        font-size: 18px;
        margin: 0 15px 15px; } }
  .site-whitening .lips__box {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    margin: 0 0 64px; }
    @media screen and (max-width: 1119px) {
      .site-whitening .lips__box {
        margin: 0 0 32px;
        padding: 20px;
        align-items: flex-start; } }
    @media screen and (max-width: 768px) {
      .site-whitening .lips__box {
        margin: 0 0 24px;
        padding: 15px;
        display: block; } }
    .site-whitening .lips__box > figure {
      width: 500px; }
      @media screen and (max-width: 1119px) {
        .site-whitening .lips__box > figure {
          width: 320px; } }
      .site-whitening .lips__box > figure img {
        width: 100%; }
    .site-whitening .lips__box__txt {
      width: calc(100% - 500px);
      padding: 32px 48px; }
      @media screen and (max-width: 1119px) {
        .site-whitening .lips__box__txt {
          width: calc(100% - 320px);
          padding: 0 0 0 32px; } }
      @media screen and (max-width: 768px) {
        .site-whitening .lips__box__txt {
          width: auto;
          padding: 0;
          margin: 20px 0 0; } }
      .site-whitening .lips__box__txt h3 {
        font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.04em;
        font-size: 24px;
        line-height: 1.66;
        margin: 0 0 24px; }
        @media screen and (max-width: 768px) {
          .site-whitening .lips__box__txt h3 {
            font-size: 17px;
            margin: 0 0 15px; } }
      .site-whitening .lips__box__txt dl {
        margin: 0 0 16px;
        padding: 0 0 16px;
        border-bottom: #e8e8ea 1px dashed; }
        @media screen and (max-width: 1119px) {
          .site-whitening .lips__box__txt dl {
            margin: 0 0 12px;
            padding: 0 0 12px; } }
        .site-whitening .lips__box__txt dl:last-of-type {
          margin: 0;
          padding: 0;
          border: none; }
        .site-whitening .lips__box__txt dl dt {
          font-size: 16px;
          font-weight: 700;
          margin: 0 0 4px; }
          @media screen and (max-width: 1119px) {
            .site-whitening .lips__box__txt dl dt {
              font-size: 15px; } }
        .site-whitening .lips__box__txt dl dd {
          position: relative;
          padding: 0 0 0 24px; }
          .site-whitening .lips__box__txt dl dd:before {
            content: '■';
            color: #cfdaea;
            position: absolute;
            top: 1px;
            left: 0; }
  .site-whitening .lips__list {
    background: #FFF;
    padding: 44px 44px 32px;
    box-shadow: 0 0 32px rgba(28, 42, 64, 0.08); }
    @media screen and (max-width: 768px) {
      .site-whitening .lips__list {
        padding: 15px;
        padding-bottom: 8px; } }
    .site-whitening .lips__list > li {
      position: relative;
      padding: 0 0 12px;
      padding-left: 40px;
      border-bottom: #e8e8ea 1px dashed;
      margin: 0 0 12px;
      font-size: 15px; }
      .site-whitening .lips__list > li:last-child {
        margin-bottom: 0;
        border: none; }
      .site-whitening .lips__list > li:nth-child(1):before {
        content: "1";
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #b3c4de;
        color: #FFF;
        text-align: center;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        transform: translateY(-50%); }
      .site-whitening .lips__list > li:nth-child(2):before {
        content: "2";
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #b3c4de;
        color: #FFF;
        text-align: center;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        transform: translateY(-50%); }
      .site-whitening .lips__list > li:nth-child(3):before {
        content: "3";
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #b3c4de;
        color: #FFF;
        text-align: center;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        transform: translateY(-50%); }
      .site-whitening .lips__list > li:nth-child(4):before {
        content: "4";
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #b3c4de;
        color: #FFF;
        text-align: center;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        transform: translateY(-50%); }
      .site-whitening .lips__list > li:nth-child(5):before {
        content: "5";
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #b3c4de;
        color: #FFF;
        text-align: center;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        transform: translateY(-50%); }
      .site-whitening .lips__list > li:nth-child(6):before {
        content: "6";
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #b3c4de;
        color: #FFF;
        text-align: center;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        transform: translateY(-50%); }
      .site-whitening .lips__list > li:nth-child(7):before {
        content: "7";
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #b3c4de;
        color: #FFF;
        text-align: center;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        transform: translateY(-50%); }
      .site-whitening .lips__list > li:nth-child(8):before {
        content: "8";
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #b3c4de;
        color: #FFF;
        text-align: center;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        transform: translateY(-50%); }
      .site-whitening .lips__list > li:nth-child(9):before {
        content: "9";
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #b3c4de;
        color: #FFF;
        text-align: center;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        transform: translateY(-50%); }
      .site-whitening .lips__list > li:nth-child(10):before {
        content: "10";
        width: 24px;
        height: 24px;
        line-height: 24px;
        background: #b3c4de;
        color: #FFF;
        text-align: center;
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        transform: translateY(-50%); }

.site-whitening .selfwhite {
  position: relative; }
  .site-whitening .selfwhite:before {
    content: '';
    background: url("../../../images/whitening/self_sky.jpg") no-repeat center top;
    width: 100%;
    height: 260px;
    position: absolute;
    top: 0;
    left: 0; }
    @media screen and (max-width: 768px) {
      .site-whitening .selfwhite:before {
        height: 160px; } }
  .site-whitening .selfwhite .whitebox {
    position: relative;
    background: #FFF;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.8) 0%, white 50%);
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.8) 0%, white 50%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 0%, white 50%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#ffffff',GradientType=0 );
    padding: 64px 0 0;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -24px 24px rgba(0, 0, 0, 0.08); }
    @media screen and (max-width: 768px) {
      .site-whitening .selfwhite .whitebox {
        padding: 32px 0 0; } }
    .site-whitening .selfwhite .whitebox h2.large {
      background: none;
      padding: 0; }
    .site-whitening .selfwhite .whitebox .context {
      font-size: 17px; }
      @media screen and (max-width: 1119px) {
        .site-whitening .selfwhite .whitebox .context {
          font-size: 15px; } }
      @media screen and (max-width: 768px) {
        .site-whitening .selfwhite .whitebox .context {
          font-size: 13.5px;
          padding: 0 15px; } }
  .site-whitening .selfwhite .whitelist {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    margin: 40px 0 0;
    padding: 0 40px; }
    @media screen and (max-width: 1119px) {
      .site-whitening .selfwhite .whitelist {
        padding: 0 30px; } }
    @media screen and (max-width: 768px) {
      .site-whitening .selfwhite .whitelist {
        padding: 0;
        margin: 16px 0 0;
        display: block; } }
    .site-whitening .selfwhite .whitelist li {
      width: 32%;
      border: #f1f4f9 4px solid;
      border-radius: 4px;
      padding: 20px;
      margin: 32px 0 0; }
      @media screen and (min-width: 1120px) {
        .site-whitening .selfwhite .whitelist li:nth-child(-n+3) {
          margin-top: 0; } }
      @media screen and (max-width: 1119px) {
        .site-whitening .selfwhite .whitelist li {
          width: 48%; }
          .site-whitening .selfwhite .whitelist li:nth-child(-n+2) {
            margin-top: 0; } }
      @media screen and (max-width: 768px) {
        .site-whitening .selfwhite .whitelist li {
          width: auto;
          margin: 0 0 16px;
          padding: 15px; }
          .site-whitening .selfwhite .whitelist li:last-child {
            margin-bottom: 0; } }
      .site-whitening .selfwhite .whitelist li i {
        text-align: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: #a4b9d7;
        color: #FFF;
        font-size: 24px;
        line-height: 1;
        margin: 0 auto 12px;
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        justify-content: center; }
      .site-whitening .selfwhite .whitelist li dl dt {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: #5d82b8;
        margin: 0 0 8px; }
      .site-whitening .selfwhite .whitelist li dl dd {
        text-align: justify;
        line-height: 1.66; }
        @media screen and (max-width: 768px) {
          .site-whitening .selfwhite .whitelist li dl dd {
            padding: 0 15px; } }
  .site-whitening .selfwhite .twoclum {
    position: relative;
    margin: 64px 0 0;
    background: #f1f4f9; }
    @media screen and (max-width: 1119px) {
      .site-whitening .selfwhite .twoclum {
        margin: 48px 0 0; } }
    @media screen and (max-width: 768px) {
      .site-whitening .selfwhite .twoclum {
        margin: 32px 0 0; } }
    .site-whitening .selfwhite .twoclum__pic {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: url("../../../images/whitening/self_face.jpg");
      background-size: cover;
      background-position: center; }
      @media screen and (max-width: 1119px) {
        .site-whitening .selfwhite .twoclum__pic {
          width: 40%; } }
      @media screen and (max-width: 768px) {
        .site-whitening .selfwhite .twoclum__pic {
          position: relative;
          width: auto;
          height: 160px; } }
    .site-whitening .selfwhite .twoclum dl {
      width: 50%;
      margin: 0 0 0 50%;
      padding: 88px 0;
      padding-left: 64px; }
      @media screen and (max-width: 1119px) {
        .site-whitening .selfwhite .twoclum dl {
          width: 60%;
          margin: 0 0 0 40%;
          padding: 48px 0;
          padding-left: 48px; } }
      @media screen and (max-width: 768px) {
        .site-whitening .selfwhite .twoclum dl {
          width: auto;
          margin: 0;
          padding: 20px 0; } }
      .site-whitening .selfwhite .twoclum dl dt {
        font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.04em;
        line-height: 1.58;
        font-size: 26px;
        font-weight: 500;
        margin: 0 0 20px;
        padding: 0 0 12px;
        border-bottom: #FFF 4px solid; }
        @media screen and (max-width: 1119px) {
          .site-whitening .selfwhite .twoclum dl dt {
            font-size: 24px; } }
        @media screen and (max-width: 768px) {
          .site-whitening .selfwhite .twoclum dl dt {
            font-size: 18px; } }
      .site-whitening .selfwhite .twoclum dl dd {
        text-align: justify; }
  .site-whitening .selfwhite .merit {
    background: #f1f4f9;
    background: -moz-linear-gradient(top, #f1f4f9 0%, white 100%);
    background: -webkit-linear-gradient(top, #f1f4f9 0%, white 100%);
    background: linear-gradient(to bottom, #f1f4f9 0%, white 100%);
    border-top: #FFF 8px solid;
    padding: 64px 0 96px; }
    @media screen and (max-width: 1119px) {
      .site-whitening .selfwhite .merit {
        padding: 40px 0; } }
    @media screen and (max-width: 768px) {
      .site-whitening .selfwhite .merit {
        padding: 32px 0; } }
    .site-whitening .selfwhite .merit .borders {
      font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
      font-weight: 500;
      -webkit-font-feature-settings: "palt";
      font-feature-settings: "palt";
      letter-spacing: 0.04em;
      font-size: 22px;
      border-bottom: #e8e8ea 1px solid;
      margin: 0 0 20px;
      padding: 0 0 4px 14px;
      position: relative;
      line-height: 1.5; }
      @media screen and (max-width: 768px) {
        .site-whitening .selfwhite .merit .borders {
          font-size: 17px; } }
      .site-whitening .selfwhite .merit .borders:before {
        content: '';
        width: 5px;
        height: 70%;
        border-radius: 999px;
        position: absolute;
        top: 10%;
        left: 0;
        background: #1c2a40; }
    .site-whitening .selfwhite .merit__list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      .site-whitening .selfwhite .merit__list li {
        background: #FFF;
        border: #cfdaea 2px solid;
        width: calc(100% / 6 - 10px);
        text-align: center;
        padding: 8px 15px;
        font-size: 17px;
        font-weight: 700;
        color: #5d82b8;
        box-shadow: 0 0 24px rgba(207, 218, 234, 0.32); }
        @media screen and (max-width: 1119px) {
          .site-whitening .selfwhite .merit__list li {
            width: 32%;
            margin: 24px 0 0; }
            .site-whitening .selfwhite .merit__list li:nth-child(-n+3) {
              margin-top: 0; } }
        @media screen and (max-width: 768px) {
          .site-whitening .selfwhite .merit__list li {
            width: 48.5%;
            font-size: 15px;
            margin: 0 0 15px; }
            .site-whitening .selfwhite .merit__list li:nth-last-child(-n+2) {
              margin-bottom: 0; } }
    .site-whitening .selfwhite .merit .lasted {
      padding: 32px 24px;
      text-align: center;
      margin: 48px 0 0;
      background: #FFF;
      box-shadow: 0 8px 24px rgba(207, 218, 234, 0.48);
      position: relative; }
      @media screen and (max-width: 768px) {
        .site-whitening .selfwhite .merit .lasted {
          margin: 40px 0 0;
          padding: 15px;
          text-align: justify; } }
      .site-whitening .selfwhite .merit .lasted:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 24px 20px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        top: -23px;
        left: 50%;
        transform: translateX(-50%); }
      .site-whitening .selfwhite .merit .lasted .btnarea {
        margin: 16px 0 0; }

.site-whitening .ticket__title {
  font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.04em;
  font-size: 32px;
  text-align: center;
  margin: 0 0 32px;
  line-height: 1.7; }
  @media screen and (max-width: 768px) {
    .site-whitening .ticket__title {
      font-size: 20px;
      margin: 0 0 24px; } }
  .site-whitening .ticket__title:after {
    content: attr(data-eng);
    font-size: 12px;
    display: block; }
    @media screen and (max-width: 768px) {
      .site-whitening .ticket__title:after {
        font-size: 11px; } }

.site-whitening .ticket__list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  background: #f1f4f9;
  padding: 15px;
  margin: 0 0 24px; }
  @media screen and (max-width: 768px) {
    .site-whitening .ticket__list {
      display: block; } }
  .site-whitening .ticket__list:last-of-type {
    margin: 0; }
  .site-whitening .ticket__list li {
    width: 32%;
    background: #FFF;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 1119px) {
      .site-whitening .ticket__list li {
        width: 32.5%; } }
    @media screen and (max-width: 768px) {
      .site-whitening .ticket__list li {
        width: auto;
        border-bottom: #f1f4f9 2px solid;
        padding: 15px; } }
    .site-whitening .ticket__list li figure {
      box-shadow: 0 0 15px rgba(207, 218, 234, 0.88); }
      @media screen and (max-width: 768px) {
        .site-whitening .ticket__list li figure {
          box-shadow: none; } }
    .site-whitening .ticket__list li img {
      width: 100%; }
    .site-whitening .ticket__list li dl {
      text-align: center;
      font-weight: 700; }
      .site-whitening .ticket__list li dl dt {
        font-size: 17px;
        margin: 0 0 12px; }
        @media screen and (max-width: 1119px) {
          .site-whitening .ticket__list li dl dt {
            font-size: 14px; } }
        @media screen and (max-width: 768px) {
          .site-whitening .ticket__list li dl dt {
            margin: 0 0 10px; } }
        .site-whitening .ticket__list li dl dt strong {
          font-size: 22px; }
          @media screen and (max-width: 1119px) {
            .site-whitening .ticket__list li dl dt strong {
              font-size: 18px; } }
      .site-whitening .ticket__list li dl dd {
        line-height: 1; }
        .site-whitening .ticket__list li dl dd.str {
          margin: 8px 0 0;
          font-size: 32px; }
          @media screen and (max-width: 1119px) {
            .site-whitening .ticket__list li dl dd.str {
              font-size: 24px; } }
          .site-whitening .ticket__list li dl dd.str strong {
            font-size: 40px;
            display: inline-block;
            margin: 0 2px; }
            @media screen and (max-width: 1119px) {
              .site-whitening .ticket__list li dl dd.str strong {
                font-size: 32px; } }

.site-whitening .undertext {
  margin: 32px 0 0;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .site-whitening .undertext {
      margin: 20px 0 0; } }

.asunaro-header {
  background: #FFF;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 82px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  z-index: 1000;
  -webkit-transition: all 200ms 0ms ease-in-out;
  -moz-transition: all 200ms 0ms ease-in-out;
  -ms-transition: all 200ms 0ms ease-in-out;
  -o-transition: all 200ms 0ms ease-in-out;
  transition: all 200ms 0ms ease-in-out; }
  @media screen and (max-width: 768px) {
    .asunaro-header {
      padding: 0 15px; } }
  .asunaro-header.active {
    box-shadow: 0 0 32px rgba(80, 82, 84, 0.1); }
  .asunaro-header .logo {
    height: 42px; }
    @media screen and (max-width: 768px) {
      .asunaro-header .logo {
        height: 36px; } }
    .asunaro-header .logo img {
      height: 100%; }
  .asunaro-header .gnavi ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .asunaro-header .gnavi ul {
        display: block; } }
    .asunaro-header .gnavi ul li {
      list-style: none;
      margin: 0 0 0 48px;
      font-size: 15px; }
      @media screen and (max-width: 1119px) {
        .asunaro-header .gnavi ul li {
          display: none; }
          .asunaro-header .gnavi ul li:nth-last-child(-n+2) {
            display: block; } }
      @media screen and (max-width: 768px) {
        .asunaro-header .gnavi ul li {
          margin: 0 0 4px; } }
      .asunaro-header .gnavi ul li:first-child {
        margin: 0; }
      .asunaro-header .gnavi ul li:last-child {
        margin-left: 8px; }
        @media screen and (max-width: 768px) {
          .asunaro-header .gnavi ul li:last-child {
            margin: 0; } }
      .asunaro-header .gnavi ul li a {
        color: #1c2a40; }
        .asunaro-header .gnavi ul li a.btn {
          background: #f3f3f4;
          padding: 6px 32px;
          display: block;
          -webkit-transition: all 200ms 0ms ease-in-out;
          -moz-transition: all 200ms 0ms ease-in-out;
          -ms-transition: all 200ms 0ms ease-in-out;
          -o-transition: all 200ms 0ms ease-in-out;
          transition: all 200ms 0ms ease-in-out; }
          @media screen and (max-width: 768px) {
            .asunaro-header .gnavi ul li a.btn {
              font-size: 12px;
              padding: 2px 15px;
              min-width: 130px;
              text-align: center; } }
          .asunaro-header .gnavi ul li a.btn:hover {
            color: #FFF;
            background: #5a8a9f; }
            .asunaro-header .gnavi ul li a.btn:hover > i {
              color: #FFF; }
          .asunaro-header .gnavi ul li a.btn i {
            display: inline-block;
            margin: 0 0 0 8px;
            font-size: 11px;
            vertical-align: middle;
            color: #5a8a9f;
            transform: translateY(-1px);
            -webkit-transition: all 200ms 0ms ease-in-out;
            -moz-transition: all 200ms 0ms ease-in-out;
            -ms-transition: all 200ms 0ms ease-in-out;
            -o-transition: all 200ms 0ms ease-in-out;
            transition: all 200ms 0ms ease-in-out; }

.asunaro-footer {
  background: #414344;
  color: #ebf1f4; }
  .asunaro-footer a {
    color: #ebf1f4; }
  .asunaro-footer .pagetop {
    background: #323335;
    display: block;
    text-align: center;
    padding: 15px;
    -webkit-transition: all 200ms 0ms ease-in-out;
    -moz-transition: all 200ms 0ms ease-in-out;
    -ms-transition: all 200ms 0ms ease-in-out;
    -o-transition: all 200ms 0ms ease-in-out;
    transition: all 200ms 0ms ease-in-out; }
    .asunaro-footer .pagetop:hover {
      background: #3c3e3f; }
  .asunaro-footer .footerlist {
    padding: 40px 0;
    border-bottom: rgba(255, 255, 255, 0.12) 1px solid; }
    @media screen and (max-width: 768px) {
      .asunaro-footer .footerlist {
        padding: 24px 0; } }
    .asunaro-footer .footerlist ul {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      font-size: 13px; }
      @media screen and (max-width: 768px) {
        .asunaro-footer .footerlist ul {
          display: block; } }
      .asunaro-footer .footerlist ul li {
        margin: 0 32px 0 0; }
        @media screen and (max-width: 768px) {
          .asunaro-footer .footerlist ul li {
            margin: 0 0 1px; }
            .asunaro-footer .footerlist ul li:last-child {
              margin: 0; }
            .asunaro-footer .footerlist ul li a {
              display: block;
              padding: 15px;
              background: rgba(0, 0, 0, 0.12); } }
        .asunaro-footer .footerlist ul li i {
          display: inline-block;
          margin: 0 0 0 6px;
          transform: translateY(-1px);
          font-size: 10px; }
        .asunaro-footer .footerlist ul li:last-child {
          margin: 0; }
  .asunaro-footer .copy {
    display: block;
    text-align: center;
    padding: 40px 0; }
    @media screen and (max-width: 768px) {
      .asunaro-footer .copy {
        padding: 15px 0; } }

.asunaro-fv {
  position: relative;
  z-index: 2;
  overflow: hidden; }
  .asunaro-fv #asunaro-swipe {
    max-width: 2560px;
    margin: 0 auto;
    overflow: visible;
    position: relative; }
    .asunaro-fv #asunaro-swipe.swiper-container-initialized h1 {
      opacity: 1; }
    .asunaro-fv #asunaro-swipe .title {
      position: absolute;
      top: calc(50% - 20px);
      left: 8%;
      transform: translateY(-50%);
      z-index: 3; }
      .asunaro-fv #asunaro-swipe .title h1 {
        font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.04em;
        font-weight: 300;
        letter-spacing: 0;
        font-size: calc(44px + 18 * (100vw - 450px)/ 900);
        line-height: 1.4; }
        @media screen and (max-width: 768px) {
          .asunaro-fv #asunaro-swipe .title h1 {
            font-size: 24px; } }
      .asunaro-fv #asunaro-swipe .title .eng {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        font-size: calc(12px + 4 * (100vw - 450px)/ 900);
        color: #d0a200;
        text-indent: .2em;
        line-height: 1;
        margin: 20px 0 0;
        letter-spacing: .2em; }
        @media screen and (max-width: 768px) {
          .asunaro-fv #asunaro-swipe .title .eng {
            font-size: 10px;
            margin: 12px 0 0; } }
    .asunaro-fv #asunaro-swipe .copy {
      position: absolute;
      left: 40px;
      bottom: 40px;
      font-size: 10px;
      line-height: 1;
      z-index: 3; }
      @media screen and (max-width: 768px) {
        .asunaro-fv #asunaro-swipe .copy {
          display: none; } }
    .asunaro-fv #asunaro-swipe .swiper-slide {
      width: 100%;
      height: calc(100vh - 82px);
      min-height: 640px;
      overflow: hidden; }
      @media screen and (max-width: 1119px) {
        .asunaro-fv #asunaro-swipe .swiper-slide {
          min-height: 1px;
          height: 480px; } }
      @media screen and (max-width: 768px) {
        .asunaro-fv #asunaro-swipe .swiper-slide {
          height: 260px; } }
      .asunaro-fv #asunaro-swipe .swiper-slide .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center; }
        .asunaro-fv #asunaro-swipe .swiper-slide .bg img {
          display: none; }
    .asunaro-fv #asunaro-swipe .swiper-slide-active .bg,
    .asunaro-fv #asunaro-swipe .swiper-slide-duplicate-active .bg,
    .asunaro-fv #asunaro-swipe .swiper-slide-prev .bg {
      animation: zoomUp 8s linear 0s 1 normal both; }

@keyframes zoomUp {
  0% {
    transform: scale(1.08); }
  100% {
    transform: scale(1); } }

.asunaro-body {
  background: #FFF;
  color: #505254; }
  .asunaro-body .asunaro-module.wrap {
    padding: 80px 0; }
    @media screen and (max-width: 1119px) {
      .asunaro-body .asunaro-module.wrap {
        padding: 56px 0; } }
    .asunaro-body .asunaro-module.wrap.no-top {
      padding-top: 0; }
    .asunaro-body .asunaro-module.wrap.no-bottom {
      padding-bottom: 0; }
  .asunaro-body .asunaro-module h3.large {
    text-align: center;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    padding: 0 0 32px; }
    @media screen and (max-width: 768px) {
      .asunaro-body .asunaro-module h3.large {
        font-size: 13px; } }
    .asunaro-body .asunaro-module h3.large:before {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      content: attr(data-eng);
      font-size: 42px;
      font-weight: 700;
      text-align: center;
      display: block;
      color: #5a8a9f;
      letter-spacing: .12em;
      margin: 0 0 12px; }
      @media screen and (max-width: 768px) {
        .asunaro-body .asunaro-module h3.large:before {
          font-size: 28px;
          margin: 0 0 8px; } }
    .asunaro-body .asunaro-module h3.large.white:before {
      color: #FFF; }
  .asunaro-body .asunaro-module .tablestyle {
    border: #e8e8ea 1px solid;
    width: 100%;
    border-collapse: collapse;
    font-size: 16px; }
    @media screen and (max-width: 768px) {
      .asunaro-body .asunaro-module .tablestyle {
        font-size: 14px; } }
    .asunaro-body .asunaro-module .tablestyle tbody tr {
      border-bottom: #e8e8ea 1px solid; }
    .asunaro-body .asunaro-module .tablestyle tbody th, .asunaro-body .asunaro-module .tablestyle tbody td {
      vertical-align: middle;
      text-align: left;
      padding: 32px 20px; }
      @media screen and (max-width: 768px) {
        .asunaro-body .asunaro-module .tablestyle tbody th, .asunaro-body .asunaro-module .tablestyle tbody td {
          padding: 15px; } }
    .asunaro-body .asunaro-module .tablestyle tbody th {
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        .asunaro-body .asunaro-module .tablestyle tbody th {
          width: 140px; } }

.asunaro-article,
.asunaro-footer {
  position: relative;
  top: 82px;
  left: 0; }

.asunaro-first {
  position: relative;
  z-index: 2; }
  .asunaro-first .firstcontent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    @media screen and (max-width: 1119px) {
      .asunaro-first .firstcontent {
        top: -15px; } }
    .asunaro-first .firstcontent__box {
      background: #FFF;
      padding-top: 64px;
      padding-bottom: 64px;
      box-shadow: 0 -32px 32px rgba(28, 42, 64, 0.08); }
      @media screen and (max-width: 768px) {
        .asunaro-first .firstcontent__box {
          padding-top: 32px;
          padding-bottom: 32px; } }
      .asunaro-first .firstcontent__box h2 {
        font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.04em;
        text-align: center;
        font-size: 22px;
        line-height: 1.7;
        font-weight: 300; }
        @media screen and (max-width: 768px) {
          .asunaro-first .firstcontent__box h2 {
            font-size: 16px;
            margin: 0 30px; }
            .asunaro-first .firstcontent__box h2 br {
              display: none; } }
        .asunaro-first .firstcontent__box h2:before {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          content: attr(data-eng);
          color: #5a8a9f;
          display: block;
          text-align: center;
          font-size: 13px;
          letter-spacing: .12em;
          margin: 0 0 8px; }
      .asunaro-first .firstcontent__box .context {
        text-align: center;
        font-size: 15px;
        margin: 16px 0 0; }
        @media screen and (max-width: 768px) {
          .asunaro-first .firstcontent__box .context {
            margin: 15px 15px 0;
            font-size: 12.5px; }
            .asunaro-first .firstcontent__box .context br {
              display: none; } }
  .asunaro-first .secondcontent {
    padding: 300px 0 80px;
    position: relative;
    overflow: hidden;
    z-index: 1; }
    @media screen and (max-width: 1119px) {
      .asunaro-first .secondcontent {
        padding: 250px 0 56px; } }
    @media screen and (max-width: 768px) {
      .asunaro-first .secondcontent {
        padding: 250px 0 32px; } }
    .asunaro-first .secondcontent:before, .asunaro-first .secondcontent:after {
      content: '';
      width: 140%;
      height: 760px;
      background: #ebf1f4;
      position: absolute;
      left: -20%;
      bottom: -400px;
      transform: rotate(10deg); }
      @media screen and (max-width: 768px) {
        .asunaro-first .secondcontent:before, .asunaro-first .secondcontent:after {
          transform: rotate(0deg);
          left: 0;
          bottom: -2px;
          width: 100%;
          height: calc(88% - 250px); } }
    .asunaro-first .secondcontent:before {
      left: auto;
      right: -20%;
      transform: rotate(-10deg); }
      @media screen and (max-width: 768px) {
        .asunaro-first .secondcontent:before {
          display: none; } }
    .asunaro-first .secondcontent__list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      position: relative;
      z-index: 2; }
      @media screen and (max-width: 768px) {
        .asunaro-first .secondcontent__list {
          display: block; } }
      .asunaro-first .secondcontent__list li {
        width: 30.5%;
        background: #FFF; }
        @media screen and (max-width: 1119px) {
          .asunaro-first .secondcontent__list li {
            width: 32%; } }
        @media screen and (max-width: 768px) {
          .asunaro-first .secondcontent__list li {
            width: auto;
            margin: 0 0 15px; }
            .asunaro-first .secondcontent__list li:last-child {
              margin: 0; } }
        .asunaro-first .secondcontent__list li:nth-child(odd) {
          transform: translateY(-32px); }
          @media screen and (max-width: 768px) {
            .asunaro-first .secondcontent__list li:nth-child(odd) {
              transform: translateY(0); } }
        .asunaro-first .secondcontent__list li img {
          width: 100%; }
        .asunaro-first .secondcontent__list li dl {
          padding: 20px; }
          @media screen and (max-width: 1119px) {
            .asunaro-first .secondcontent__list li dl {
              padding: 15px; } }
          .asunaro-first .secondcontent__list li dl > dt {
            font-weight: 700;
            font-size: 18px;
            text-align: center;
            margin: 0 0 6px; }
            @media screen and (max-width: 1119px) {
              .asunaro-first .secondcontent__list li dl > dt {
                font-size: 16px; } }
            .asunaro-first .secondcontent__list li dl > dt:before {
              content: attr(data-eng);
              color: #d0a200;
              display: block;
              font-size: 9px; }
          .asunaro-first .secondcontent__list li dl > dd {
            text-align: justify;
            font-size: 13.5px;
            line-height: 1.66; }

.asunaro-second {
  background: #ebf1f4;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 768px) {
    .asunaro-second {
      background: #FFF;
      padding-top: 0 !important; } }
  .asunaro-second:before, .asunaro-second:after {
    content: '';
    width: 72%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: #FFF;
    z-index: 1; }
    @media screen and (max-width: 768px) {
      .asunaro-second:before, .asunaro-second:after {
        display: none; } }
  .asunaro-second:after {
    content: '';
    width: 100%;
    height: calc(100% - 80px);
    position: absolute;
    top: 80px;
    right: 0;
    background: #FFF;
    z-index: 1; }
  .asunaro-second .bg {
    position: absolute;
    top: 80px;
    left: 0;
    width: 50%;
    height: calc(100% - (80px * 2));
    background-size: cover;
    background-position: center;
    z-index: 2; }
    @media screen and (max-width: 768px) {
      .asunaro-second .bg {
        top: 0;
        position: relative;
        width: 100%;
        height: 200px; } }
  .asunaro-second .content {
    margin: 0 0 0 50%;
    padding: 64px 0;
    padding-left: 56px;
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 1119px) {
      .asunaro-second .content {
        padding: 48px 0;
        padding-left: 32px; } }
    @media screen and (max-width: 768px) {
      .asunaro-second .content {
        padding: 32px 0 0;
        margin: 0; } }
    .asunaro-second .content dt {
      font-size: 30px;
      font-weight: 700;
      line-height: 1;
      margin: 0 0 28px; }
      @media screen and (max-width: 1119px) {
        .asunaro-second .content dt {
          font-size: 24px;
          line-height: 1.5;
          margin: 0 0 12px; } }
      @media screen and (max-width: 768px) {
        .asunaro-second .content dt {
          font-size: 20px;
          text-align: center; } }
      .asunaro-second .content dt:before {
        content: attr(data-eng);
        font-size: 11px;
        font-weight: 700;
        display: block;
        margin: 0 0 16px;
        color: #5a8a9f;
        text-indent: .24em;
        letter-spacing: .12em; }
        @media screen and (max-width: 1119px) {
          .asunaro-second .content dt:before {
            margin: 0 0 8px; } }
    .asunaro-second .content dd {
      font-size: 15px;
      text-align: justify; }
      @media screen and (max-width: 1119px) {
        .asunaro-second .content dd {
          font-size: 14px; } }
      @media screen and (max-width: 768px) {
        .asunaro-second .content dd {
          font-size: 13px; } }
      .asunaro-second .content dd.btnarea {
        margin: 32px 0 0; }
        @media screen and (max-width: 768px) {
          .asunaro-second .content dd.btnarea {
            margin: 24px 0 0; } }
      .asunaro-second .content dd a {
        background: #5a8a9f;
        color: #FFF;
        display: inline-block;
        font-size: 17px;
        padding: 11px 40px; }
        @media screen and (max-width: 1119px) {
          .asunaro-second .content dd a {
            font-size: 15px;
            padding: 10px 32px; } }
        @media screen and (max-width: 768px) {
          .asunaro-second .content dd a {
            display: block;
            text-align: center;
            padding: 12px 15px;
            font-size: 17px; } }
        .asunaro-second .content dd a:hover {
          background: #4b7385; }

.asunaro-third {
  overflow: visible;
  position: relative;
  z-index: 2; }
  .asunaro-third:after {
    content: '';
    width: 100%;
    height: 8px;
    background: #f3f3f4;
    position: absolute;
    top: calc(50% - 40px);
    left: 0;
    transform: translateY(-50%); }
  .asunaro-third dl {
    background: #f3f3f4;
    padding: 32px 80px;
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 768px) {
      .asunaro-third dl {
        padding: 15px; } }
    .asunaro-third dl:before {
      content: "\f5c9";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: #a6c1cd;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 32px; }
    .asunaro-third dl dt {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      margin: 0 0 12px; }
      @media screen and (max-width: 768px) {
        .asunaro-third dl dt {
          font-size: 20px;
          margin: 0 0 8px;
          padding: 15px 0 0; } }
    .asunaro-third dl dd {
      font-size: 15px;
      text-align: center; }
      @media screen and (max-width: 768px) {
        .asunaro-third dl dd {
          font-size: 13px;
          text-align: justify; } }

.asunaro-four {
  background: url("../../../images/asunaro/soccer.jpg") no-repeat center fixed;
  background-size: cover;
  position: relative;
  color: #FFF; }
  .asunaro-four:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #537f92;
    opacity: .88;
    z-index: 1; }
  .asunaro-four .display {
    z-index: 2; }
  .asunaro-four h4 {
    text-align: center;
    font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em;
    font-size: 28px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      .asunaro-four h4 {
        font-size: 18px; } }
    .asunaro-four h4:after {
      content: attr(data-jp);
      font-size: 14px;
      display: block; }
      @media screen and (max-width: 768px) {
        .asunaro-four h4:after {
          font-size: 12px; } }
  .asunaro-four .stronger {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 4px;
    border: #FFF 1px solid;
    margin: 20px 0 0; }
    .asunaro-four .stronger strong {
      background: #FFF;
      color: #35515e;
      display: block;
      padding: 8px 0; }
  .asunaro-four .context {
    text-align: center;
    font-size: 15px;
    margin: 24px 0 0; }
    @media screen and (max-width: 768px) {
      .asunaro-four .context {
        font-size: 14px; } }
  .asunaro-four .list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    margin: 32px 0; }
    @media screen and (max-width: 768px) {
      .asunaro-four .list {
        display: block;
        margin: 24px 0; } }
    .asunaro-four .list li {
      width: 32%; }
      @media screen and (max-width: 768px) {
        .asunaro-four .list li {
          width: auto;
          margin: 0 0 15px; }
          .asunaro-four .list li:last-child {
            margin: 0; } }
      .asunaro-four .list li img {
        width: 100%; }
  .asunaro-four .btnarea {
    text-align: center; }
    .asunaro-four .btnarea a {
      background: #5a8a9f;
      color: #FFF;
      display: inline-block;
      font-size: 17px;
      padding: 11px 40px;
      background: #1f3037; }
      @media screen and (max-width: 1119px) {
        .asunaro-four .btnarea a {
          font-size: 15px;
          padding: 10px 32px; } }
      @media screen and (max-width: 768px) {
        .asunaro-four .btnarea a {
          display: block;
          text-align: center;
          padding: 12px 15px;
          font-size: 17px; } }
      .asunaro-four .btnarea a:hover {
        background: #4b7385; }
      .asunaro-four .btnarea a i {
        display: inline-block;
        margin: 0 0 0 8px;
        font-size: 12px;
        transform: translateY(-2px); }
      .asunaro-four .btnarea a:hover {
        background: #35515e; }

.googlemap {
  position: relative; }
  .googlemap:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 0 10px 10px rgba(80, 82, 84, 0.04);
    z-index: 2;
    pointer-events: none; }
  .googlemap iframe {
    width: 100%;
    height: 300px;
    vertical-align: bottom;
    filter: grayscale(1); }
    @media screen and (max-width: 768px) {
      .googlemap iframe {
        height: 200px; } }

.meijyu-header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #FFF;
  padding: 0 40px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 1119px) {
    .meijyu-header {
      height: 64px; } }
  @media screen and (max-width: 768px) {
    .meijyu-header {
      position: relative; } }
  .meijyu-header.active {
    box-shadow: 0 0 32px rgba(66, 71, 70, 0.12); }
  .meijyu-header .logo {
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }
    @media screen and (max-width: 1119px) {
      .meijyu-header .logo {
        width: 160px; } }
    .meijyu-header .logo img {
      width: 100%; }
  @media screen and (max-width: 768px) {
    .meijyu-header .gnavi {
      display: none; } }
  .meijyu-header .gnavi ul {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    transform: translateX(10px); }
    @media screen and (max-width: 1119px) {
      .meijyu-header .gnavi ul {
        transform: translateX(8px); } }
    .meijyu-header .gnavi ul li {
      margin: 0 24px 0 0; }
      @media screen and (max-width: 1119px) {
        .meijyu-header .gnavi ul li {
          margin: 0 12px 0 0; } }
      .meijyu-header .gnavi ul li:nth-child(3) {
        margin-right: 320px; }
        @media screen and (max-width: 1119px) {
          .meijyu-header .gnavi ul li:nth-child(3) {
            margin-right: 220px; } }
        .meijyu-header .gnavi ul li:nth-child(3):after {
          display: none; }
      .meijyu-header .gnavi ul li:last-child {
        margin: 0; }
        .meijyu-header .gnavi ul li:last-child:after {
          display: none; }
      .meijyu-header .gnavi ul li:after {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #00b898;
        display: inline-block;
        margin: 0 0 0 24px;
        transform: translateY(-2px);
        opacity: .42; }
        @media screen and (max-width: 1119px) {
          .meijyu-header .gnavi ul li:after {
            margin: 0 0 0 12px; } }
      .meijyu-header .gnavi ul li a {
        font-size: 15.5px;
        color: #424746; }
        @media screen and (max-width: 1119px) {
          .meijyu-header .gnavi ul li a {
            font-size: 14px; } }

.meijyu-footer {
  position: relative;
  top: 80px;
  left: 0;
  background: #008f76;
  padding: 24px 0;
  text-align: center;
  color: #FFF; }
  @media screen and (max-width: 1119px) {
    .meijyu-footer {
      top: 64px; } }
  @media screen and (max-width: 768px) {
    .meijyu-footer {
      top: 0;
      font-size: 8px; } }

.meijyu-fv {
  width: 100%;
  height: calc(100vh - 80px);
  min-height: 560px;
  background: url("/images/meijyu/fv.jpg") right center;
  background-size: cover;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative; }
  @media screen and (max-width: 1119px) {
    .meijyu-fv {
      min-height: 1px;
      height: 480px; } }
  @media screen and (max-width: 768px) {
    .meijyu-fv {
      height: 240px; } }
  .meijyu-fv:before, .meijyu-fv:after {
    content: '';
    width: 20vw;
    height: 20vw;
    position: absolute;
    top: -10vw;
    left: -10vw;
    transform: rotate(45deg);
    background: #00b898;
    opacity: .64; }
  .meijyu-fv:after {
    top: auto;
    left: auto;
    bottom: -10vw;
    right: -10vw; }
  .meijyu-fv i.conner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none; }
    .meijyu-fv i.conner:before, .meijyu-fv i.conner:after {
      content: '';
      width: 18vw;
      height: 8px;
      background: #00b898;
      position: absolute;
      top: 16vw;
      left: -8vw;
      border-radius: 999px;
      transform: rotate(-45deg);
      opacity: .64; }
    .meijyu-fv i.conner:after {
      top: auto;
      left: auto;
      bottom: 16vw;
      right: -8vw;
      transform: rotate(-45deg); }
  .meijyu-fv .title {
    position: relative;
    z-index: 3;
    text-align: center;
    transform: translateY(-32px); }
    @media screen and (max-width: 768px) {
      .meijyu-fv .title {
        transform: translateY(0); } }
    .meijyu-fv .title__eng {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      color: #00b898;
      font-size: 72px;
      font-weight: 600;
      line-height: 1.1;
      margin: 0 0 16px;
      letter-spacing: 0em; }
      @media screen and (max-width: 1119px) {
        .meijyu-fv .title__eng {
          font-size: 56px; } }
      @media screen and (max-width: 768px) {
        .meijyu-fv .title__eng {
          font-size: 32px; } }
      .meijyu-fv .title__eng .le {
        text-indent: -.16em;
        display: inline-block;
        letter-spacing: .032em; }
    .meijyu-fv .title h1 {
      font-size: 16px;
      background: #FFF;
      color: #424746;
      font-weight: 500;
      display: inline-block;
      line-height: 1;
      padding: 18px 32px;
      border-radius: 999px; }
      @media screen and (max-width: 1119px) {
        .meijyu-fv .title h1 {
          font-size: 15px; } }
      @media screen and (max-width: 768px) {
        .meijyu-fv .title h1 {
          padding: 8px 16px;
          font-size: 10px; } }
  .meijyu-fv .scroll {
    position: absolute;
    left: 50%;
    bottom: 40px;
    z-index: 100;
    transform: translateX(-50%);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: .24em; }
    @media screen and (max-width: 1119px) {
      .meijyu-fv .scroll {
        bottom: 15px; } }
    @media screen and (max-width: 768px) {
      .meijyu-fv .scroll {
        display: none; } }
    .meijyu-fv .scroll:before {
      content: '';
      width: 30px;
      height: 52px;
      background: #FFF;
      border-radius: 999px;
      position: absolute;
      bottom: 32px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 0 32px rgba(66, 71, 70, 0.16); }
      @media screen and (max-width: 1119px) {
        .meijyu-fv .scroll:before {
          bottom: 24px; } }
    .meijyu-fv .scroll:after {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #00b898;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 64px;
      animation: sce 1600ms infinite; }
      @media screen and (max-width: 1119px) {
        .meijyu-fv .scroll:after {
          bottom: 56px; } }

@keyframes sce {
  0% {
    bottom: 56px;
    opacity: 0; }
  20% {
    bottom: 56px;
    opacity: 1; }
  70% {
    bottom: 40px;
    opacity: 1; }
  100% {
    bottom: 40px;
    opacity: 0; } }

.meijyu-body {
  background: #FFF;
  color: #424746; }
  .meijyu-body a {
    color: #424746; }
  .meijyu-body .meijyu-module.wrap {
    padding: 80px 0; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module.wrap {
        padding: 64px 0; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module.wrap {
        padding: 32px 0; } }
    .meijyu-body .meijyu-module.wrap.border {
      border-bottom: #e8e8ea 1px solid; }
    .meijyu-body .meijyu-module.wrap.no-top {
      padding-top: 0; }
    .meijyu-body .meijyu-module.wrap.no-bottom {
      padding-bottom: 0; }
    .meijyu-body .meijyu-module.wrap.green {
      background: #00b898;
      color: #FFF; }
      .meijyu-body .meijyu-module.wrap.green.tx {
        background: #00b898 url("../../../../images/meijyu/white_dotto.png") repeat;
        background-size: 16px; }
  .meijyu-body .meijyu-module.dotto-bg {
    background: url("../../../../images/meijyu/black_dotto.png") repeat;
    background-size: 16px; }
    .meijyu-body .meijyu-module.dotto-bg--darken {
      background: #eff5f4 url("../../../../images/meijyu/black_dotto.png") repeat;
      background-size: 16px; }
  .meijyu-body .meijyu-module.bg {
    background: #eff5f4; }
  .meijyu-body .meijyu-module h2 {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.6;
    margin: 0 0 24px; }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module h2 {
        font-size: 16px;
        margin: 0 0 16px; } }
    .meijyu-body .meijyu-module h2 strong {
      color: #00b898; }
    .meijyu-body .meijyu-module h2:before {
      content: attr(data-eng);
      display: block;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: .24em;
      color: #00b898;
      font-weight: 600;
      margin: 0 0 8px; }
    .meijyu-body .meijyu-module h2.large {
      font-size: 38px; }
      @media screen and (max-width: 768px) {
        .meijyu-body .meijyu-module h2.large {
          font-size: 24px; } }
      @media screen and (max-width: 768px) {
        .meijyu-body .meijyu-module h2.large {
          font-size: 24px; } }
      .meijyu-body .meijyu-module h2.large:before {
        margin: 0 0 3px; }
  .meijyu-body .meijyu-module h3 {
    font-weight: 700;
    font-size: 40px;
    line-height: 1;
    letter-spacing: .12em;
    text-align: center;
    margin: 0 0 32px; }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module h3 {
        font-size: 24px;
        white-space: nowrap; } }
  .meijyu-body .meijyu-module .tablestyle {
    border: none;
    width: 100%;
    border-collapse: collapse; }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .tablestyle {
        display: block; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .tablestyle tbody {
        display: block; } }
    .meijyu-body .meijyu-module .tablestyle tbody tr {
      border-bottom: #e8e8ea 1px solid; }
      @media screen and (max-width: 768px) {
        .meijyu-body .meijyu-module .tablestyle tbody tr {
          display: block; } }
      .meijyu-body .meijyu-module .tablestyle tbody tr:last-child {
        border: none; }
        .meijyu-body .meijyu-module .tablestyle tbody tr:last-child th, .meijyu-body .meijyu-module .tablestyle tbody tr:last-child td {
          padding-bottom: 0; }
      .meijyu-body .meijyu-module .tablestyle tbody tr th, .meijyu-body .meijyu-module .tablestyle tbody tr td {
        padding: 32px 0;
        font-size: 15px;
        text-align: left;
        vertical-align: middle; }
        @media screen and (max-width: 768px) {
          .meijyu-body .meijyu-module .tablestyle tbody tr th, .meijyu-body .meijyu-module .tablestyle tbody tr td {
            padding: 8px 0;
            font-size: 13px;
            display: block; } }
      .meijyu-body .meijyu-module .tablestyle tbody tr th {
        font-weight: 700;
        color: #00b898;
        font-size: 20px;
        width: 300px; }
        @media screen and (max-width: 768px) {
          .meijyu-body .meijyu-module .tablestyle tbody tr th {
            font-size: 15px;
            width: auto;
            padding-bottom: 0; } }
      .meijyu-body .meijyu-module .tablestyle tbody tr td {
        padding-left: 40px; }
        @media screen and (max-width: 768px) {
          .meijyu-body .meijyu-module .tablestyle tbody tr td {
            padding-left: 0; } }
        .meijyu-body .meijyu-module .tablestyle tbody tr td strong {
          font-weight: 700;
          font-size: 28px;
          line-height: 1;
          color: #f76c82;
          margin: 0 0 8px; }
          @media screen and (max-width: 1119px) {
            .meijyu-body .meijyu-module .tablestyle tbody tr td strong {
              font-size: 20px; } }
        .meijyu-body .meijyu-module .tablestyle tbody tr td dl dt {
          font-weight: 700;
          font-size: 28px;
          line-height: 1;
          color: #f76c82;
          margin: 0 0 8px; }
          @media screen and (max-width: 1119px) {
            .meijyu-body .meijyu-module .tablestyle tbody tr td dl dt {
              font-size: 20px; } }
        .meijyu-body .meijyu-module .tablestyle tbody tr td dl.contact {
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          align-items: center;
          justify-content: flex-start; }
          .meijyu-body .meijyu-module .tablestyle tbody tr td dl.contact dt, .meijyu-body .meijyu-module .tablestyle tbody tr td dl.contact dd {
            font-size: 14.5px;
            font-weight: 400;
            line-height: 1.5;
            margin: 0; }
          .meijyu-body .meijyu-module .tablestyle tbody tr td dl.contact dt {
            color: #1c2a40;
            margin: 0 20px 0 0;
            width: 130px;
            background: #e3e9f3;
            padding: 3px 12px;
            text-align: center;
            font-weight: 700; }
          .meijyu-body .meijyu-module .tablestyle tbody tr td dl.contact dd a {
            color: #00b898;
            text-decoration: underline; }
        .meijyu-body .meijyu-module .tablestyle tbody tr td dl.timer {
          display: -webkit-flex;
          display: -moz-flex;
          display: -o-flex;
          display: -ms-flex;
          display: flex;
          -moz-flex-flow: row wrap;
          -webkit-flex-flow: row wrap;
          -o-flex-flow: row wrap;
          -ms-flex-flow: row wrap;
          flex-flow: row wrap;
          -webkit-justify-content: space-between;
          -o-justify-content: space-between;
          -ms-justify-content: space-between;
          -moz-justify-content: space-between;
          justify-content: space-between;
          align-items: center;
          justify-content: flex-start; }
          .meijyu-body .meijyu-module .tablestyle tbody tr td dl.timer dt, .meijyu-body .meijyu-module .tablestyle tbody tr td dl.timer dd {
            font-size: 16px; }
          .meijyu-body .meijyu-module .tablestyle tbody tr td dl.timer dt {
            margin: 0;
            width: 100px; }
          .meijyu-body .meijyu-module .tablestyle tbody tr td dl.timer dd:after {
            content: '|';
            margin: 0 15px;
            display: inline-block;
            opacity: .24; }
          .meijyu-body .meijyu-module .tablestyle tbody tr td dl.timer dd:last-of-type:after {
            display: none; }
    .meijyu-body .meijyu-module .tablestyle--ss tbody tr th {
      width: 150px; }
      @media screen and (max-width: 768px) {
        .meijyu-body .meijyu-module .tablestyle--ss tbody tr th {
          width: auto; } }
    .meijyu-body .meijyu-module .tablestyle--border {
      border: #e8e8ea 1px solid; }
      @media screen and (max-width: 768px) {
        .meijyu-body .meijyu-module .tablestyle--border tbody tr {
          padding: 5px 0; } }
      .meijyu-body .meijyu-module .tablestyle--border tbody tr:last-child th, .meijyu-body .meijyu-module .tablestyle--border tbody tr:last-child td {
        padding-bottom: 32px; }
        @media screen and (max-width: 768px) {
          .meijyu-body .meijyu-module .tablestyle--border tbody tr:last-child th, .meijyu-body .meijyu-module .tablestyle--border tbody tr:last-child td {
            padding-bottom: 5px; } }
      .meijyu-body .meijyu-module .tablestyle--border tbody tr th,
      .meijyu-body .meijyu-module .tablestyle--border tbody tr td {
        padding: 32px 40px; }
        @media screen and (max-width: 768px) {
          .meijyu-body .meijyu-module .tablestyle--border tbody tr th,
          .meijyu-body .meijyu-module .tablestyle--border tbody tr td {
            padding: 5px 15px; } }
      .meijyu-body .meijyu-module .tablestyle--border tbody tr th {
        width: 200px; }
        @media screen and (max-width: 768px) {
          .meijyu-body .meijyu-module .tablestyle--border tbody tr th {
            width: auto;
            padding-bottom: 0 !important; } }
  .meijyu-body .meijyu-module .context {
    text-align: center;
    font-size: 15px; }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .context {
        font-size: 13px; } }
    .meijyu-body .meijyu-module .context.glay {
      background: #eff5f4;
      padding: 15px 24px; }
    .meijyu-body .meijyu-module .context--ll {
      font-size: 18px; }
      @media screen and (max-width: 768px) {
        .meijyu-body .meijyu-module .context--ll {
          font-size: 13px; } }
  .meijyu-body .meijyu-module .mt-px-0 {
    margin-top: 0px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-0 {
        margin-top: 0px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-0 {
        margin-top: 0px; } }
  .meijyu-body .meijyu-module .mb-px-0 {
    margin-bottom: 0px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-0 {
        margin-bottom: 0px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-0 {
        margin-bottom: 0px; } }
  .meijyu-body .meijyu-module .pt-px-0 {
    padding-top: 0px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-0 {
        padding-top: 0px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-0 {
        padding-top: 0px; } }
  .meijyu-body .meijyu-module .pb-px-0 {
    padding-bottom: 0px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-0 {
        padding-bottom: 0px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-0 {
        padding-bottom: 0px; } }
  .meijyu-body .meijyu-module .mt-px-1 {
    margin-top: 8px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-1 {
        margin-top: 6px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-1 {
        margin-top: 4px; } }
  .meijyu-body .meijyu-module .mb-px-1 {
    margin-bottom: 8px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-1 {
        margin-bottom: 6px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-1 {
        margin-bottom: 4px; } }
  .meijyu-body .meijyu-module .pt-px-1 {
    padding-top: 8px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-1 {
        padding-top: 6px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-1 {
        padding-top: 4px; } }
  .meijyu-body .meijyu-module .pb-px-1 {
    padding-bottom: 8px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-1 {
        padding-bottom: 6px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-1 {
        padding-bottom: 4px; } }
  .meijyu-body .meijyu-module .mt-px-2 {
    margin-top: 16px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-2 {
        margin-top: 12px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-2 {
        margin-top: 8px; } }
  .meijyu-body .meijyu-module .mb-px-2 {
    margin-bottom: 16px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-2 {
        margin-bottom: 12px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-2 {
        margin-bottom: 8px; } }
  .meijyu-body .meijyu-module .pt-px-2 {
    padding-top: 16px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-2 {
        padding-top: 12px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-2 {
        padding-top: 8px; } }
  .meijyu-body .meijyu-module .pb-px-2 {
    padding-bottom: 16px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-2 {
        padding-bottom: 12px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-2 {
        padding-bottom: 8px; } }
  .meijyu-body .meijyu-module .mt-px-3 {
    margin-top: 24px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-3 {
        margin-top: 18px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-3 {
        margin-top: 12px; } }
  .meijyu-body .meijyu-module .mb-px-3 {
    margin-bottom: 24px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-3 {
        margin-bottom: 18px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-3 {
        margin-bottom: 12px; } }
  .meijyu-body .meijyu-module .pt-px-3 {
    padding-top: 24px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-3 {
        padding-top: 18px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-3 {
        padding-top: 12px; } }
  .meijyu-body .meijyu-module .pb-px-3 {
    padding-bottom: 24px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-3 {
        padding-bottom: 18px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-3 {
        padding-bottom: 12px; } }
  .meijyu-body .meijyu-module .mt-px-4 {
    margin-top: 32px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-4 {
        margin-top: 24px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-4 {
        margin-top: 16px; } }
  .meijyu-body .meijyu-module .mb-px-4 {
    margin-bottom: 32px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-4 {
        margin-bottom: 24px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-4 {
        margin-bottom: 16px; } }
  .meijyu-body .meijyu-module .pt-px-4 {
    padding-top: 32px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-4 {
        padding-top: 24px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-4 {
        padding-top: 16px; } }
  .meijyu-body .meijyu-module .pb-px-4 {
    padding-bottom: 32px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-4 {
        padding-bottom: 24px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-4 {
        padding-bottom: 16px; } }
  .meijyu-body .meijyu-module .mt-px-5 {
    margin-top: 40px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-5 {
        margin-top: 30px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-5 {
        margin-top: 20px; } }
  .meijyu-body .meijyu-module .mb-px-5 {
    margin-bottom: 40px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-5 {
        margin-bottom: 30px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-5 {
        margin-bottom: 20px; } }
  .meijyu-body .meijyu-module .pt-px-5 {
    padding-top: 40px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-5 {
        padding-top: 30px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-5 {
        padding-top: 20px; } }
  .meijyu-body .meijyu-module .pb-px-5 {
    padding-bottom: 40px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-5 {
        padding-bottom: 30px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-5 {
        padding-bottom: 20px; } }
  .meijyu-body .meijyu-module .mt-px-6 {
    margin-top: 48px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-6 {
        margin-top: 36px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-6 {
        margin-top: 24px; } }
  .meijyu-body .meijyu-module .mb-px-6 {
    margin-bottom: 48px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-6 {
        margin-bottom: 36px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-6 {
        margin-bottom: 24px; } }
  .meijyu-body .meijyu-module .pt-px-6 {
    padding-top: 48px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-6 {
        padding-top: 36px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-6 {
        padding-top: 24px; } }
  .meijyu-body .meijyu-module .pb-px-6 {
    padding-bottom: 48px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-6 {
        padding-bottom: 36px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-6 {
        padding-bottom: 24px; } }
  .meijyu-body .meijyu-module .mt-px-7 {
    margin-top: 56px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-7 {
        margin-top: 42px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-7 {
        margin-top: 28px; } }
  .meijyu-body .meijyu-module .mb-px-7 {
    margin-bottom: 56px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-7 {
        margin-bottom: 42px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-7 {
        margin-bottom: 28px; } }
  .meijyu-body .meijyu-module .pt-px-7 {
    padding-top: 56px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-7 {
        padding-top: 42px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-7 {
        padding-top: 28px; } }
  .meijyu-body .meijyu-module .pb-px-7 {
    padding-bottom: 56px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-7 {
        padding-bottom: 42px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-7 {
        padding-bottom: 28px; } }
  .meijyu-body .meijyu-module .mt-px-8 {
    margin-top: 64px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-8 {
        margin-top: 48px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-8 {
        margin-top: 32px; } }
  .meijyu-body .meijyu-module .mb-px-8 {
    margin-bottom: 64px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-8 {
        margin-bottom: 48px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-8 {
        margin-bottom: 32px; } }
  .meijyu-body .meijyu-module .pt-px-8 {
    padding-top: 64px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-8 {
        padding-top: 48px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-8 {
        padding-top: 32px; } }
  .meijyu-body .meijyu-module .pb-px-8 {
    padding-bottom: 64px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-8 {
        padding-bottom: 48px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-8 {
        padding-bottom: 32px; } }
  .meijyu-body .meijyu-module .mt-px-9 {
    margin-top: 72px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-9 {
        margin-top: 54px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-9 {
        margin-top: 36px; } }
  .meijyu-body .meijyu-module .mb-px-9 {
    margin-bottom: 72px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-9 {
        margin-bottom: 54px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-9 {
        margin-bottom: 36px; } }
  .meijyu-body .meijyu-module .pt-px-9 {
    padding-top: 72px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-9 {
        padding-top: 54px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-9 {
        padding-top: 36px; } }
  .meijyu-body .meijyu-module .pb-px-9 {
    padding-bottom: 72px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-9 {
        padding-bottom: 54px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-9 {
        padding-bottom: 36px; } }
  .meijyu-body .meijyu-module .mt-px-10 {
    margin-top: 80px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-10 {
        margin-top: 60px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-10 {
        margin-top: 40px; } }
  .meijyu-body .meijyu-module .mb-px-10 {
    margin-bottom: 80px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-10 {
        margin-bottom: 60px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-10 {
        margin-bottom: 40px; } }
  .meijyu-body .meijyu-module .pt-px-10 {
    padding-top: 80px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-10 {
        padding-top: 60px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-10 {
        padding-top: 40px; } }
  .meijyu-body .meijyu-module .pb-px-10 {
    padding-bottom: 80px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-10 {
        padding-bottom: 60px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-10 {
        padding-bottom: 40px; } }
  .meijyu-body .meijyu-module .mt-px-11 {
    margin-top: 88px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mt-px-11 {
        margin-top: 66px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mt-px-11 {
        margin-top: 44px; } }
  .meijyu-body .meijyu-module .mb-px-11 {
    margin-bottom: 88px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .mb-px-11 {
        margin-bottom: 66px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .mb-px-11 {
        margin-bottom: 44px; } }
  .meijyu-body .meijyu-module .pt-px-11 {
    padding-top: 88px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pt-px-11 {
        padding-top: 66px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pt-px-11 {
        padding-top: 44px; } }
  .meijyu-body .meijyu-module .pb-px-11 {
    padding-bottom: 88px; }
    @media screen and (max-width: 1119px) {
      .meijyu-body .meijyu-module .pb-px-11 {
        padding-bottom: 66px; } }
    @media screen and (max-width: 768px) {
      .meijyu-body .meijyu-module .pb-px-11 {
        padding-bottom: 44px; } }

.meijyu-article {
  position: relative;
  top: 80px;
  left: 0; }
  @media screen and (max-width: 1119px) {
    .meijyu-article {
      top: 64px; } }
  @media screen and (max-width: 768px) {
    .meijyu-article {
      top: 0; } }
  .meijyu-article .first {
    position: relative; }
    .meijyu-article .first:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 24px 24px 0 24px;
      border-color: #ffffff transparent transparent transparent;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%) translateY(100%); }
    .meijyu-article .first .clums {
      position: relative; }
      .meijyu-article .first .clums:before {
        content: '';
        width: 40%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 60%;
        background: #f1f4f9; }
        @media screen and (max-width: 1119px) {
          .meijyu-article .first .clums:before {
            left: 50%;
            width: 50%; } }
        @media screen and (max-width: 768px) {
          .meijyu-article .first .clums:before {
            display: none; } }
      .meijyu-article .first .clums__pic {
        width: 60%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
        @media screen and (max-width: 1119px) {
          .meijyu-article .first .clums__pic {
            width: 50%; } }
        @media screen and (max-width: 768px) {
          .meijyu-article .first .clums__pic {
            width: auto;
            height: calc(100vw / 16 * 8);
            position: relative; } }
        .meijyu-article .first .clums__pic img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: right center; }
          @media screen and (max-width: 1119px) {
            .meijyu-article .first .clums__pic img {
              object-position: center; } }
      .meijyu-article .first .clums__data {
        width: 40%;
        margin: 0 0 0 50%;
        padding: 8vh 40px;
        padding-left: 0;
        position: relative;
        z-index: 2; }
        @media screen and (max-width: 1119px) {
          .meijyu-article .first .clums__data {
            width: 50%; } }
        @media screen and (max-width: 768px) {
          .meijyu-article .first .clums__data {
            width: auto;
            margin: 32px 20px 0;
            padding: 0; } }
        .meijyu-article .first .clums__data__box {
          padding: 40px;
          max-width: 620px;
          min-width: 520px;
          background: #FFF;
          border-radius: 3px;
          box-shadow: 0 0 32px rgba(0, 0, 0, 0.04); }
          @media screen and (max-width: 1119px) {
            .meijyu-article .first .clums__data__box {
              max-width: 100%;
              min-width: 1px; } }
          @media screen and (max-width: 768px) {
            .meijyu-article .first .clums__data__box {
              box-shadow: none;
              padding: 0 0 32px; } }
          .meijyu-article .first .clums__data__box dl {
            line-height: 1.3; }
            @media screen and (max-width: 768px) {
              .meijyu-article .first .clums__data__box dl {
                text-align: center; } }
            .meijyu-article .first .clums__data__box dl dt {
              font-size: 28px;
              font-weight: 700;
              margin: 0 0 2px; }
              @media screen and (max-width: 1119px) {
                .meijyu-article .first .clums__data__box dl dt {
                  font-size: 20px;
                  margin: 0 0 4px; } }
              .meijyu-article .first .clums__data__box dl dt .plus {
                display: inline-block;
                margin: 0 8px;
                width: 24px;
                height: 24px;
                position: relative;
                vertical-align: middle;
                transform: translateY(-4px); }
                @media screen and (max-width: 1119px) {
                  .meijyu-article .first .clums__data__box dl dt .plus {
                    width: 16px;
                    height: 16px;
                    transform: translateY(-2px); } }
                .meijyu-article .first .clums__data__box dl dt .plus:before, .meijyu-article .first .clums__data__box dl dt .plus:after {
                  content: '';
                  width: 100%;
                  height: 6px;
                  background: #00b898;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%); }
                  @media screen and (max-width: 1119px) {
                    .meijyu-article .first .clums__data__box dl dt .plus:before, .meijyu-article .first .clums__data__box dl dt .plus:after {
                      height: 4px; } }
                .meijyu-article .first .clums__data__box dl dt .plus:after {
                  transform: translate(-50%, -50%) rotate(90deg); }
            .meijyu-article .first .clums__data__box dl dd {
              color: #00b898;
              font-size: 56px;
              font-weight: 700; }
              @media screen and (max-width: 1119px) {
                .meijyu-article .first .clums__data__box dl dd {
                  font-size: 32px; } }
          .meijyu-article .first .clums__data__box p {
            margin: 16px 0 0;
            font-size: 15.5px;
            text-align: justify; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .first .clums__data__box p {
                font-size: 14px; } }
          .meijyu-article .first .clums__data__box ul {
            margin: 24px 0 0;
            background: #f1f4f9;
            padding: 20px;
            display: -webkit-flex;
            display: -moz-flex;
            display: -o-flex;
            display: -ms-flex;
            display: flex;
            -moz-flex-flow: row wrap;
            -webkit-flex-flow: row wrap;
            -o-flex-flow: row wrap;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;
            -webkit-justify-content: space-between;
            -o-justify-content: space-between;
            -ms-justify-content: space-between;
            -moz-justify-content: space-between;
            justify-content: space-between; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .first .clums__data__box ul {
                padding: 15px;
                font-size: 12px; } }
            .meijyu-article .first .clums__data__box ul li {
              width: 50%; }
              .meijyu-article .first .clums__data__box ul li .in {
                display: inline-block;
                margin: 0 8px 0 0;
                color: #00b898;
                font-weight: 700; }
  .meijyu-article .second .box {
    border: #FFF 1px dashed;
    color: #424746;
    border-radius: 8px;
    padding: 10px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .meijyu-article .second .box {
        padding: 5px; } }
    .meijyu-article .second .box__inner {
      background: #FFF;
      border-radius: 6px;
      overflow: hidden;
      padding: 24px;
      box-shadow: 0 0 20px rgba(28, 42, 64, 0.12); }
      @media screen and (max-width: 1119px) {
        .meijyu-article .second .box__inner {
          padding: 15px; } }
    .meijyu-article .second .box h4 {
      font-size: 36px;
      font-weight: 700;
      position: relative;
      margin: 8px 0 16px; }
      @media screen and (max-width: 1119px) {
        .meijyu-article .second .box h4 {
          font-size: 24px;
          margin: 4px 0 8px; } }
      .meijyu-article .second .box h4:before {
        content: '';
        width: 10px;
        height: 10px;
        background: #fcde15;
        position: absolute;
        top: 18px;
        left: 24px;
        transform: rotate(45deg);
        z-index: 3; }
        @media screen and (max-width: 1119px) {
          .meijyu-article .second .box h4:before {
            width: 8px;
            height: 8px;
            top: 15px;
            left: 16px; } }
      .meijyu-article .second .box h4 i {
        display: inline-block;
        margin: 0 14px 0 0;
        font-size: 32px;
        color: #00b898;
        transform: translateY(-1px); }
        @media screen and (max-width: 1119px) {
          .meijyu-article .second .box h4 i {
            font-size: 24px; } }
    .meijyu-article .second .box .clum {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      @media screen and (max-width: 768px) {
        .meijyu-article .second .box .clum {
          display: block; } }
      .meijyu-article .second .box .clum__pic {
        width: 400px;
        border-radius: 5px;
        overflow: hidden; }
        @media screen and (max-width: 1119px) {
          .meijyu-article .second .box .clum__pic {
            width: 280px; } }
        @media screen and (max-width: 768px) {
          .meijyu-article .second .box .clum__pic {
            width: 100%; } }
        .meijyu-article .second .box .clum__pic img {
          width: 100%; }
      .meijyu-article .second .box .clum__data {
        width: calc(100% - 400px);
        padding: 0 0 0 32px; }
        @media screen and (max-width: 1119px) {
          .meijyu-article .second .box .clum__data {
            width: calc(100% - 280px); } }
        @media screen and (max-width: 768px) {
          .meijyu-article .second .box .clum__data {
            width: auto;
            padding: 15px 0 0; } }
        .meijyu-article .second .box .clum__data__description {
          background: #eff5f4;
          padding: 18px;
          border-radius: 4px;
          font-size: 15px;
          line-height: 1.66; }
          .meijyu-article .second .box .clum__data__description a {
            color: #00b898;
            font-weight: 700;
            text-decoration: underline;
            cursor: pointer; }
          @media screen and (max-width: 1119px) {
            .meijyu-article .second .box .clum__data__description {
              padding: 15px;
              font-size: 14px; } }
          @media screen and (max-width: 768px) {
            .meijyu-article .second .box .clum__data__description {
              font-size: 13px; } }
          .meijyu-article .second .box .clum__data__description strong {
            color: #f76c82;
            font-weight: 700; }
    .meijyu-article .second .box .kugiri {
      padding: 20px 0 0;
      margin: 20px 0 0;
      border-top: #e8e8ea 1px solid; }
      @media screen and (max-width: 1119px) {
        .meijyu-article .second .box .kugiri {
          margin: 15px 0 0;
          padding: 15px 0 0; } }
    .meijyu-article .second .box .catch {
      width: 100%;
      text-align: center; }
      .meijyu-article .second .box .catch img {
        max-width: 100%; }
    .meijyu-article .second .box .flex {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between; }
      @media screen and (max-width: 768px) {
        .meijyu-article .second .box .flex {
          display: block; } }
      .meijyu-article .second .box .flex__harf {
        width: calc(50% - 15px); }
        @media screen and (max-width: 1119px) {
          .meijyu-article .second .box .flex__harf {
            width: calc(50% - 7.5px); } }
        @media screen and (max-width: 768px) {
          .meijyu-article .second .box .flex__harf {
            width: auto; } }
    .meijyu-article .second .box h5 {
      background: #00b898;
      color: #FFF;
      text-align: center;
      font-size: 22px;
      font-weight: 700;
      padding: 3px 15px; }
      @media screen and (max-width: 1119px) {
        .meijyu-article .second .box h5 {
          font-size: 18px; } }
    .meijyu-article .second .box .dataset dt {
      background: #00b898;
      color: #FFF;
      text-align: center;
      font-size: 22px;
      font-weight: 700;
      padding: 3px 15px; }
      @media screen and (max-width: 1119px) {
        .meijyu-article .second .box .dataset dt {
          font-size: 18px; } }
    .meijyu-article .second .box .dataset dd {
      line-height: 1.66;
      font-size: 15px; }
      @media screen and (max-width: 1119px) {
        .meijyu-article .second .box .dataset dd {
          font-size: 14px; } }
      .meijyu-article .second .box .dataset dd ol {
        list-style: none; }
        .meijyu-article .second .box .dataset dd ol li {
          position: relative;
          padding: 0 0 0 32px;
          font-size: 17px;
          margin: 0 0 8px; }
          @media screen and (max-width: 1119px) {
            .meijyu-article .second .box .dataset dd ol li {
              font-size: 15px; } }
          .meijyu-article .second .box .dataset dd ol li:nth-child(1):before {
            content: "1";
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #f76c82;
            color: #FFF;
            position: absolute;
            top: 4px;
            left: 0;
            font-size: 14px;
            border-radius: 50%; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .second .box .dataset dd ol li:nth-child(1):before {
                width: 20px;
                height: 20px;
                line-height: 20px; } }
          .meijyu-article .second .box .dataset dd ol li:nth-child(2):before {
            content: "2";
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #f76c82;
            color: #FFF;
            position: absolute;
            top: 4px;
            left: 0;
            font-size: 14px;
            border-radius: 50%; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .second .box .dataset dd ol li:nth-child(2):before {
                width: 20px;
                height: 20px;
                line-height: 20px; } }
          .meijyu-article .second .box .dataset dd ol li:nth-child(3):before {
            content: "3";
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #f76c82;
            color: #FFF;
            position: absolute;
            top: 4px;
            left: 0;
            font-size: 14px;
            border-radius: 50%; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .second .box .dataset dd ol li:nth-child(3):before {
                width: 20px;
                height: 20px;
                line-height: 20px; } }
          .meijyu-article .second .box .dataset dd ol li:nth-child(4):before {
            content: "4";
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #f76c82;
            color: #FFF;
            position: absolute;
            top: 4px;
            left: 0;
            font-size: 14px;
            border-radius: 50%; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .second .box .dataset dd ol li:nth-child(4):before {
                width: 20px;
                height: 20px;
                line-height: 20px; } }
          .meijyu-article .second .box .dataset dd ol li:nth-child(5):before {
            content: "5";
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #f76c82;
            color: #FFF;
            position: absolute;
            top: 4px;
            left: 0;
            font-size: 14px;
            border-radius: 50%; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .second .box .dataset dd ol li:nth-child(5):before {
                width: 20px;
                height: 20px;
                line-height: 20px; } }
          .meijyu-article .second .box .dataset dd ol li:nth-child(6):before {
            content: "6";
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #f76c82;
            color: #FFF;
            position: absolute;
            top: 4px;
            left: 0;
            font-size: 14px;
            border-radius: 50%; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .second .box .dataset dd ol li:nth-child(6):before {
                width: 20px;
                height: 20px;
                line-height: 20px; } }
          .meijyu-article .second .box .dataset dd ol li:nth-child(7):before {
            content: "7";
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #f76c82;
            color: #FFF;
            position: absolute;
            top: 4px;
            left: 0;
            font-size: 14px;
            border-radius: 50%; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .second .box .dataset dd ol li:nth-child(7):before {
                width: 20px;
                height: 20px;
                line-height: 20px; } }
          .meijyu-article .second .box .dataset dd ol li:nth-child(8):before {
            content: "8";
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #f76c82;
            color: #FFF;
            position: absolute;
            top: 4px;
            left: 0;
            font-size: 14px;
            border-radius: 50%; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .second .box .dataset dd ol li:nth-child(8):before {
                width: 20px;
                height: 20px;
                line-height: 20px; } }
          .meijyu-article .second .box .dataset dd ol li:nth-child(9):before {
            content: "9";
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #f76c82;
            color: #FFF;
            position: absolute;
            top: 4px;
            left: 0;
            font-size: 14px;
            border-radius: 50%; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .second .box .dataset dd ol li:nth-child(9):before {
                width: 20px;
                height: 20px;
                line-height: 20px; } }
          .meijyu-article .second .box .dataset dd ol li:nth-child(10):before {
            content: "10";
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #f76c82;
            color: #FFF;
            position: absolute;
            top: 4px;
            left: 0;
            font-size: 14px;
            border-radius: 50%; }
            @media screen and (max-width: 1119px) {
              .meijyu-article .second .box .dataset dd ol li:nth-child(10):before {
                width: 20px;
                height: 20px;
                line-height: 20px; } }
    .meijyu-article .second .box .dataflow dl {
      border: #e2edeb 4px solid;
      padding: 15px 20px;
      margin: 0 0 32px;
      position: relative; }
      .meijyu-article .second .box .dataflow dl:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 24px 32px 0 32px;
        border-color: #e2edeb transparent transparent transparent;
        position: absolute;
        left: 50%;
        bottom: -24px;
        transform: translateX(-50%); }
      .meijyu-article .second .box .dataflow dl:last-of-type {
        margin: 0; }
        .meijyu-article .second .box .dataflow dl:last-of-type:after {
          display: none; }
      .meijyu-article .second .box .dataflow dl dt {
        font-size: 17px;
        font-weight: 700;
        color: #f76c82; }
      .meijyu-article .second .box .dataflow dl dd {
        font-size: 15px;
        line-height: 1.66; }
        .meijyu-article .second .box .dataflow dl dd strong {
          color: #00b898;
          font-size: 20px;
          display: block;
          line-height: 1; }

.meijyu-contact .views {
  position: fixed;
  bottom: 24px;
  right: 24px;
  background: #FFF;
  box-shadow: 0 0 32px rgba(66, 71, 70, 0.2);
  width: 240px;
  z-index: 1000;
  border-radius: 6px; }
  @media screen and (max-width: 1119px) {
    .meijyu-contact .views {
      bottom: 15px;
      right: 15px;
      width: 200px; } }
  @media screen and (max-width: 768px) {
    .meijyu-contact .views {
      width: 160px; } }
  .meijyu-contact .views:after {
    content: '';
    width: 55px;
    height: 55px;
    background: url("../../../../images/meijyu/contact_bnr_p.png") no-repeat;
    background-size: 55px;
    position: absolute;
    top: -12px;
    left: 32px;
    z-index: 2; }
    @media screen and (max-width: 1119px) {
      .meijyu-contact .views:after {
        transform: scale(0.64);
        top: -24px;
        left: 24px; } }
    @media screen and (max-width: 768px) {
      .meijyu-contact .views:after {
        left: 16px; } }

.meijyu-contact .close {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #FFF;
  position: absolute;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  top: -8px;
  right: -8px;
  cursor: pointer; }
  .meijyu-contact .close i {
    opacity: .32; }

.meijyu-contact figure {
  border-radius: 6px 6px 0 0;
  overflow: hidden; }
  .meijyu-contact figure img {
    width: 100%; }

.meijyu-contact a {
  color: #424746;
  display: block; }
  .meijyu-contact a:hover img {
    opacity: 1; }

.meijyu-contact dl {
  padding: 15px 15px 12px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0em; }
  .meijyu-contact dl dt {
    font-size: 13px;
    margin: 0 0 6px; }
    @media screen and (max-width: 1119px) {
      .meijyu-contact dl dt {
        font-size: 11px; } }
    @media screen and (max-width: 768px) {
      .meijyu-contact dl dt {
        font-size: 10px; } }
  .meijyu-contact dl dd {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-weight: 700;
    color: #424746;
    font-size: 20px; }
    @media screen and (max-width: 1119px) {
      .meijyu-contact dl dd {
        font-size: 16px; } }
    @media screen and (max-width: 768px) {
      .meijyu-contact dl dd {
        font-size: 13px; } }
    .meijyu-contact dl dd > i {
      transform: rotateY(180deg) translateY(-1px);
      color: #00b898;
      display: inline-block;
      margin: 0 6px 0 0; }

.meijyu-pricebox {
  border: #00b898 3px solid;
  border-radius: 6px;
  position: relative;
  padding: 40px;
  background: #FFF; }
  @media screen and (max-width: 1119px) {
    .meijyu-pricebox {
      padding: 24px; } }
  @media screen and (max-width: 768px) {
    .meijyu-pricebox {
      padding: 15px; } }
  .meijyu-pricebox h3 {
    background: #FFF;
    font-size: 24px !important;
    color: #00b898;
    font-weight: 700;
    position: absolute;
    padding: 0 24px;
    letter-spacing: 0em;
    top: 0;
    left: 50%;
    line-height: 1;
    transform: translateX(-50%) translateY(-50%); }
    @media screen and (max-width: 1119px) {
      .meijyu-pricebox h3 {
        font-size: 20px !important; } }
  .meijyu-pricebox__alert {
    margin: 40px 0 0;
    padding: 16px 24px;
    font-size: 13.5px;
    background: #eff5f4; }
    @media screen and (max-width: 1119px) {
      .meijyu-pricebox__alert {
        margin: 20px 0 0;
        padding: 15px; } }
  .meijyu-pricebox__txt {
    text-align: center;
    font-size: 18px; }
    @media screen and (max-width: 1119px) {
      .meijyu-pricebox__txt {
        font-size: 16px; } }

.meijyu-photolist {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 0 0 0 -25px; }
  @media screen and (max-width: 1119px) {
    .meijyu-photolist {
      margin: 0 0 0 -15px; } }
  .meijyu-photolist li {
    width: 33.3%;
    padding: 0 0 0 25px;
    margin: 25px 0 0; }
    @media screen and (min-width: 1120px) {
      .meijyu-photolist li:nth-child(-n+3) {
        margin-top: 0; } }
    @media screen and (max-width: 1119px) {
      .meijyu-photolist li {
        width: 50%;
        padding: 0 0 0 15px;
        margin: 15px 0 0; }
        .meijyu-photolist li:nth-child(-n+2) {
          margin-top: 0; } }
    .meijyu-photolist li figure {
      background: #FFF;
      padding: 8px;
      box-shadow: 0 5px 20px rgba(66, 71, 70, 0.08); }
      .meijyu-photolist li figure img {
        width: 100%; }
      .meijyu-photolist li figure figcaption {
        text-align: center;
        margin: 8px 0 0; }

.meijyu-map iframe {
  width: 100%;
  vertical-align: bottom; }
  @media screen and (max-width: 768px) {
    .meijyu-map iframe {
      height: 180px; } }

.meijyu-news {
  position: relative;
  z-index: 2;
  background: #00b898;
  color: #FFF;
  padding: 80px 0; }
  @media screen and (max-width: 1119px) {
    .meijyu-news {
      padding: 56px 0; } }
  @media screen and (max-width: 768px) {
    .meijyu-news {
      padding: 32px 0; } }
  .meijyu-news .display.flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .meijyu-news .display.flex {
        display: block; } }
  .meijyu-news .newsbox {
    width: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .meijyu-news .newsbox {
        width: 100%;
        padding: 0;
        display: block; } }
  .meijyu-news .leftside {
    width: 280px;
    font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.04em; }
    @media screen and (max-width: 768px) {
      .meijyu-news .leftside {
        width: 100%; } }
    .meijyu-news .leftside h3 {
      line-height: 1;
      font-size: 14px;
      margin: 0 0 16px;
      text-align: left; }
      @media screen and (max-width: 768px) {
        .meijyu-news .leftside h3 {
          font-size: 12px; } }
      .meijyu-news .leftside h3:before {
        content: attr(data-eng);
        font-size: 36px;
        display: inline-block;
        margin: 0 24px 0 0; }
        @media screen and (max-width: 768px) {
          .meijyu-news .leftside h3:before {
            font-size: 20px; } }
    .meijyu-news .leftside a {
      display: inline-block;
      color: #FFF;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        .meijyu-news .leftside a {
          position: absolute;
          top: 0;
          right: 16px;
          font-size: 13px; } }
      .meijyu-news .leftside a:hover {
        text-decoration: underline; }
      .meijyu-news .leftside a i {
        display: inline-block;
        font-size: 13px;
        margin: 0 0 0 12px; }
  .meijyu-news ol {
    width: calc(100% - 280px);
    height: 130px;
    padding: 0 40px 0 0;
    overflow: auto; }
    @media screen and (max-width: 1119px) {
      .meijyu-news ol {
        height: 150px; } }
    @media screen and (max-width: 768px) {
      .meijyu-news ol {
        width: 100%;
        height: 164px;
        padding: 15px;
        border: rgba(255, 255, 255, 0.2) 1px solid;
        border-radius: 4px; } }
    .meijyu-news ol li {
      border-bottom: rgba(255, 255, 255, 0.06) 1px solid;
      padding: 0 0 24px;
      margin: 0 0 24px; }
      @media screen and (max-width: 1119px) {
        .meijyu-news ol li {
          margin: 0 0 15px;
          padding: 0 0 15px; } }
      .meijyu-news ol li:last-child {
        margin: 0; }
      .meijyu-news ol li a {
        display: -webkit-flex;
        display: -moz-flex;
        display: -o-flex;
        display: -ms-flex;
        display: flex;
        -moz-flex-flow: row wrap;
        -webkit-flex-flow: row wrap;
        -o-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-justify-content: space-between;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        color: #FFF; }
        @media screen and (max-width: 1119px) {
          .meijyu-news ol li a {
            display: block; } }
        .meijyu-news ol li a:hover h4 {
          text-decoration: underline; }
      .meijyu-news ol li span.time {
        width: 14%;
        font-size: 12px;
        background: #007b65;
        color: #FFF;
        padding: 2px 0;
        text-align: center; }
        @media screen and (max-width: 1119px) {
          .meijyu-news ol li span.time {
            width: 115px;
            display: inline-block;
            font-size: 12px;
            padding: 1px 12px;
            margin: 2px 0 0; } }
      .meijyu-news ol li h4 {
        width: 86%;
        font-size: 16px;
        padding: 0 0 0 32px; }
        @media screen and (max-width: 1119px) {
          .meijyu-news ol li h4 {
            width: auto;
            font-size: 14px;
            padding: 8px 0 0;
            clear: both; } }

.site-clum {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 0 auto; }
  @media screen and (max-width: 1119px) {
    .site-clum {
      width: auto;
      display: block; } }
  .site-clum__sidebar {
    width: 280px; }
    @media screen and (max-width: 1119px) {
      .site-clum__sidebar {
        width: auto; } }
    @media screen and (max-width: 768px) {
      .site-clum__sidebar {
        margin: 32px 0 0; } }
  .site-clum__container {
    width: calc(100% - 280px);
    padding: 0 48px 0 0; }
    @media screen and (max-width: 1119px) {
      .site-clum__container {
        width: auto;
        padding: 0; } }
    .site-clum__container .pager {
      margin: 24px 0 0;
      text-align: center; }
      .site-clum__container .pager a, .site-clum__container .pager span {
        width: 28px;
        height: 28px;
        line-height: 26px;
        text-align: center;
        display: inline-block;
        margin: 0 3px;
        border: #e8e8ea 1px solid;
        background: #e8e8ea;
        color: #1c2a40;
        font-size: 13px; }
      .site-clum__container .pager span {
        background: #cfdaea;
        border-color: #cfdaea;
        color: #FFF; }
      .site-clum__container .pager a:hover {
        color: #1c2a40;
        background: #FFF;
        border-color: #1c2a40; }

.site-module.wrap {
  padding: 96px 0; }
  @media screen and (max-width: 1119px) {
    .site-module.wrap {
      padding: 72px 0; } }
  @media screen and (max-width: 768px) {
    .site-module.wrap {
      padding: 48px 0; } }
  .site-module.wrap.border {
    border-bottom: #e8e8ea 1px solid; }
  .site-module.wrap.mid {
    padding: 72px 0; }
    @media screen and (max-width: 1119px) {
      .site-module.wrap.mid {
        padding: 56px 0; } }
    @media screen and (max-width: 768px) {
      .site-module.wrap.mid {
        padding: 32px 0; } }
  .site-module.wrap.no-top {
    padding-top: 0; }
  .site-module.wrap.no-bottom {
    padding-bottom: 0; }

.site-module h1.large,
.site-module h2.large,
.site-module h3.large,
.site-module h4.large {
  text-align: center;
  font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.04em;
  font-size: 30px;
  letter-spacing: 0em;
  font-weight: 400;
  line-height: 1;
  margin: 0 0 28px;
  background: url("../../../images/common/h_icon.png") no-repeat center top;
  background-size: 64px;
  padding: 50px 0 0; }
  @media screen and (max-width: 768px) {
    .site-module h1.large,
    .site-module h2.large,
    .site-module h3.large,
    .site-module h4.large {
      font-size: 20px;
      line-height: 1.56; } }
  @media screen and (max-width: 768px) {
    .site-module h1.large,
    .site-module h2.large,
    .site-module h3.large,
    .site-module h4.large {
      font-size: 18px;
      margin: 0 0 24px;
      padding: 42px 0 0; } }
  .site-module h1.large.no,
  .site-module h2.large.no,
  .site-module h3.large.no,
  .site-module h4.large.no {
    background: none;
    padding: 0; }
  .site-module h1.large:after,
  .site-module h2.large:after,
  .site-module h3.large:after,
  .site-module h4.large:after {
    content: attr(data-eng);
    font-size: 11px;
    display: block;
    margin: 14px 0 0;
    letter-spacing: .4em; }
    @media screen and (max-width: 768px) {
      .site-module h1.large:after,
      .site-module h2.large:after,
      .site-module h3.large:after,
      .site-module h4.large:after {
        font-size: 10px;
        margin: 6px 0 0; } }

.site-module .indent {
  text-indent: -.32em;
  display: inline-block; }

.site-module .btnarea {
  margin: 32px 0 0; }
  .site-module .btnarea.center {
    text-align: center; }
  .site-module .btnarea a {
    border: #1c2a40 1px solid;
    display: inline-block;
    min-width: 300px;
    color: #1c2a40;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 64px; }
    @media screen and (max-width: 768px) {
      .site-module .btnarea a {
        display: block;
        font-size: 15px;
        padding: 10px 16px; } }
    .site-module .btnarea a:hover {
      background: #1c2a40;
      color: #FFF; }
    .site-module .btnarea a.white {
      background: #1c2a40;
      color: #FFF;
      position: relative;
      z-index: 1; }
      .site-module .btnarea a.white:after {
        content: '';
        position: absolute;
        z-index: -2;
        width: 100%;
        height: 100%;
        border: #1c2a40 1px solid;
        top: 8px;
        left: 8px;
        -webkit-transition: all 240ms 0ms ease-in-out;
        -moz-transition: all 240ms 0ms ease-in-out;
        -ms-transition: all 240ms 0ms ease-in-out;
        -o-transition: all 240ms 0ms ease-in-out;
        transition: all 240ms 0ms ease-in-out; }
        @media screen and (max-width: 768px) {
          .site-module .btnarea a.white:after {
            display: none; } }
      .site-module .btnarea a.white:before {
        content: '';
        position: absolute;
        background: #FFF;
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        -webkit-transition: all 240ms 0ms ease-in-out;
        -moz-transition: all 240ms 0ms ease-in-out;
        -ms-transition: all 240ms 0ms ease-in-out;
        -o-transition: all 240ms 0ms ease-in-out;
        transition: all 240ms 0ms ease-in-out; }
      .site-module .btnarea a.white:hover {
        color: #1c2a40; }
        .site-module .btnarea a.white:hover:after {
          background: #1c2a40; }
        .site-module .btnarea a.white:hover:before {
          width: 100%; }
    .site-module .btnarea a i {
      display: inline-block;
      font-size: .9rem;
      margin: 0 0 0 8px;
      transform: translateY(-2px); }

.site-module table.tablestyle {
  width: 100%;
  border: #e8e8ea 1px solid;
  border-collapse: collapse; }
  @media screen and (max-width: 768px) {
    .site-module table.tablestyle {
      display: block; } }
  @media screen and (max-width: 768px) {
    .site-module table.tablestyle tbody {
      display: block; } }
  @media screen and (max-width: 768px) {
    .site-module table.tablestyle tbody tr {
      display: block;
      border-bottom: #e8e8ea 1px solid; }
      .site-module table.tablestyle tbody tr:last-child {
        border: none; } }
  .site-module table.tablestyle tbody tr th, .site-module table.tablestyle tbody tr td {
    padding: 15px;
    vertical-align: middle;
    text-align: left;
    border: #e8e8ea 1px solid; }
    @media screen and (max-width: 768px) {
      .site-module table.tablestyle tbody tr th, .site-module table.tablestyle tbody tr td {
        display: block;
        border: none; } }
  .site-module table.tablestyle tbody tr th {
    background: #f1f4f9;
    width: 200px; }
    @media screen and (max-width: 768px) {
      .site-module table.tablestyle tbody tr th {
        width: auto; } }
  .site-module table.tablestyle tbody tr td dl {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 15px; }
    .site-module table.tablestyle tbody tr td dl:last-of-type {
      margin: 0; }
    .site-module table.tablestyle tbody tr td dl dt {
      width: 120px;
      border: #1c2a40;
      padding: 3px 0;
      text-align: center;
      background: #f1f4f9; }
    .site-module table.tablestyle tbody tr td dl dd {
      width: calc(100% - 120px);
      margin: 2px 0 0;
      padding: 0 0 0 24px; }

.site-module table.formstyle {
  width: 100%;
  border-collapse: collapse; }
  @media screen and (max-width: 768px) {
    .site-module table.formstyle {
      display: block; } }
  @media screen and (max-width: 768px) {
    .site-module table.formstyle tbody {
      display: block; } }
  .site-module table.formstyle tbody tr {
    border-bottom: #e8e8ea 1px solid; }
    @media screen and (max-width: 768px) {
      .site-module table.formstyle tbody tr {
        display: block; }
        .site-module table.formstyle tbody tr:last-child {
          border: none; } }
    .site-module table.formstyle tbody tr th, .site-module table.formstyle tbody tr td {
      padding: 32px 0;
      text-align: left;
      vertical-align: middle;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        .site-module table.formstyle tbody tr th, .site-module table.formstyle tbody tr td {
          padding: 15px 0;
          display: block; } }
    .site-module table.formstyle tbody tr th {
      width: 240px; }
      @media screen and (max-width: 768px) {
        .site-module table.formstyle tbody tr th {
          width: auto; } }
      .site-module table.formstyle tbody tr th.hiss:after {
        content: '必須';
        font-size: 13px;
        padding: 2px 16px;
        border-radius: 3px;
        display: inline-block;
        background: #1c2a40;
        color: #FFF;
        float: right; }
    .site-module table.formstyle tbody tr td {
      padding-left: 40px; }
      @media screen and (max-width: 768px) {
        .site-module table.formstyle tbody tr td {
          padding-left: 0;
          padding-top: 0; } }
      .site-module table.formstyle tbody tr td input[type=text],
      .site-module table.formstyle tbody tr td input[type=tel],
      .site-module table.formstyle tbody tr td input[type=email],
      .site-module table.formstyle tbody tr td select,
      .site-module table.formstyle tbody tr td textarea {
        font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
        font-weight: 500;
        -webkit-font-feature-settings: "palt";
        font-feature-settings: "palt";
        letter-spacing: 0.024em;
        border: #e8e8ea 2px solid;
        padding: 5px 12px;
        border-radius: 3px;
        display: block;
        width: 100%;
        font-size: 15px; }
        .site-module table.formstyle tbody tr td input[type=text]:focus,
        .site-module table.formstyle tbody tr td input[type=tel]:focus,
        .site-module table.formstyle tbody tr td input[type=email]:focus,
        .site-module table.formstyle tbody tr td select:focus,
        .site-module table.formstyle tbody tr td textarea:focus {
          border-color: #1c2a40;
          box-shadow: 0 0 15px rgba(28, 42, 64, 0.08); }
      .site-module table.formstyle tbody tr td textarea {
        height: 150px; }

.site-module .formsend {
  margin: 32px 0 0;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .site-module .formsend {
      margin: 16px 0 0; } }
  .site-module .formsend input {
    display: inline-block;
    background: #FFF;
    width: 320px;
    border: #1c2a40 2px solid;
    color: #1c2a40;
    padding: 13px 0;
    border-radius: 4px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer; }
    @media screen and (max-width: 768px) {
      .site-module .formsend input {
        width: 100%;
        font-size: 17px; } }
    .site-module .formsend input:hover {
      background: #1c2a40;
      color: #FFF;
      box-shadow: 0 0 16px rgba(28, 42, 64, 0.12); }

.site-404 .context {
  text-align: center; }
  .site-404 .context dt {
    font-weight: 700; }
  .site-404 .context dd {
    font-size: 13px; }

.linkbnr {
  margin: 40px 0 0;
  text-align: center; }
  @media screen and (max-width: 768px) {
    .linkbnr {
      margin: 24px 0 0; } }
  .linkbnr.meijyu {
    margin: 0 0 40px; }
    @media screen and (max-width: 768px) {
      .linkbnr.meijyu {
        margin: 0 0 24px; } }
    .linkbnr.meijyu .linkbnr__link .linkbnr__link__img {
      width: 240px; }
  .linkbnr__link {
    display: block;
    border: #1c2a40 2px solid;
    border-radius: 4px;
    padding: 24px 40px; }
    @media screen and (max-width: 768px) {
      .linkbnr__link {
        padding: 15px; } }
    .linkbnr__link:hover {
      opacity: .8; }
    .linkbnr__link__img {
      width: 140px;
      margin: 0 auto; }
      .linkbnr__link__img.meijyu {
        width: 200px; }
      .linkbnr__link__img img {
        width: 100%; }
    .linkbnr__link__ttl {
      background: #1c2a40;
      color: #FFF;
      border-radius: 4px;
      margin: 12px 0 0;
      font-size: 14px;
      padding: 8px 24px; }
      @media screen and (max-width: 768px) {
        .linkbnr__link__ttl {
          padding: 8px 15px;
          font-size: 12px; } }

#intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #FFF;
  transition: opacity 800ms;
  visibility: hidden; }
  #intro.visible {
    background: #1c2a40;
    visibility: visible; }
  #intro.active {
    opacity: 0; }
  #intro #svglogo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 440px;
    height: auto;
    transform: translateX(-50%) translateY(-50%) scale(0);
    animation: scales 600ms 800ms forwards; }
    @media screen and (max-width: 768px) {
      #intro #svglogo {
        width: 200px; } }

@keyframes scales {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(0.8); }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(1); } }
    #intro #svglogo .st0 {
      stroke: #FFF;
      fill: #FFF;
      fill-opacity: 0;
      stroke-width: 1;
      stroke-dasharray: 3000;
      stroke-dashoffset: 3000;
      animation: lineanime 4s ease-in 800ms forwards; }

@keyframes lineanime {
  0% {
    stroke-dashoffset: 3000;
    fill-opacity: 0; }
  30% {
    fill-opacity: 0; }
  35% {
    fill-opacity: 1; }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1; } }

.scroll-anime {
  opacity: 0;
  transform: translateY(48px);
  -webkit-transition: all 800ms 0ms ease-in-out;
  -moz-transition: all 800ms 0ms ease-in-out;
  -ms-transition: all 800ms 0ms ease-in-out;
  -o-transition: all 800ms 0ms ease-in-out;
  transition: all 800ms 0ms ease-in-out; }
  .scroll-anime._active {
    opacity: 1;
    transform: translateY(0); }

.scroll-img {
  position: relative; }
  .scroll-img:before, .scroll-img:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .scroll-img:before {
    background: #FFF;
    -webkit-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 600ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img:after {
    background: #f1f4f9;
    -webkit-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -ms-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 600ms 0ms cubic-bezier(0.86, 0, 0.07, 1); }
  .scroll-img.__active:before {
    width: 0; }
  .scroll-img.__active:after {
    width: 0; }

.scale-enter-active, .scale-leave-active {
  transition: 400ms; }

.scale-enter, .scale-leave-to {
  opacity: 0; }
