.site-faq{
	.context{
		text-align: center;
        @media screen and (max-width:$responsive){
            margin: 0 15px;
        }
		a{
			color: $normal;
			text-decoration: underline;
		}
	}
	.question{
		margin: 40px 0 0;
		li{
			margin: 0 0 20px;
			&:last-child{
				margin: 0;
			}
			dl{
				border: $border 2px solid;
				background: #FFF;
				cursor: pointer;
				border-radius: 4px;
				box-shadow: 0 0 15px rgba($normal,.08);
				dt,dd{
					padding: 15px 20px;
					font-size: 15px;
                    @media screen and (max-width:$responsive){
                        padding: 15px;
                        font-size: 13px;
                    }
				}
				dt{
					font-size: 16px;
					font-weight: 700;
					position: relative;
					padding-left: 44px;
					padding-right: 44px;
					transition: background 200ms , box-shadow 200ms;
                    @media screen and (max-width:$responsive){
                        font-size: 14px;
                    }
					&:before{
						content: "\f059";
						font-family: "Font Awesome 5 Free";
						font-weight: 400;
						position: absolute;
						top: 13px;
						left: 20px;
						font-size: 17px;
                        @media screen and (max-width:$responsive){
                            left: 16px;
                        }
					}
					&:after{
						content: "\f078";
						font-weight: 900;
						font-family: "Font Awesome 5 Free";
						position: absolute;
						top: 18px;
						right: 20px;
						font-size: 12px;
						opacity: .4;
						@include transition(200ms);
					}
				}
				dd{
					border-top: $border 2px dashed;
					transition: border-color 200ms;
				}
				&.active{
					border: $normal 2px solid;
					box-shadow: 0 4px 15px rgba($normal,.16);
					dt{
						background: $glay;
						&:after{
							transform: rotate(-180deg);
						}
					}
					dd{
						border-color: #FFF;
					}
				}
			}
		}
	}
	.attentionlist{
		border: $glay 8px solid;
		margin: 32px 0 0;
        @media screen and (max-width:$responsive){
            margin: 24px 0 0;
        }
		li{
			padding: 24px;
			padding-left: 56px;
			border-bottom: $glay 2px solid;
			font-size: 15px;
			position: relative;
            @media screen and (max-width:$responsive){
                padding: 15px;
                padding-left: 48px;
                font-size: 13.5px;
            }
			&:before{
				content: "\f058";
				font-weight: 900;
				font-size: 18px;
				font-family: "Font Awesome 5 Free";
				position: absolute;
				left: 24px;
				top: 50%;
				color: darken($dark, 8%);
				transform: translateY(-50%);
                @media screen and (max-width:$responsive){
                    left: 16px;
                }
			}
		}
	}
}
