.site-index{
	.firstview{
		overflow: hidden;
		position: relative;
		#fvslide{
			position: relative;
			opacity: 0;
			transition: opacity 1200ms linear;
			&.swiper-container-initialized{
				opacity: 1;
				.swiper-wrapper{
					.swiper-slide{
						.bg{
							transform: scale(1);
						}
					}
				}
			}
			.swiper-wrapper{
				.swiper-slide{
					background: #FFF;
					width: 100%;
					height: 100vh;
					position: relative;
					overflow: hidden;
					@media screen and (min-width:$responsive + 1px){
						max-height: 100vh !important;
					}
					.bg{
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background-position: center;
						background-size: cover;
						transform: scale(1.1);
						transition: transform 4000ms linear;
                        @media screen and (max-width:$tab){
                            background-position: right center;
                            &.sp1{
                                background-image: url("../../../images/index/fv_01_sp.jpg") !important;
                            }
                            &.sp2{
                                background-image: url("../../../images/index/fv_02_sp.jpg") !important;
                            }
                            &.sp3{
                                background-image: url("../../../images/index/fv_03_sp.jpg") !important;
                            }
                        }
						img{
							display: none;
						}
					}
				}
			}
			.controll{
				position: absolute;
				right: 40px;
				top: 50%;
				transform: translateY(-50%);
				z-index: 100;
                @media screen and (max-width:$tab){
                    right: 20px;
                }
                @media screen and (max-width:$responsive){
                    right: 16px;
                }
				&__next,
				&__prev{
					$size: 80px;
					width: $size;
					height: $size;
					border: #FFF 1px solid;
					border-radius: 50%;
					cursor: pointer;
					@include transition(400ms, $ease: $ease);
					position: relative;
                    @media screen and (max-width:$tab){
                        $size: 64px;
    					width: $size;
    					height: $size;
                    }
                    @media screen and (max-width:$responsive){
                        $size: 48px;
    					width: $size;
    					height: $size;
                    }
					&:after{
						content: '';
						width: 22px;
						height: 9px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translateX(-50%) translateY(-50%);
						background-image: url("../../../images/index/control_prev.png");
						background-position: -0 0;
						background-repeat: no-repeat;
						background-size: 44px;
						@include transition(400ms, $ease: $ease);
                        @media screen and (max-width:$responsive){
                            width: 18px;
                            height: 7px;
                            background-size: 36px;
                        }
					}
					&:hover{
						background-color: $glay;
						border-color: rgba(#FFF,0);
						&:after{
							background-position: -22px 0;
                            @media screen and (max-width:$responsive){
                                background-position: -18px 0;
                            }
						}
					}
				}
				&__next{
					margin: 0 0 24px;
                    @media screen and (max-width:$responsive){
                        margin: 0 0 16px;
                    }
					&:after{
						background-image: url("../../../images/index/control_next.png");
						background-position: -22px 0;
                        @media screen and (max-width:$responsive){
                            background-position: -18px 0;
                        }
					}
					&:hover{
						&:after{
							background-position: 0 0;
						}
					}
				}
			}
		}
		&__title{
			position: absolute;
			top: 52%;
			left: 9.4%;
			transform: translateY(-50%);
			z-index: 200;
			@include min();
			letter-spacing: .06em;
			font-weight: 100;
			@include fontsize(42px, 36);
			line-height: 1.44;
			opacity: 0;
			transition: opacity 1000ms linear;
            @media screen and (max-width:$tab){
                left: 6%;
                @include fontsize(40px, 32);
            }
            @media screen and (max-width:$responsive){
                @include fontsize(32px, 24);
            }
			&.fade{
				opacity: 1;
			}
			.indent{
				text-indent: -0.4em;
				display: inline-block;
			}
			.tume{
				text-indent: -0.12em;
				display: inline-block;
				&--harf{
					text-indent: -0.06em;
				}
			}
		}
		&__copy{
			position: absolute;
			left: 40px;
			bottom: 40px;
			z-index: 100;
			font-size: 9px;
            @media screen and (max-width:$tab){
                left: 20px;
                bottom: 20px;
            }
            @media screen and (max-width:$responsive){
                font-size: 8px;
                left: 16px;
                bottom: 16px;
            }
		}
        &__scroll{
            position: absolute;
			right: 80px;
			bottom: 0;
			z-index: 100;
			font-size: 11px;
            width: 120px;
            padding: 10px 0 0;
            color: #FFF;
            letter-spacing: .24em;
            transform-origin: bottom right;
            mix-blend-mode: exclusion;
            transform: rotate(90deg);
            @media screen and (max-width:$tab){
                right: 50px;
                width: 100px;
            }
            @media screen and (max-width:$responsive){
                font-size: 9px;
                right: 40px;
                width: 80px;
            }
            &:after{
                content: '';
                width: 100%;
                height: 1px;
                position: absolute;
                left: 0;
                top: 0;
                background: #FFF;
                animation: sc 2s $ease infinite;
                @keyframes sc {
                    0%{
                        width: 0;
                        left: 0;
                    }
                    50%{
                        width: 100%;
                        left: 0;
                    }
                    100%{
                        width: 0;
                        left: 100%;
                    }
                }
            }
        }
		&__dammy{
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 100%;
			background: #FFF;
			z-index: 200;
		}
		#fvtxt{
			display: none;
		}
    }


    .first{
		&.wrap{
			padding-top: 32px;
            @media screen and (max-width:$responsive){
                padding-top: 16px;
            }
		}
        .context{
            text-align: center;
			margin: 0 0 80px;
            @media screen and (max-width:$tab){
                margin: 0 0 64px;
            }
            @media screen and (max-width:$responsive){
                margin: 0 16px 32px;
            }
        }
		.clum{
			position: relative;
			background: $glay;
			padding: 96px 0;
			margin: 0;
			z-index: 3;
            @media screen and (max-width:$tab){
                padding: 72px 0;
            }
            @media screen and (max-width:$responsive){
                padding: 0 0 48px;
            }
			&:before{
				content: '';
				width: 1px;
				height: 80px;
				background: $dark;
				position: absolute;
				top: -40px;
				left: 50%;
				z-index: 3;
				transform: translateX(-50%);
                @media screen and (max-width:$responsive){
                    display: none;
                }
			}
			&__bg{
				position: absolute;
				top: 96px;
				right: 0;
				width: calc(50%);
				height: 100%;
				background-size: cover;
				background-position: center;
				overflow: hidden;
                @media screen and (max-width:$responsive){
                    position: relative;
                    top: 0;
                    left: 0;
                    width: auto;
                    height: 240px;
                }
				&__item{
					position: absolute;
					top: -5%;
					left: 0;
					width: 100%;
					height: 110%;
					background-size: cover;
					background-position: center;
                    @media screen and (max-width:$responsive){
                        top: -20%;
                        height: 140%;
                    }
				}
			}
			&__text{
				width: 50%;
				padding: 88px 60px 40px 0;
				position: relative;
                @media screen and (max-width:$tab){
                    padding: 72px 40px 24px 0;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    padding: 32px 0 0;
                }
				dl{
					dt{
						@include min();
						line-height: 1.66;
						font-size: 28px;
						margin: 0 0 22px;
						position: relative;
                        @media screen and (max-width:$tab){
                            font-size: 24px;
                        }
                        @media screen and (max-width:$responsive){
                            font-size: 18px;
                            margin: 0 0 16px;
                            text-align: center;
                        }
						&:before{
							content: '';
							width: 40px;
							height: 1px;
							background: $normal;
							top: -40px;
							left: 0;
							position: absolute;
                            @media screen and (max-width:$responsive){
                                display: none;
                            }
						}
					}
					dd{
						text-align: justify;
						font-size: 15px;
                        @media screen and (max-width:$responsive){
                            font-size: 13.5px;
                            margin: 0 15px;
                            &.btnarea{
                                margin: 24px 0 0;
                            }
                            br{
                                display: none;
                            }
                        }
					}
				}
			}
			&__eng{
				position: absolute;
				left: calc(1vw + 40px);
				bottom: -56px;
				@include min();
				@include fontsize(110px, 20);
				letter-spacing: 0em;
				line-height: 1;
				color: lighten($dark, 2%);
				z-index: 1;
                @media screen and (max-width:$responsive){
                    display: none;
                }
			}
		}
    }

	.second{
		position: relative;
        @media screen and (max-width:$responsive){
            padding-top: 0 !important;
        }
		&:before{
			content: '';
			width: 100%;
			height: 520px;
			background: $glay;
			position: absolute;
			left: 0;
			bottom: 0;
            @media screen and (max-width:$responsive){
                display: none;
            }
		}
		.display{
			width: auto;
			max-width: $width + 240px;
            @media screen and (max-width:$responsive){
                max-width: 100%;
            }
		}
		.context{
            text-align: center;
			margin: 0 0 32px;
            @media screen and (max-width:$tab){
                margin: 0 0 24px;
            }
            @media screen and (max-width:$responsive){
                margin: 0 16px 20px;
            }
        }
		.list{
			@include flex();
			padding: 0 0 12px;
            @media screen and (max-width:$responsive){
                padding: 0;
                display: block;
            }
			li{
				list-style: none;
				width: 31.5%;
				background: #FFF;
				box-shadow: 0 8px 16px rgba($normal,.04);
                @media screen and (max-width:$tab){
                    width: 32%;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 16px;
                    box-shadow: 0 8px 16px rgba($normal,.12);
                    &:last-child{
                        margin: 0;
                    }
                }
				figure{
					img{
						width: 100%;
					}
				}
				dl{
					padding: 24px 20px;
                    @media screen and (max-width:$responsive){
                        padding: 16px;
                    }
					dt{
						@include min();
						text-align: center;
						font-weight: 700;
						font-size: 16.5px;
						margin: 0 0 16px;
						padding: 0 0 16px;
						border-bottom: $border 1px dashed;
                        @media screen and (max-width:$tab){
                            font-size: 15px;
                        }
						&:before{
							content: attr(data-eng);
							font-size: 10px;
							letter-spacing: .24em;
							color: darken($glay, 32%);
							display: block;
						}
					}
					dd{
						text-align: justify;
						font-size: 14px;
                        @media screen and (max-width:$tab){
                            font-size: 13px;
                        }
					}
				}
			}
		}
	}

	.third{
		position: relative;
		background: url("../../../images/index/lip.jpg") no-repeat right center;
		background-size: cover;
		.lip{
			width: 800px;
			margin: 0 auto;
			position: relative;
			z-index: 2;
            @media screen and (max-width:$tab){
                width: auto;
            }
			img{
				width: 100%;
			}
		}
		.description{
			border-top: $border 1px solid;
			padding: 40px 0 0;
			margin: 32px 0 0;
			@include flex();
			align-items: center;
            @media screen and (max-width:$responsive){
                margin: 24px 0 0;
                padding: 0;
                display: block;
                border: none;
            }
			dt{
				width: 200px;
				@include min();
				line-height: 1;
				font-size: 26px;
				white-space: nowrap;
                @media screen and (max-width:$tab){
                    font-size: 24px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    text-align: center;
                    font-size: 20px;
                }
			}
			dd{
				width: calc(100% - 200px - 180px);
				padding: 0 0 0 40px;
				text-align: justify;
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 16px 0 0;
                    padding: 0 15px;
                }
				&.more{
					width: 180px;
                    @media screen and (max-width:$responsive){
                        width: auto;
                    }
					a{
						display: block;
						border: $normal 1px solid;
						color: $normal;
						@include min();
						font-size: 15px;
						text-align: center;
						padding: 12px 0;
						position: relative;
						z-index: 1;
						&:before{
							content: '';
							width: 0;
							height: 100%;
							background: $normal;
							position: absolute;
							top: 0;
							left: 0;
							z-index: -1;
							@include transition(140ms);
						}
						&:hover{
							color: #FFF;
							&:before{
								width: 100%;
							}
						}
					}
				}
			}
		}
	}

	.news{
        position: relative;
        z-index: 2;
		background: $normal;
		color: #FFF;
		padding: 80px 0;
        @media screen and (max-width:$tab){
            padding: 56px 0;
        }
        @media screen and (max-width:$responsive){
            padding: 32px 0;
        }
		.display{
			&.flex{
				@include flex();
				@media screen and (max-width:$responsive){
					display: block;
				}
			}
		}
		.newsbox{
			width: 100%;
			@include flex();
			@media screen and (max-width:$responsive){
				width: 100%;
				padding: 0;
				display: block;
			}
		}
        .leftside{
            width: 280px;
			@include min();
			@media screen and (max-width:$responsive){
				width: 100%;
			}
			h3{
				line-height: 1;
				font-size: 14px;
				margin: 0 0 16px;
				@media screen and (max-width:$responsive){
					font-size: 12px;
				}
				&:before{
					content: attr(data-eng);
					font-size: 36px;
					display: inline-block;
					margin: 0 24px 0 0;
					@media screen and (max-width:$responsive){
						font-size: 20px;
					}
				}
			}
			a{
				display: inline-block;
				color: #FFF;
				font-size: 15px;
				@media screen and (max-width:$responsive){
					position: absolute;
					top: 0;
					right: 16px;
					font-size: 13px;
				}
				&:hover{
					text-decoration: underline;
				}
				i{
					display: inline-block;
					font-size: 13px;
					margin: 0 0 0 12px;
				}
			}
        }
        ol{
            width: calc(100% - 280px);
            height: 130px;
            padding: 0 40px 0 0;
            overflow: auto;
            @media screen and (max-width:$tab){
                height: 150px;
            }
			@media screen and (max-width:$responsive){
				width: 100%;
				height: 164px;
				padding: 15px;
				border: rgba(#FFF,.2) 1px solid;
				border-radius: 4px;
			}
            li{
                border-bottom: rgba(#FFF,.06) 1px solid;
                padding: 0 0 24px;
                margin: 0 0 24px;
				@media screen and (max-width:$tab){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
                &:last-child{
                    margin: 0;
                }
                a{
                    @include flex();
                    align-items: center;
                    color: #FFF;
					@media screen and (max-width:$tab){
						display: block;
					}
                    &:hover{
                        h4{
                            text-decoration: underline;
                        }
                    }
                }
                span.time{
                    width: 14%;
                    font-size: 12px;
                    background: lighten($normal, 8%);
                    color: #FFF;
                    padding: 2px 0;
                    text-align: center;
					@media screen and (max-width:$tab){
						width: 115px;
                        display: inline-block;
						font-size: 12px;
						padding: 1px 12px;
						margin: 2px 0 0;
					}
                }
                h4{
                    width: 86%;
                    font-size: 16px;
                    padding: 0 0 0 32px;
					@media screen and (max-width:$tab){
						width: auto;
						font-size: 14px;
						padding: 8px 0 0;
						clear: both;
					}
                }
            }
        }
    }

	.sns{
		padding: 80px 0;
		background: $glay;
        @media screen and (max-width:$tab){
            padding: 56px 0;
        }
        @media screen and (max-width:$responsive){
            padding: 32px 0;
        }
		.socials{
			@include flex();
            @media screen and (max-width:$responsive){
                display: block;
            }
			li{
				width: 48.5%;
				font-size: 12px;
				iframe{
					width: 100%;
				}
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 16px;
                    &:last-child{
                        margin: 0;
                    }
                }
			}
		}
	}

}
