.meijyu-footer{
    position: relative;
    top: $m-head;
    left: 0;
    background: darken($m-green,8%);
    padding: 24px 0;
    text-align: center;
    color: #FFF;
	@media screen and (max-width:$tab){
		top: $m-sp-head;
	}
	@media screen and (max-width:$responsive){
		top: 0;
		font-size: 8px;
	}
}
