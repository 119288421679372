.site-agree{
	.context{
		text-align: center;
		margin: 0 0 32px;
        @media screen and (max-width:$responsive){
            margin: 0 15px 24px;
        }
	}
	
	.block{
		background: lighten($glay, 1%);
		padding: 24px 32px;
		@media screen and (max-width:$responsive){
			padding: 15px;	
		}
		&.mb{
			margin-bottom: 40px;
			@media screen and (max-width:$responsive){
				margin-bottom: 24px;	
			}
		}
		h3.borders{
			font-size: 20px;
			font-weight: 700;
			padding: 0 0 12px;
			margin: 0 0 15px;
			border-bottom: #FFF 4px solid;
			@media screen and (max-width:$responsive){
				font-size: 16px;	
			}
		}
		.btnarea{
			margin: 24px 0 0;
			text-align: center;
			@media screen and (max-width:$responsive){
				margin: 15px 0 0;
				a{
					display: block;
					padding: 10px 0;
				}
			}
		}
		.center{
			text-align: center;
			margin: 20px 0 0;
			@media screen and (max-width:$responsive){
				margin: 12px 0 0;	
			}
			a{
				font-size: 16px;
				color: $normal;
				text-decoration: underline;
				&:hover{
					opacity: .64;
				}
			}
		}
	}
}
