.asunaro-fv{
    position: relative;
    z-index: 2;
	overflow: hidden;
    #asunaro-swipe{
        max-width: 2560px;
        margin: 0 auto;
        overflow: visible;
        position: relative;
        &.swiper-container-initialized{
            h1{
                opacity: 1;
            }
        }
		.title{
            position: absolute;
            top: calc(50% - 20px);
            left: 8%;
            transform: translateY(-50%);
            z-index: 3;
			h1{
				@include min();
				font-weight: 300;
				letter-spacing: 0;
				@include fontsize(44px, 18);
				line-height: 1.4;
				@media screen and (max-width:$responsive){
					font-size: 24px;
				}
			}
			.eng{
				@include poppin();
				text-transform: uppercase;
				@include fontsize(12px, 4);
				color: $asunaro-yellow;
				text-indent: .2em;
				line-height: 1;
				margin: 20px 0 0;
				letter-spacing: .2em;
				@media screen and (max-width:$responsive){
					font-size: 10px;
					margin: 12px 0 0;
				}
			}
		}
		.copy{
			position: absolute;
			left: 40px;
			bottom: 40px;
			font-size: 10px;
			line-height: 1;
			z-index: 3;
			@media screen and (max-width:$responsive){
				display: none;
			}
		}		
        .swiper-slide{
            width: 100%;
			height: calc(100vh - #{$asunaro-head});
            min-height: 640px;
            overflow: hidden;
            @media screen and (max-width:$tab){
                min-height: 1px;
                height: 480px;
            }
            @media screen and (max-width:$responsive){
                height: 260px;	
            }
            .bg{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
				img{
					display: none;
				}
            }
        }
        .swiper-slide-active .bg,
        .swiper-slide-duplicate-active .bg,
        .swiper-slide-prev .bg{
            animation: zoomUp 8s linear 0s 1 normal both;  
        }
        @keyframes zoomUp {
            0% {
                transform: scale(1.08);
            }
            100% {
                transform: scale(1);
            }
        }
    }
}
