#addmap{
	position: relative;
	iframe{
		width: 100%;
		height: 340px;
		vertical-align: bottom;
		filter: grayscale(1);
        @media screen and (max-width:$tab){
            height: 240px;
        }
        @media screen and (max-width:$responsive){
            height: 180px;
        }
	}
}

#site-footer{
	padding: 0 0 80px;
	background: $normal;
	color: #FFF;
    @media screen and (max-width:$tab){
        padding: 0 0 56px;
    }
    @media screen and (max-width:$responsive){
        padding: 0 0 48px;
    }
	#pagetop{
		background: lighten($normal,4%);
		display: block;
		text-align: center;
		padding: 14px 0;
		margin: 0 0 80px;
		@include transition(120ms);
        @media screen and (max-width:$tab){
            margin: 0 0 56px;
        }
        @media screen and (max-width:$responsive){
            margin: 0 0 48px;
        }
		&:hover{
			background: lighten($normal,8%);
		}
	}
	a{
		color: #FFF;
	}
	.flex{
		@include flex();
		align-items: center;
		position: relative;
        @media screen and (max-width:$tab){
            align-items: flex-start;
        }
        @media screen and (max-width:$responsive){
            display: block;
        }
	}
	.company{
		width: 420px;
		@include flex();
		align-items: center;
        @media screen and (max-width:$tab){
            width: 320px;
        }
        @media screen and (max-width:$responsive){
            width: auto;
        }
		figure{
			width: 120px;
            @media screen and (max-width:$responsive){
                width: 80px;
            }
			img{
				width: 100%;
			}
		}
		dl{
			width: calc(100% - 120px);
			padding: 0 0 0 40px;
            @media screen and (max-width:$responsive){
                padding: 0 0 0 16px;
                width: calc(100% - 80px);
            }
			dt{
				margin: 0 0 10px;
				padding: 0 0 10px;
				border-bottom: rgba(#FFF,.12) 1px solid;
                @media screen and (max-width:$responsive){
                    margin: 0 0 6px;
                    padding: 0 0 6px;
                }
			}
			dd{
				font-size: 12px;
                @media screen and (max-width:$responsive){
                    font-size: 11px;
                }
			}
		}
	}
	.fnav{
		width: calc(100% - 420px);
		padding: 0 64px;
		@include flex();
        @media screen and (max-width:$tab){
            width: calc(100% - 320px);
            padding: 0 56px;
        }
        @media screen and (max-width:$responsive){
            width: auto;
            margin: 24px 0 0;
            padding: 0;
            display: block;
            border: rgba(#FFF,.16) 1px solid;
            border-radius: 4px;
        }
        li{
            width: 50%;
			margin: 6px 0 0;
            @media screen and (max-width:$tab){
                font-size: 13px;
            }
            @media screen and (max-width:$responsive){
                width: auto;
                border-bottom: rgba(#FFF,.16) 1px solid;
                margin: 0;
                &:last-child{
                    border: none;
                }
            }
			&:nth-child(-n+2){
				margin-top: 0;
			}
			a{
				display: inline-block;
                @media screen and (max-width:$responsive){
                    display: block;
                    padding: 10px 16px;
                }
				&:before{
					content: '+';
					display: inline-block;
					margin: 0 8px 0 0;
					opacity: .48;
				}
			}
        }
	}
	.sns{
		position: absolute;
		font-size: 13px;
		top: 0;
		right: 0;
        @media screen and (max-width:$responsive){
            position: relative;
            @include flex();
            align-items: center;
            justify-content: center;
            margin: 24px 0;
        }
		li{
			margin: 0 0 16px;
            @media screen and (max-width:$responsive){
                margin: 0 16px 0 0;
            }
			&:last-child{
				margin: 0;
			}
			a{
				border: rgba(#FFF,.16) 1px solid;
				width: 32px;
				height: 32px;
				line-height: 32px;
				text-align: center;
				border-radius: 50%;
				display: block;
				color: rgba(#FFF,.56);
				@include transition(200ms);
				&:hover{
					border-color: #FFF;
					color: #FFF;
					transform: scale(1.1);
				}
			}
		}
	}
	.copy{
		display: block;
		margin: 56px 0 0;
		@include min();
		font-size: 10px;
		text-align: center;
		opacity: .64;
        @media screen and (max-width:$responsive){
            margin: 24px 0 0;
        }
	}
}
