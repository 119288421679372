.site-contact{
	.localnav{
		background: $glay;
		padding: 20px;
		border-radius: 4px;
        @media screen and (max-width:$tab){
            padding: 15px;
        }
		&__list{
			@include flex();
            @media screen and (max-width:$responsive){
                display: block;
            }
			> li{
				width: calc(50% - 10px);
				background: #FFF;
				padding: 20px 40px;
				border-radius: 4px;
				box-shadow: 0 0 16px rgba($normal, .08);
                @media screen and (max-width:$tab){
                    padding: 15px;
                }
                @media screen and (max-width:$responsive){
                    width: auto;
                    margin: 0 0 16px;
                    &:last-child{
                        margin: 0;
                    }
                }
				dl{
					dt{
						text-align: center;
						font-size: 20px;
						font-weight: 700;
						line-height: 1;
						padding: 12px 0 0;
                        @media screen and (max-width:$tab){
                            font-size: 16px;
                        }
					}
					dd{
						margin: 16px 0 0;
						text-align: center;
						> a{
							display: block;
							background: #FFF;
							border: $normal 2px solid;
							color: $normal;
							padding: 10px 0;
							border-radius: 4px;
							font-size: 18px;
							font-weight: 700;
							&:hover{
								background: $normal;
								color: #FFF;
								box-shadow: 0 0 16px rgba($normal,.12);
							}
							> i{
								display: inline-block;
								margin: 0 8px 0 0;
								&.rvs{
									transform: rotateY(180deg);
								}
							}
						}
						&.min{
							margin: 10px 0 0;
                            @media screen and (max-width:$tab){
                                font-size: 12px;
                            }
							strong{
								display: inline-block;
								background: $normal;
								color: #FFF;
								margin: 0 8px 0 0;
								padding: 0 8px;
								font-size: 12px;
								transform: translateY(-2px);
								border-radius: 3px;
							}
						}
					}
				}
			}
		}
	}
}
