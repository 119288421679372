#secondview{
	$meijyu: #008f76;
	
    height: 400px;
    position: relative;
	background: #FFF;
    @media screen and (max-width:$tab){
        height: 240px;
    }
    @media screen and (max-width:$responsive){
        height: 200px;
    }
	&.meijyu-bread{
		background: $meijyu url("../../../images/meijyu/white_dotto.png") repeat;
		background-size: 16px;
		height: 300px;
		color: #FFF;
		h1{
			top: 50%;
		}
		@media screen and (max-width:$tab){
			height: 200px;
			margin: 64px 0 0;
		}
		@media screen and (max-width:$responsive){
			height: 150px;
			margin: 0;
			h1{
				top: 45%;
			}
		}
		a {
			color: #FFF !important;
		}
	}
    .bgset{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;
        &__bg{
            position: absolute;
            top: -20%;
            left: 0;
            width: 100%;
            height: 140%;
            background-size: cover;
            background-position: center;
			opacity: .88;
            @media screen and (max-width:$tab){
                top: -30%;
                height: 160%;
            }
            @media screen and (max-width:$responsive){
                top: -40%;
                height: 180%;
            }
        }
    }
    h1{
        position: absolute;
        left: 50%;
        top: 48%;
        transform: translateX(-50%);
        line-height: 1;
		text-align: center;
		@include min();
        font-size: 14px;
        z-index: 2;
        @media screen and (max-width:$tab){
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        @media screen and (max-width:$responsive){
            top: calc(50% + 8px);
            font-size: 12px;
        }
		&:before{
			content: attr(data-eng);
			font-size: 48px;
			display: block;
			margin: 0 0 14px;
            @media screen and (max-width:$tab){
                font-size: 36px;
            }
            @media screen and (max-width:$responsive){
                font-size: 28px;
                margin: 0 0 10px;
            }
		}
    }

    .bread{
        position: absolute;
        bottom: 24px;
        right: 40px;
        z-index: 3;
        @media screen and (max-width:$tab){
            bottom: 16px;
            right: 16px;
        }
        @media screen and (max-width:$responsive){
            right: auto;
            left: 16px;
            bottom: 13px;
        }
        ol{
            @include flex();
            align-items: center;
            justify-content: flex-start;

            li{
                font-size: 10px;
                letter-spacing: .12em;
                @media screen and (max-width:$responsive){
                    font-size: 9px;
                }
                a{
                    color: $normal;
                }
                &:after{
                    content: "\f054";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    display: inline-block;
                    margin: 0 12px;
                    font-size: 8px;
                    transform: translateY(-1px);
                }
                &:first-child{
                    i{
                        display: inline-block;
                        margin: 0 8px 0 0;
                    }
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
}
