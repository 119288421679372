@charset "utf-8";

@mixin transition($speed:400ms,$delay:0ms,$ease:ease-in-out){
	-webkit-transition: all $speed $delay $ease;
	-moz-transition: all $speed $delay $ease;
	-ms-transition: all $speed $delay $ease;
	-o-transition: all $speed $delay $ease;
	transition: all $speed $delay $ease;
}

@mixin flex($parent:wrap , $child:space-between){
	display:-webkit-flex;
	display:-moz-flex;
	display:-o-flex;
	display:-ms-flex;
	display:flex;
	-moz-flex-flow:row $parent;
	-webkit-flex-flow:row $parent;
	-o-flex-flow:row $parent;
	-ms-flex-flow:row $parent;
	flex-flow:row $parent;
	-webkit-justify-content:$child;
	-o-justify-content:$child;
	-ms-justify-content:$child;
	-moz-justify-content:$child;
	justify-content:$child;
}

@mixin clum($int:3 , $width:32% , $margin:2%){
	list-style:none;
	width:$width;
	float:left;
	margin:0 $margin $margin 0;
	&:nth-child(#{$int}n+1){
		clear:both;
	}
	&:nth-child(#{$int}n+#{$int}){
		margin-right:0;
	}
	&:nth-last-child(-n+#{$int}){
		margin-bottom:0;
	}
}

@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 100;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 200;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 300;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 400;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Medium");
	font-weight: 500;
}
@font-face {
	font-family: "Yu Gothic";
	src: local("Yu Gothic Bold");
	font-weight: bold;
}

@mixin font(){
	font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
	font-weight:500;
	-webkit-font-feature-settings : "palt";
	font-feature-settings : "palt";
	letter-spacing:0.024em;
}
@mixin min(){
	font-family: 'Lora', 'Noto Serif JP', "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	font-weight:500;
	-webkit-font-feature-settings : "palt";
	font-feature-settings : "palt";
	letter-spacing:0.04em;
}

@mixin default($width:auto,$font:14px,$color:#000,$link:#D00,$pad:40px,$bg:#FFF){
	*{
		margin:0;
		padding:0;
	}
	*, *:before, *:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-o-box-sizing: border-box;
		-ms-box-sizing: border-box;
		box-sizing: border-box;
	}
	html{
		-webkit-text-size-adjust: none;
	}
	body{
		color: $color;
		background:$bg;
		font-size:$font;
		line-height:1.8;
		@include font();
	}
	h1,h2,h3,h4,h5,h6{
		font-weight:500;
	}
	img {
		-ms-interpolation-mode: bicubic;
		vertical-align:bottom;
	}
	i,strong{
		font-style:normal;
	}
	a{
		text-decoration:none;
		outline:none;
		cursor:pointer;
		color: $link;
		&:hover{
            text-decoration:none;
			color: lighten($link,10%);
			img{
				opacity:.8;
		  	}
		}
	}
	.clear{
		clear:both;
	}
	.clearfix:after{
		content:"";
		display:block;
		clear:both;
	}
	.display{
		width:$width;
		margin:0 auto;
		position:relative;
		top:0;
		left:0;
		padding: 0 $pad;
	}
    ul,ol{
        list-style: none;
    }
	input[type="text"],
	input[type="tel"],
	input[type="email"],
	input[type="submit"],
		textarea{
		-webkit-appearance: none;
	}
}
