.asunaro-footer{
	background: darken($asunaro-normal, 6%);
	color: $asunaro-light;
	a{
		color: $asunaro-light;
	}
	.pagetop{
		background: darken($asunaro-normal, 12%);
		display: block;
		text-align: center;
		padding: 15px;
		@include transition(200ms);
		&:hover{
			background: darken($asunaro-normal, 8%);
		}
	}
	.footerlist{
		padding: 40px 0;
		border-bottom: rgba(#FFF,.12) 1px solid;
		@media screen and (max-width:$responsive){
			padding: 24px 0;	
		}
		ul{
			@include flex();
			align-items: center;
			justify-content: center;
			font-size: 13px;
			@media screen and (max-width:$responsive){
				display: block;	
			}
			li{
				margin: 0 32px 0 0;
				@media screen and (max-width:$responsive){
					margin: 0 0 1px;
					&:last-child{
						margin: 0;
					}
					a{
						display: block;
						padding: 15px;
						background: rgba(#000,.12);
					}
				}
				i{
					display: inline-block;
					margin: 0 0 0 6px;
					transform: translateY(-1px);
					font-size: 10px;
				}
				&:last-child{
					margin: 0;
				}
			}
		}
	}
	.copy{
		display: block;
		text-align: center;
		padding: 40px 0;
		@media screen and (max-width:$responsive){
			padding: 15px 0;	
		}
	}
}
