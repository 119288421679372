.site-about{
	.gallery{
		@include flex();
		margin: 0 0 56px -24px;
		justify-content: flex-start;
		@media screen and (max-width:$responsive){
			margin: 0 0 32px -15px;
		}
		li{
			width: 33.3%;
			padding: 0 0 0 24px;
			margin: 24px 0 0;
			img{
				width: 100%;
			}
			figcaption{
				margin: 8px 0 0;
				text-align: center;
				@media screen and (max-width:$responsive){
					font-size: 12px;	
				}
			}
			@media screen and (min-width:$tab + 1px){
				&:nth-child(-n+3){
					margin-top: 0;
				}
			}			
			@media screen and (max-width:$tab){
				width: 50%;
				&:nth-child(-n+2){
					margin-top: 0;
				}
			}
			@media screen and (max-width:$responsive){
				padding: 0 0 0 15px;	
			}
		}
	}

	.message{
		border: $glay 16px solid;
		padding: 32px 48px;
		@media screen and (max-width:$tab){
			padding: 32px;	
		}
		@media screen and (max-width:$responsive){
			border: none;
			padding: 0 15px;
		}
		> dl{
			margin: 0 auto;
			dd{
				@include min();
				font-size: 15.5px;
				line-height: 2;
				text-align: justify;
				margin: 0 0 24px;
                @media screen and (max-width:$tab){
                    font-size: 14px;
                    line-height: 1.8;
                }
			}
			dt{
				@include min();
				text-align: right;
				font-size: 13px;
				margin: 32px 0 0;
				small{
					font-size: 16px;
                    @media screen and (max-width:$responsive){
                        font-size: 15px;
                    }
				}
				strong{
					font-size: 24px;
					line-height: 1;
					display: inline-block;
					margin: 6px 0 0 6px;
                    @media screen and (max-width:$responsive){
                        font-size: 22px;
                    }
				}
			}
		}
	}
	.titlemessage{
		text-align: center;
		@include min();
		font-size: 16px;
		margin: 0 0 24px;
		@media screen and (max-width:$responsive){
			margin: 15px;
			margin-top: 0;
			font-size: 13px;
		}
	}
}
