.meijyu-fv{
    width: 100%;
    height: calc(100vh - #{$m-head});
	min-height: 560px;
    background: url('/images/meijyu/fv.jpg') right center;
    background-size: cover;
    @include flex();
    align-items: center;
    justify-content: center;
	overflow: hidden;
	position: relative;
	@media screen and (max-width:$tab){
		min-height: 1px;
		height: 480px;
	}
	@media screen and (max-width:$responsive){
		height: 240px;	
	}
	&:before,
	&:after{
		content: '';
		width: 20vw;
		height: 20vw;
		position: absolute;
		top: -10vw;
		left: -10vw;
		transform: rotate(45deg);
		background: $m-green;
		opacity: .64;
	}
	&:after{
		top: auto;
		left: auto;
		bottom: -10vw;
		right: -10vw;
	}
	i.conner{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		pointer-events: none;
		&:before,
		&:after{
			content: '';
			width: 18vw;
			height: 8px;
			background: $m-green;
			position: absolute;
			top: 16vw;
			left: -8vw;
			border-radius: 999px;
			transform: rotate(-45deg);
			opacity: .64;
		}
		&:after{
			top: auto;
			left: auto;
			bottom: 16vw;
			right: -8vw;
			transform: rotate(-45deg);
		}
	}
	.title{
		position: relative;
		z-index: 3;
		text-align: center;
		transform: translateY(-32px);
		@media screen and (max-width:$responsive){
			transform: translateY(0);
		}
		&__eng{
			@include poppin();
			color: $m-green;
			font-size: 72px;
			font-weight: 600;
			line-height: 1.1;
			margin: 0 0 16px;
			letter-spacing: 0em;
			@media screen and (max-width:$tab){
				font-size: 56px;	
			}
			@media screen and (max-width:$responsive){
				font-size: 32px;	
			}
			.le{
				text-indent: -.16em;
				display: inline-block;
				letter-spacing: .032em;
			}
		}
		h1{
			font-size: 16px;
			background: #FFF;
			color: $m-normal;
			font-weight: 500;
			display: inline-block;
			line-height: 1;
			padding: 18px 32px;
			border-radius: 999px;
			@media screen and (max-width:$tab){
				font-size: 15px;	
			}
			@media screen and (max-width:$responsive){
				padding: 8px 16px;
				font-size: 10px;
			}
		}
	}
	.scroll{
		position: absolute;
		left: 50%;
		bottom: 40px;
		z-index: 100;
		transform: translateX(-50%);
		text-align: center;
		@include poppin();
		font-size: 10px;
		letter-spacing: .24em;
		@media screen and (max-width:$tab){
			bottom: 15px;	
		}
		@media screen and (max-width:$responsive){
			display: none;
		}
		&:before{
			content: '';
			width: 30px;
			height: 52px;
			background: #FFF;
			border-radius: 999px;
			position: absolute;
			bottom: 32px;
			left: 50%;
			transform: translateX(-50%);
			box-shadow: 0 0 32px rgba($m-normal,.16);
			@media screen and (max-width:$tab){
				bottom: 24px;	
			}
		}
		&:after{
			content: '';
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background: $m-green;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 64px;
			animation: sce 1600ms infinite;
			@media screen and (max-width:$tab){
				bottom: 56px;	
			}
			@keyframes sce{
				0%{
					bottom: 56px;
					opacity: 0;
				}
				20%{
					bottom: 56px;
					opacity: 1;
				}
				70%{
					bottom: 40px;
					opacity: 1;
				}
				100%{
					bottom: 40px;
					opacity: 0;
				}
			}
		}
	}
}
