@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');

$asunaro-normal: #505254;
$asunaro-glay: #f3f3f4;
$asunaro-yellow: #d0a200;
$asunaro-blue: #5a8a9f;
$asunaro-light: #ebf1f4;

$asunaro-head: 82px;

@mixin poppin{
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
}

@mixin btn{
	background: $asunaro-blue;
	color: #FFF;
	display: inline-block;
	font-size: 17px;
	padding: 11px 40px;
	@media screen and (max-width:$tab){
		font-size: 15px;
		padding: 10px 32px;
	}
	@media screen and (max-width:$responsive){
		display: block;
		text-align: center;
		padding: 12px 15px;
		font-size: 17px;
	}
	&:hover{
		background: darken($asunaro-blue, 8%);
	}
}

@import "page/asunaro/header";
@import "page/asunaro/footer";
@import "page/asunaro/firstview";

.asunaro-body{
	background: #FFF;
	color: $asunaro-normal;
	.asunaro-module{
		&.wrap{
			padding: 80px 0;
			@media screen and (max-width:$tab){
				padding: 56px 0;	
			}
			&.no-top{
				padding-top: 0;
			}
			&.no-bottom{
				padding-bottom: 0;
			}
		}
		h3.large{
			text-align: center;
			font-size: 16px;
			line-height: 1;
			font-weight: 700;
			padding: 0 0 32px;
			@media screen and (max-width:$responsive){
				font-size: 13px;	
			}
			&:before{
				@include poppin();
				content: attr(data-eng);
				font-size: 42px;
				font-weight: 700;
				text-align: center;
				display: block;
				color: $asunaro-blue;
				letter-spacing: .12em;
				margin: 0 0 12px;
				@media screen and (max-width:$responsive){
					font-size: 28px;
					margin: 0 0 8px;
				}
			}
			&.white{
				&:before{
					color: #FFF;
				}
			}
		}
		.tablestyle{
			border: $border 1px solid;
			width: 100%;
			border-collapse: collapse;
			font-size: 16px;
			@media screen and (max-width:$responsive){
				font-size: 14px;	
			}
			tbody{
				tr{
					border-bottom: $border 1px solid;
				}
				th,td{
					vertical-align: middle;
					text-align: left;
					padding: 32px 20px;
					@media screen and (max-width:$responsive){
						padding: 15px;	
					}
				}
				th{
					font-weight: 700;
					@media screen and (max-width:$responsive){
						width: 140px;	
					}
				}
			}
		}
	}
}

.asunaro-article,
.asunaro-footer{
	position: relative;
	top: $asunaro-head;
	left: 0;
}

.asunaro-first{
	position: relative;
	z-index: 2;
	.firstcontent{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		@media screen and (max-width:$tab){
			top: -15px;	
		}
		&__box{
			background: #FFF;
			padding-top: 64px;
			padding-bottom: 64px;
			box-shadow: 0 -32px 32px rgba($normal,.08);
			@media screen and (max-width:$responsive){
				padding-top: 32px;
				padding-bottom: 32px;
			}
			h2{
				@include min();
				//color: $asunaro-blue;
				text-align: center;
				font-size: 22px;
				line-height: 1.7;
				font-weight: 300;
				@media screen and (max-width:$responsive){
					font-size: 16px;
					margin: 0 30px;
					br{
						display: none;
					}
				}
				&:before{
					@include poppin();
					content: attr(data-eng);
					color: $asunaro-blue;
					display: block;
					text-align: center;
					font-size: 13px;
					letter-spacing: .12em;
					margin: 0 0 8px;
				}
			}
			.context{
				text-align: center;
				font-size: 15px;
				margin: 16px 0 0;
				@media screen and (max-width:$responsive){
					margin: 15px 15px 0;
					font-size: 12.5px;
					br{
						display: none;
					}
				}
			}
		}
	}
	.secondcontent{
		padding: 300px 0 80px;
		position: relative;
		overflow: hidden;
		z-index: 1;
		@media screen and (max-width:$tab){
			padding: 250px 0 56px;	
		}
		@media screen and (max-width:$responsive){
			padding: 250px 0 32px;	
		}
		&:before,
		&:after{
			content: '';
			width: 140%;
			height: 760px;
			background: $asunaro-light;
			position: absolute;
			left: -20%;
			bottom: -400px;
			transform: rotate(10deg);
			@media screen and (max-width:$responsive){
				transform: rotate(0deg);
				left: 0;
				bottom: -2px;
				width: 100%;
				height: calc(88% - 250px);
			}
		}
		&:before{
			left: auto;
			right: -20%;
			transform: rotate(-10deg);
			@media screen and (max-width:$responsive){
				display: none;
			}
		}
		&__list{
			@include flex();
			position: relative;
			z-index: 2;
			@media screen and (max-width:$responsive){
				display: block;	
			}
			li{
				width: 30.5%;
				background: #FFF;
				@media screen and (max-width:$tab){
					width: 32%;	
				}
				@media screen and (max-width:$responsive){
					width: auto;
					margin: 0 0 15px;
					&:last-child{
						margin: 0;
					}
				}
				&:nth-child(odd){
					transform: translateY(-32px);
					@media screen and (max-width:$responsive){
						transform: translateY(0);
					}
				}
				img{
					width: 100%;
				}
				dl{
					padding: 20px;
					@media screen and (max-width:$tab){
						padding: 15px;	
					}
					> dt{
						font-weight: 700;
						font-size: 18px;
						text-align: center;
						margin: 0 0 6px;
						@media screen and (max-width:$tab){
							font-size: 16px;
						}
						&:before{
							content: attr(data-eng);
							color: $asunaro-yellow;
							display: block;
							font-size: 9px;
						}
					}
					> dd{
						text-align: justify;
						font-size: 13.5px;
						line-height: 1.66;
					}
				}
			}
		}
	}
}

.asunaro-second{
	background: $asunaro-light;
	position: relative;
	overflow: hidden;
	@media screen and (max-width:$responsive){
		background: #FFF;
		padding-top: 0 !important;
	}
	&:before,
	&:after{
		content: '';
		width: 72%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background: #FFF;
		z-index: 1;
		@media screen and (max-width:$responsive){
			display: none;
		}
	}
	&:after{
		content: '';
		width: 100%;
		height: calc(100% - 80px);
		position: absolute;
		top: 80px;
		right: 0;
		background: #FFF;
		z-index: 1;
	}
	.bg{
		position: absolute;
		top: 80px;
		left: 0;
		width: 50%;
		height: calc(100% - (80px * 2));
		background-size: cover;
		background-position: center;
		z-index: 2;
		@media screen and (max-width:$responsive){
			top: 0;
			position: relative;
			width: 100%;
			height: 200px;
		}
	}
	.content{
		margin: 0 0 0 50%;
		padding: 64px 0;
		padding-left: 56px;
		position: relative;
		@media screen and (max-width:$tab){
			padding: 48px 0;
			padding-left: 32px;	
		}
		@media screen and (max-width:$responsive){
			padding: 32px 0 0;
			margin: 0;
		}
		z-index: 2;
		dt{
			font-size: 30px;
			font-weight: 700;
			line-height: 1;
			margin: 0 0 28px;
			@media screen and (max-width:$tab){
				font-size: 24px;
				line-height: 1.5;
				margin: 0 0 12px;
			}
			@media screen and (max-width:$responsive){
				font-size: 20px;
				text-align: center;
			}
			&:before{
				content: attr(data-eng);
				font-size: 11px;
				font-weight: 700;
				display: block;
				margin: 0 0 16px;
				color: $asunaro-blue;
				text-indent: .24em;
				letter-spacing: .12em;
				@media screen and (max-width:$tab){
					margin: 0 0 8px;	
				}
			}
		}
		dd{
			font-size: 15px;
			text-align: justify;
			@media screen and (max-width:$tab){
				font-size: 14px;	
			}
			@media screen and (max-width:$responsive){
				font-size: 13px;	
			}
			&.btnarea{
				margin: 32px 0 0;
				@media screen and (max-width:$responsive){
					margin: 24px 0 0;	
				}
			}
			a{
				@include btn;
			}
		}
	}
}

.asunaro-third{
	overflow: visible;
	position: relative;
	z-index: 2;
	&:after{
		content: '';
		width: 100%;
		height: 8px;
		background: $asunaro-glay;
		position: absolute;
		top: calc(50% - 40px);
		left: 0;
		transform: translateY(-50%);
	}
	dl{
		background: $asunaro-glay;
		padding: 32px 80px;
		position: relative;
		z-index: 2;
		@media screen and (max-width:$responsive){
			padding: 15px;	
		}
		&:before{
			content: "\f5c9";
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			color: lighten($asunaro-blue, 24%);
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			font-size: 32px;
		}
		dt{
			text-align: center;
			font-size: 24px;
			font-weight: 700;
			margin: 0 0 12px;
			@media screen and (max-width:$responsive){
				font-size: 20px;
				margin: 0 0 8px;
				padding: 15px 0 0;
			}
		}
		dd{
			font-size: 15px;
			text-align: center;
			@media screen and (max-width:$responsive){
				font-size: 13px;
				text-align: justify;
			}
		}
	}
}

.asunaro-four{
	background: url("../../../images/asunaro/soccer.jpg") no-repeat center fixed;
	background-size: cover;
	position: relative;
	color: #FFF;
	&:before{
		content: '';
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		background: darken($asunaro-blue, 4%);
		opacity: .88;
		z-index: 1;
	}
	
	.display{
		z-index: 2;
	}
	h4{
		text-align: center;
		@include min();
		font-size: 28px;
		font-weight: 700;
		@media screen and (max-width:$responsive){
			font-size: 18px;	
		}
		&:after{
			content: attr(data-jp);
			font-size: 14px;
			display: block;
			@media screen and (max-width:$responsive){
				font-size: 12px;	
			}
		}
	}
	.stronger{
		font-size: 18px;
		font-weight: 700;
		text-align: center;
		padding: 4px;
		border: #FFF 1px solid;
		margin: 20px 0 0;
		strong{
			background: #FFF;
			color: darken($asunaro-blue, 20%);
			display: block;
			padding: 8px 0;
		}
	}
	.context{
		text-align: center;
		font-size: 15px;
		margin: 24px 0 0;
		@media screen and (max-width:$responsive){
			font-size: 14px;	
		}
	}
	.list{
		@include flex();
		margin: 32px 0;
		@media screen and (max-width:$responsive){
			display: block;
			margin: 24px 0;
		}
		li{
			width: 32%;
			@media screen and (max-width:$responsive){
				width: auto;
				margin: 0 0 15px;
				&:last-child{
					margin: 0;
				}
			}
			img{
				width: 100%;
			}
		}
	}
	.btnarea{
		text-align: center;
		a{
			@include btn();
			background: darken($asunaro-blue, 32%);
			i{
				display: inline-block;
				margin: 0 0 0 8px;
				font-size: 12px;
				transform: translateY(-2px);
			}
			&:hover{
				background: darken($asunaro-blue, 20%);
			}
		}
	}
}

.googlemap{
	position: relative;
	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		box-shadow: inset 0 10px 10px rgba($asunaro-normal,.04);
		z-index: 2;
		pointer-events: none;
	}
	iframe{
		width: 100%;
		height: 300px;
		vertical-align: bottom;
		filter: grayscale(1);
		@media screen and (max-width:$responsive){
			height: 200px;	
		}
	}
}