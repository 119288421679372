@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#1c2a40;
$link:#D02;
$border:#e8e8ea;
$glay: #f1f4f9;
$dark: #cfdaea;

$pink: #fff0f2;
$red: #ff8392;


/* SITE SETTING
------------------------------------------------------------*/
$width:1120px;
$pc-head:80px;
$sp-head:60px;

$ease: cubic-bezier(0.85, 0, 0.15, 1);

$tab: $width - 1px;
$responsive: 768px;

@mixin fontsize($w: 32px, $m: 22){
    font-size: calc(#{$w} + #{$m} * (100vw - 450px)/ 900);
}

@mixin hand(){
    font-family: 'Pinyon Script', cursive;
}

@media screen and (min-width:$width){
	@include default(
		$width, //サイト幅
		14.5px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		40px, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width:$tab){
	@include default(
		auto, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        40px, //パディング
		#FFF //ボディ背景
	);
}

@media screen and (max-width:$responsive){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
        16px, //パディング
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/index";
@import "page/contact";
@import "page/faq";
@import "page/agree";
@import "page/about";
@import "page/news";
@import "page/whitening";

@import "page/asunaro/style";
@import "page/meijyu/style";

.site-clum{
    @include flex();
    margin: 0 auto;
    @media screen and (max-width:$tab){
        width: auto;
        display: block;
    }
    &__sidebar{
        width: 280px;
        @media screen and (max-width:$tab){
            width: auto;
        }
        @media screen and (max-width:$responsive){
            margin: 32px 0 0;
        }
    }
    &__container{
        width: calc(100% - 280px);
        padding: 0 48px 0 0;
        @media screen and (max-width:$tab){
            width: auto;
            padding: 0;
        }
        .pager{
            margin: 24px 0 0;
            text-align: center;
            a,span{
                $size: 28px;
                width: $size;
                height: $size;
                line-height: $size - 2px;
                text-align: center;
                display: inline-block;
                margin: 0 3px;
                border: $border 1px solid;
                background: $border;
                color: $normal;
                font-size: 13px;
            }
            span{
                background: $dark;
                border-color: $dark;
                color: #FFF;
            }
            a{
                &:hover{
                    color: $normal;
                    background: #FFF;
                    border-color: $normal;
                }
            }
        }
    }
}


.site-module{
    &.wrap{
        padding: 96px 0;
        @media screen and (max-width:$tab){
            padding: 72px 0;
        }
        @media screen and (max-width:$responsive){
            padding: 48px 0;
        }
		&.border{
			border-bottom: $border 1px solid;
		}
		&.mid{
			padding: 72px 0;
            @media screen and (max-width:$tab){
                padding: 56px 0;
            }
            @media screen and (max-width:$responsive){
                padding: 32px 0;
            }
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
    }
    h1,
    h2,
    h3,
    h4{
        &.large{
            text-align: center;
            @include min();
            font-size: 30px;
			letter-spacing: 0em;
            font-weight: 400;
            line-height: 1;
            margin: 0 0 28px;
			background: url("../../../images/common/h_icon.png") no-repeat center top;
			background-size: 64px;
			padding: 50px 0 0;
            @media screen and (max-width:$responsive){
                font-size: 20px;
                line-height: 1.56;
            }
            @media screen and (max-width:$responsive){
                font-size: 18px;
                margin: 0 0 24px;
                padding: 42px 0 0;
            }
			&.no{
				background: none;
				padding: 0;
			}
            &:after{
                content: attr(data-eng);
                font-size: 11px;
                display: block;
                margin: 14px 0 0;
                letter-spacing: .4em;
                @media screen and (max-width:$responsive){
                    font-size: 10px;
                    margin: 6px 0 0;
                }
            }
        }
    }
    .indent{
        text-indent: -.32em;
        display: inline-block;
    }
	.btnarea{
		margin: 32px 0 0;
		&.center{
			text-align: center;
		}
		a{
			border: $normal 1px solid;
			display: inline-block;
			min-width: 300px;
			color: $normal;
			text-align: center;
			font-size: 16px;
			font-weight: 700;
			padding: 12px 64px;
            @media screen and (max-width:$responsive){
                display: block;
                font-size: 15px;
                padding: 10px 16px;
            }
			&:hover{
				background: $normal;
				color: #FFF;
			}
			&.white{
				background: $normal;
				color: #FFF;
				position: relative;
				z-index: 1;
				&:after{
					content: '';
					position: absolute;
					z-index: -2;
					width: 100%;
					height: 100%;
					border: $normal 1px solid;
					top: 8px;
					left: 8px;
					@include transition(240ms);
                    @media screen and (max-width:$responsive){
                        display: none;
                    }
				}
				&:before{
					content: '';
					position: absolute;
					background: #FFF;
					width: 0;
					height: 100%;
					top: 0;
					left: 0;
					z-index: -1;
					@include transition(240ms);
				}
				&:hover{
					color: $normal;
					&:after{
						background: $normal;
					}
					&:before{
						width: 100%;
					}
				}
			}
			i{
				display: inline-block;
				font-size: .9rem;
				margin: 0 0 0 8px;
				transform: translateY(-2px);
			}
		}
	}
	table.tablestyle{
		width: 100%;
		border: $border 1px solid;
		border-collapse: collapse;
        @media screen and (max-width:$responsive){
            display: block;
        }
		tbody{
            @media screen and (max-width:$responsive){
                display: block;
            }
			tr{
                @media screen and (max-width:$responsive){
                    display: block;
                    border-bottom: $border 1px solid;
                    &:last-child{
                        border: none;
                    }
                }
				th,td{
                    @media screen and (max-width:$responsive){
                        display: block;
                        border: none;
                    }
					padding: 15px;
					vertical-align: middle;
					text-align: left;
					border: $border 1px solid
				}
				th{
					background: $glay;
					width: 200px;
                    @media screen and (max-width:$responsive){
                        width: auto;
                    }
				}
				td{
					dl{
						@include flex();
						align-items: flex-start;
						margin: 0 0 15px;
						&:last-of-type{
							margin: 0;
						}
						dt{
							width: 120px;
							border: $normal;
							padding: 3px 0;
							text-align: center;
							background: $glay;
						}
						dd{
							width: calc(100% - 120px);
							margin: 2px 0 0;
							padding: 0 0 0 24px;
						}
					}
				}
			}
		}
	}
	table.formstyle{
		width: 100%;
		border-collapse: collapse;
        @media screen and (max-width:$responsive){
            display: block;
        }
		tbody{
            @media screen and (max-width:$responsive){
                display: block;
            }
			tr{
				border-bottom: $border 1px solid;
                @media screen and (max-width:$responsive){
                    display: block;
                    &:last-child{
                        border: none;
                    }
                }
				th,td{
					padding: 32px 0;
					text-align: left;
					vertical-align: middle;
					font-size: 15px;
                    @media screen and (max-width:$responsive){
                        padding: 15px 0;
                        display: block;
                    }
				}
				th{
					width: 240px;
                    @media screen and (max-width:$responsive){
                        width: auto;
                    }
					&.hiss{
						&:after{
							content: '必須';
							font-size: 13px;
							padding: 2px 16px;
							border-radius: 3px;
							display: inline-block;
							background: $normal;
							color: #FFF;
							float: right;
						}
					}
				}
				td{
					padding-left: 40px;
                    @media screen and (max-width:$responsive){
                        padding-left: 0;
                        padding-top: 0;
                    }
					input[type=text],
					input[type=tel],
					input[type=email],
					select,
					textarea{
						@include font();
						border: $border 2px solid;
						padding: 5px 12px;
						border-radius: 3px;
						display: block;
						width: 100%;
						font-size: 15px;
						&:focus{
							border-color: $normal;
							box-shadow: 0 0 15px rgba($normal,.08);
						}
					}
					textarea{
						height: 150px;
					}
				}
			}
		}
	}
	.formsend{
		margin: 32px 0 0;
		text-align: center;
        @media screen and (max-width:$responsive){
            margin: 16px 0 0;
        }
		input{
            display: inline-block;
            background: #FFF;
			width: 320px;
            border: $normal 2px solid;
            color: $normal;
            padding: 13px 0;
            border-radius: 4px;
            font-size: 18px;
            font-weight: 700;
			cursor: pointer;
            @media screen and (max-width:$responsive){
                width: 100%;
                font-size: 17px;
            }
            &:hover{
                background: $normal;
                color: #FFF;
                box-shadow: 0 0 16px rgba($normal,.12);
            }
		}
	}
}

.site-404{
	.context{
		text-align: center;
		dt{
			font-weight: 700;
		}
		dd{
			font-size: 13px;
		}
	}
}

.linkbnr{
	margin: 40px 0 0;
	text-align: center;
	@media screen and (max-width:$responsive){
		margin: 24px 0 0;	
	}
	&.meijyu{
		margin: 0 0 40px;
		@media screen and (max-width:$responsive){
			margin: 0 0 24px;
		}
		.linkbnr__link{
			//border-color: $m-green;
			.linkbnr__link__img{
				width: 240px;
			}
		}
	}
	&__link{
		display: block;
		border: $normal 2px solid;
		border-radius: 4px;
		padding: 24px 40px;
		@media screen and (max-width:$responsive){
			padding: 15px;
		}
		&:hover{
			opacity: .8;
		}
		&__img{
			width: 140px;
			margin: 0 auto;
			&.meijyu{
				width: 200px;
			}
			img{
				width: 100%;
			}
		}
		&__ttl{
			background: $normal;
			color: #FFF;
			border-radius: 4px;
			margin: 12px 0 0;
			font-size: 14px;
			padding: 8px 24px;
			@media screen and (max-width:$responsive){
				padding: 8px 15px;
				font-size: 12px;
			}
		}
	}
}

#intro{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #FFF;
    transition: opacity 800ms;
	visibility: hidden;

    &.visible{
        background: $normal;
		visibility: visible;
    }
    &.active{
        opacity: 0;
    }
	#svglogo{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 440px;
		height: auto;
		transform: translateX(-50%) translateY(-50%) scale(0);
		animation: scales 600ms 800ms forwards;
        @media screen and (max-width:$responsive){
            width: 200px;
        }
		@keyframes scales{
			0%{
				transform: translateX(-50%) translateY(-50%) scale(.8);
			}
			100%{
				transform: translateX(-50%) translateY(-50%) scale(1);
			}
		}
		.st0{
			stroke:#FFF;
			fill:#FFF;
			fill-opacity: 0;
			stroke-width:1;
			stroke-dasharray: 3000;
			stroke-dashoffset:3000;
			animation: lineanime 4s ease-in 800ms forwards;
		}
		@keyframes lineanime{
			0%{stroke-dashoffset:3000;fill-opacity: 0;}
			30% {fill-opacity: 0;}
			35% {fill-opacity: 1;}
			100%{stroke-dashoffset:0;fill-opacity: 1;}
		}
	}
}


// USE ANIMATE
.scroll-anime{
	opacity: 0;
	transform: translateY(48px);
	@include transition(800ms);
	&._active{
		opacity: 1;
		transform: translateY(0);
	}
}

.scroll-img{
	position: relative;
	$ease-transition: cubic-bezier(0.86, 0, 0.07, 1);
	$speed: 600ms;
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
        z-index: 2;
	}
	&:before{
		background: #FFF;
		@include transition($speed , $delay:$speed , $ease:$ease-transition);
	}
	&:after{
		background: $glay;
		@include transition($speed , $ease: $ease-transition);
	}
	&.__active{
		&:before{
			width: 0;
		}
		&:after{
			width: 0;
		}
	}
}

.scale-enter-active, .scale-leave-active {
	transition: 400ms;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
}
