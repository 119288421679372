.site-whitening{
	.localnav{
		&__list{
			@include flex();
			margin: 0 0 32px;
			border-radius: 4px;
			overflow: hidden;
			@media screen and (max-width:$responsive){
				margin: 0 0 16px;	
			}
			li{
				width: 25%;
				border-right: #FFF 1px solid;
				&:last-child{
					border-right: none;
				}
				@media screen and (max-width:$tab){
					width: 50%;
					&:nth-child(2n+2){
						border-right: none;
					}
					&:nth-child(-n+2){
						border-bottom: #FFF 1px solid;
					}
					
				}
				a{
					display: block;
					background: darken($dark, 16%);
					color: #FFF;
					font-size: 17px;
					text-align: center;
					padding: 10px 15px;
					transition: background 400ms;
					@media screen and (max-width:$responsive){
						font-size: 14px;
						padding: 10px 0;
					}
					&:hover{
						background: darken($dark, 4%);
					}
				}
			}
		}
	}
	.context{
		@include min();
		font-size: 16px;
		text-align: center;
		margin: 0 0 32px;
		@media screen and (max-width:$responsive){
			margin: 0 15px 16px;
			font-size: 14px;
			br{
				display: none;
			}
		}
	}
    .list{
        @include flex();
        margin: 0 0 72px;
        @media screen and (max-width:$tab){
            margin: 0 0 56px;
        }
		@media screen and (max-width:$responsive){
			margin: 0 0 48px;	
		}
        li{
            list-style: none;
            width: 31.5%;
            background: #FFF;
            box-shadow: 0 12px 32px rgba($normal,.06);
			@media screen and (max-width:$responsive){
				width: auto;
				margin: 0 0 15px;
				box-shadow: 0 6px 15px rgba($normal,.06);
				&:last-child{
					margin: 0;
				}
			}
            figure{
                img{
                    width: 100%;
                }
            }
            dl{
                padding: 24px 20px;
                dt{
                    @include min();
                    text-align: center;
                    font-weight: 700;
                    font-size: 16.5px;
                    margin: 0 0 16px;
                    padding: 0 0 16px;
                    border-bottom: $border 1px dashed;
                    &:before{
                        content: attr(data-eng);
                        font-size: 10px;
                        letter-spacing: .24em;
                        color: darken($glay, 32%);
                        display: block;
                    }
                }
                dd{
                    text-align: justify;
                    font-size: 14px;
                }
            }
        }
    }
	.trouble{
		text-align: center;
		position: relative;
		@include min();
		font-size: 24px;
		@media screen and (max-width:$responsive){
			font-size: 15px;	
		}
		&__txt{
			display: inline-block;
			background: #FFF;
			padding: 0 24px;
			position: relative;
			z-index: 2;
			@media screen and (max-width:$responsive){
				padding: 0 15px;	
			}
		}
		&:before{
			content: '';
			width: 100%;
			height: 1px;
			background: $normal;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}
	.beglist{
		@include flex();
		margin: 16px 0 0;
		@media screen and (max-width:$responsive){
			margin: 8px 0 0;
			display: block;
		}
		li{
			list-style: none;
			width: calc(50% - 10px);
			font-size: 16px;
			margin: 20px 0 0;
			border: darken($glay,2%) 2px solid;
			border-radius: 4px;
			padding: 10px 15px;
			box-shadow: 0 0 16px rgba($normal,.08);
			@media screen and (max-width:$responsive){
				width: auto;
				margin: 8px 0 0;
				padding-left: 40px;
				position: relative;
			}
			i{
				color: darken($dark, 8%);
				margin: 0 6px 0 0;
				@media screen and (max-width:$responsive){
					position: absolute;
					top: 16px;
					left: 15px;
				}
			}
		}
	}
	.beganswer{
		margin: 64px 0 0;
		padding: 64px 0 72px;
		background: $glay;
		background: -moz-linear-gradient(left,  $dark 0%, lighten($glay,2%) 50%, $dark 100%);
		background: -webkit-linear-gradient(left,  $dark 0%,lighten($glay,2%) 50%,$dark 100%);
		background: linear-gradient(to right,  $dark 0%,lighten($glay,2%) 50%,$dark 100%);
		position: relative;
		box-shadow: inset 0 32px 32px rgba($dark,.32);
		@media screen and (max-width:$responsive){
			margin: 24px 0 0;
			padding: 40px 0 32px;
		}
        &:before{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 24px 20px 0 20px;
            border-color: #FFF transparent transparent transparent;
            position: absolute;
            top: -2px;
            left: 50%;
            transform: translateX(-50%);
        }
		dl{
			position: relative;
			dt{
				@include min();
				font-size: 22px;
				line-height: 1.66;
				text-align: center;
				@media screen and (max-width:$responsive){
					font-size: 16px;
					margin: 0 15px;
				}
			}
			.beafter{
				width: 920px;
				margin: 32px auto;
				position: relative;
				@include flex();
                @media screen and (max-width:$tab){
                    width: auto;
                }
				i{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateX(-50%) translateY(-50%);
					width: 50px;
					height: 50px;
					background: #FFF;
					border-radius: 50%;
					@include flex();
					font-size: 24px;
					color: $dark;
					align-items: center;
					justify-content: center;
					@media screen and (max-width:$responsive){
						width: 20px;
						height: 20px;
						font-size: 10px;	
					}
				}
				&__before,
				&__after{
					width: 420px;
					border-radius: 8px;
					overflow: hidden;
					border: #FFF 4px solid;
					background: #FFF;
                    @media screen and (max-width:$tab){
                        width: calc(50% - 48px);
                    }
					@media screen and (max-width:$responsive){
						width: calc(50% - 15px);
					}
					figcaption{
						padding: 14px 0 10px;
						text-align: center;
						@media screen and (max-width:$responsive){
							padding: 10px 0 6px;	
						}
					}
					img{
						width: 100%;
					}
				}
			}
		}
	}

	.secondary{
		position: relative;
		overflow: hidden;
		&__kussion{
			position: absolute;
			top: -30%;
			left: 0;
			width: 100%;
			height: 160%;
			background-position: center;
			background-size: cover;
		}
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: darken($normal, 8%);
			opacity: .4;
			z-index: 2;
		}
		.display{
			z-index: 3;
		}
		h3{
			@include min();
			font-size: 26px;
			text-align: center;
			color: #FFF;
			margin: 0 0 24px;
			@media screen and (max-width:$responsive){
				font-size: 17px;
				margin: 0 15% 16px;
			}
			strong{
				font-weight: 400;
				line-height: 1;
				font-size: 40px;
				@media screen and (max-width:$responsive){
					font-size: 24px;	
				}
			}
		}
		.flexbox{
			@include flex();
			@media screen and (max-width:$tab){
				display: block;	
			}
		}
		.middle{
			color: #FFF;
			font-size: 16px;
			text-align: center;
			background: $normal;
			padding: 20px;
			&--harf{
				width: calc(50% - 10px);
				@media screen and (max-width:$tab){
					width: auto;
					margin: 0 0 20px;
					&:last-of-type{
						margin: 0;
					}
				}
				&.cols{
					background: darken($dark, 24%);
				}
			}
			@media screen and (max-width:$responsive){
				padding: 15px;	
			}
			&__ttl{
				border: #FFF 1px solid;
				font-size: 18px;
				display: inline-block;
				padding: 6px 12px;
				margin: 0 16px 0 0;
				transform: translateY(-8px);
				@media screen and (max-width:$responsive){
					font-size: 16px;
					display: block;
					margin: 0 0 6px;
					text-align: center;
					transform: translateY(0);
				}
			}
			dt{
				font-size: 64px;
				@include min();
				margin: 0 0 12px;
				line-height: 1.4;
				&.s{
					font-size: 40px;
					margin: 0 0 8px;
					@media screen and (max-width:$responsive){
						margin: 0 0 6px;	
					}
				}
				@media screen and (max-width:$responsive){
					font-size: 32px;
					margin: 0 0 8px;
				}
				small{
					display: inline-block;
					margin: 0 0 0 6px;
					font-size: 24px;
					@media screen and (max-width:$responsive){
						font-size: 20px;	
					}
				}
			}
			dd{
				&.title{
					@include min();
					letter-spacing: 0em;
					font-size: 26px;
					line-height: 1.4;
					.s{
                    	font-size: 20px;
                	}
					@media screen and (max-width:$responsive){
						font-size: 18px;	
					}
				}
				&.pad{
					padding-top: 22px;
					@media screen and (max-width:$tab){
						padding-top: 0;	
					}
				}
				&.sns{
					font-size: 24px;
					margin: 0 0 8px;
					small{
						font-size: 13px;
						display: inline-block;
						margin: 0 0 0 8px;
					}
				}
			}
		}
		.snst{
			text-align: center;
			width: 320px;
			margin: 24px auto 0;
			background: rgba(#000,.12);
			border: #FFF 2px solid;
			color: #FFF;
			font-size: 18px;
			font-weight: 700;
			padding: 10px 0;
			@media screen and (max-width:$responsive){
				font-size: 16px;
				display: block;
				width: auto;
			}
		}
		.center{
			text-align: center;
			font-size: 18px;
			margin: 24px 0 0;
			color: #FFF;
		}
	}

    .flexmachine{
        @include flex();
		@media screen and (max-width:$responsive){
			display: block;	
		}
        &__pic{
            width: 400px;
            @media screen and (max-width:$tab){
                width: 320px;
            }
			@media screen and (max-width:$responsive){
				width: 88%;
				margin: 0 auto;
			}
            img{
                width: 100%;
            }
        }
		&__dl{
			width: calc(100% - 400px);
			padding: 0 0 0 48px;
            @media screen and (max-width:$tab){
                width: calc(100% - 320px);
                padding: 0 0 0 40px;
            }
			@media screen and (max-width:$responsive){
				width: auto;
				padding: 0;
				margin: 15px 0 0;
			}
			dt{
				@include min();
				font-size: 32px;
				letter-spacing: 0em;
				line-height: 1.56;
                @media screen and (max-width:$tab){
                    font-size: 24px;
                }
				@media screen and (max-width:$responsive){
					font-size: 20px;
					text-align: center;
				}
			}
			dd{
				margin: 20px 0 0;
				padding: 20px 0 0;
				border-top: $border 1px solid;
				font-size: 15px;
				text-align: justify;
                @media screen and (max-width:$tab){
                    font-size: 13.5px;
                    br{
                        display: none;
                    }
                }
				&.min{
					margin: 4px 0 0;
					padding: 0;
					border: none;
					font-size: 12px;
					@media screen and (max-width:$responsive){
						text-align: center;	
					}
				}
			}
		}
    }
	.flexaward{
		margin: 24px 0 0;
		padding: 24px 0 0;
		border-top: $border 1px solid;
		@include flex();
		@media screen and (max-width:$tab){
			padding: 15px 0 0;
			margin: 15px 0 0;
		}
		li{
			width: 23.5%;
			border: $border 1px solid;
			img{
				width: 100%;
			}
		}
	}
	.moon{
		background: #131949;
		padding: 60px 0 0;
		@media screen and (max-width:$responsive){
			padding-top: 32px;	
		}
		&__pic{
			width: 50%;
			min-width: 880px;
			max-width: 1000px;
			margin: 0 auto;
            @media screen and (max-width:$tab){
                min-width: 1px;
                width: 80%;
                max-width: 100%;
            }
			img{
				width: 100%;
			}
		}
		&__content{
			background: #FFF;
		}
		&__ttl{
			@include min();
			font-size: 36px;
			text-align: center;
			line-height: 1;
			&:after{
				content: attr(data-text);
				margin: 16px 0 0;
				font-size: 14px;
				display: block;
			}
		}
		&__item{
			width: 800px;
			margin: 32px auto 0;
            @media screen and (max-width:$tab){
                width: auto;
            }
			img{
				width: 100%;
			}
			figcaption{
				text-align: center;
				margin: 32px 0 0;
			}
		}
	}
	.lips{
		background: $glay;
		&__ttl{
			text-align: center;
			@include min();
			font-size: 26px;
			margin: 0 0 24px;
			@media screen and (max-width:$responsive){
				font-size: 18px;
				margin: 0 15px 15px;
			}
		}
		&__box{
			@include flex();
			align-items: center;
			background: #FFF;
			margin: 0 0 64px;
            @media screen and (max-width:$tab){
                margin: 0 0 32px;
                padding: 20px;
                align-items: flex-start;
            }
			@media screen and (max-width:$responsive){
				margin: 0 0 24px;
				padding: 15px;
				display: block;
			}
			> figure{
				width: 500px;
                @media screen and (max-width:$tab){
                    width: 320px;
                }
				img{
					width: 100%;
				}
			}
			&__txt{
				width: calc(100% - 500px);
				padding: 32px 48px;
                @media screen and (max-width:$tab){
                    width: calc(100% - 320px);
                    padding: 0 0 0 32px;
                }
				@media screen and (max-width:$responsive){
					width: auto;
					padding: 0;
					margin: 20px 0 0;
				}
				h3{
					@include min();
					font-size: 24px;
					line-height: 1.66;
					margin: 0 0 24px;
					@media screen and (max-width:$responsive){
						font-size: 17px;
						margin: 0 0 15px;
					}
				}
				dl{
					margin: 0 0 16px;
					padding: 0 0 16px;
					border-bottom: $border 1px dashed;
                    @media screen and (max-width:$tab){
                        margin: 0 0 12px;
                        padding: 0 0 12px;
                    }
					&:last-of-type{
						margin: 0;
						padding: 0;
						border: none;
					}
					dt{
						font-size: 16px;
						font-weight: 700;
						margin: 0 0 4px;
                        @media screen and (max-width:$tab){
                            font-size: 15px;
                        }
					}
					dd{
						position: relative;
						padding: 0 0 0 24px;
						&:before{
							content: '■';
							color: $dark;
							position: absolute;
							top: 1px;
							left: 0;
						}
					}
				}
			}
		}
		&__list{
			background: #FFF;
			padding: 44px 44px 32px;
			box-shadow: 0 0 32px rgba($normal,.08);
			@media screen and (max-width:$responsive){
				padding: 15px;
				padding-bottom: 8px;
			}
			> li{
				position: relative;
				padding: 0 0 12px;
				padding-left: 40px;
				border-bottom: $border 1px dashed;
				margin: 0 0 12px;
				font-size: 15px;
				&:last-child{
					margin-bottom: 0;
					border: none;
				}
				@for $i from 1 through 10{
					&:nth-child(#{$i}){
						&:before{
							content: '#{$i}';
							width: 24px;
							height: 24px;
							line-height: 24px;
							background: darken($dark, 8%);
							color: #FFF;
							text-align: center;
							position: absolute;
							top: calc(50% - 6px);
							left: 0;
							transform: translateY(-50%);
						}
					}
				}
			}
		}
	}
	
	//ADD SELF WHITENING
	.selfwhite{
		position: relative;
		&:before{
			content: '';
			background: url("../../../images/whitening/self_sky.jpg") no-repeat center top;
			width: 100%;
			height: 260px;
			position: absolute;
			top: 0;
			left: 0;
			@media screen and (max-width:$responsive){
				height: 160px;	
			}
		}
		.whitebox{
			position: relative;
			background: #FFF;
			background: -moz-linear-gradient(top,  rgba(255,255,255,0.8) 0%, rgba(255,255,255,1) 50%);
			background: -webkit-linear-gradient(top,  rgba(255,255,255,0.8) 0%,rgba(255,255,255,1) 50%);
			background: linear-gradient(to bottom,  rgba(255,255,255,0.8) 0%,rgba(255,255,255,1) 50%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccffffff', endColorstr='#ffffff',GradientType=0 );
			padding: 64px 0 0;
			border-radius: 8px 8px 0 0;
			box-shadow: 0 -24px 24px rgba(#000,.08);
			@media screen and (max-width:$responsive){
				padding: 32px 0 0;	
			}
			h2.large{
				background: none;
				padding: 0;
			}
			.context{
				font-size: 17px;
				@media screen and (max-width:$tab){
					font-size: 15px;	
				}
				@media screen and (max-width:$responsive){
					font-size: 13.5px;
					padding: 0 15px;
				}
			}
		}
		.whitelist{
			@include flex();
			margin: 40px 0 0;
			padding: 0 40px;
			@media screen and (max-width:$tab){
				padding: 0 30px;	
			}
			@media screen and (max-width:$responsive){
				padding: 0;
				margin: 16px 0 0;
				display: block;
			}
			li{
				width: 32%;
				border: $glay 4px solid;
				border-radius: 4px;
				padding: 20px;
				margin: 32px 0 0;
				@media screen and (min-width:$tab + 1px){
					&:nth-child(-n+3){
						margin-top: 0;
					}
				}
				@media screen and (max-width:$tab){
					width: 48%;
					&:nth-child(-n+2){
						margin-top: 0;
					}
				}
				@media screen and (max-width:$responsive){
					width: auto;
					margin: 0 0 16px;
					padding: 15px;
					&:last-child{
						margin-bottom: 0;
					}
				}
				i{
					text-align: center;
					width: 48px;
					height: 48px;
					border-radius: 50%;
					background: darken($dark, 12%);
					color: #FFF;
					font-size: 24px;
					line-height: 1;
					margin: 0 auto 12px;
					@include flex();
					align-items: center;
					justify-content: center;
				}
				dl{
					dt{
						text-align: center;
						font-size: 18px;
						font-weight: 700;
						color: darken($dark, 32%);
						margin: 0 0 8px;
					}
					dd{
						text-align: justify;
						line-height: 1.66;
						@media screen and (max-width:$responsive){
							padding: 0 15px;	
						}
					}
				}
			}
		}
		.twoclum{
			position: relative;
			margin: 64px 0 0;
			background: $glay;
			@media screen and (max-width:$tab){
				margin: 48px 0 0;	
			}
			@media screen and (max-width:$responsive){
				margin: 32px 0 0;	
			}
			&__pic{
				position: absolute;
				top: 0;
				left: 0;
				width: 50%;
				height: 100%;
				background: url("../../../images/whitening/self_face.jpg");
				background-size: cover;
				background-position: center;
				@media screen and (max-width:$tab){
					width: 40%;	
				}
				@media screen and (max-width:$responsive){
					position: relative;
					width: auto;
					height: 160px;
				}
			}
			dl{
				width: 50%;
				margin: 0 0 0 50%;
				padding: 88px 0;
				padding-left: 64px;
				@media screen and (max-width:$tab){
					width: 60%;
					margin: 0 0 0 40%;
					padding: 48px 0;
					padding-left: 48px;
				}
				@media screen and (max-width:$responsive){
					width: auto;
					margin: 0;
					padding: 20px 0;
				}
				dt{
					@include min();
					line-height: 1.58;
					font-size: 26px;
					font-weight: 500;
					margin: 0 0 20px;
					padding: 0 0 12px;
					border-bottom: #FFF 4px solid;
					@media screen and (max-width:$tab){
						font-size: 24px;	
					}
					@media screen and (max-width:$responsive){
						font-size: 18px;	
					}
				}
				dd{
					text-align: justify;
				}
			}
		}
		.merit{
			background: $glay;
			background: -moz-linear-gradient(top,  rgba($glay,1) 0%, rgba(255,255,255,1) 100%);
			background: -webkit-linear-gradient(top,  rgba($glay,1) 0%,rgba(255,255,255,1) 100%);
			background: linear-gradient(to bottom,  rgba($glay,1) 0%,rgba(255,255,255,1) 100%);
			border-top: #FFF 8px solid;
			padding: 64px 0 96px;
			@media screen and (max-width:$tab){
				padding: 40px 0;	
			}
			@media screen and (max-width:$responsive){
				padding: 32px 0;	
			}
			.borders{
				@include min();
				font-size: 22px;
				border-bottom: $border 1px solid;
				margin: 0 0 20px;
				padding: 0 0 4px 14px;
				position: relative;
				line-height: 1.5;
				@media screen and (max-width:$responsive){
					font-size: 17px;
				}
				&:before{
					content: '';
					width: 5px;
					height: 70%;
					border-radius: 999px;
					position: absolute;
					top: 10%;
					left: 0;
					background: $normal;
				}
			}
			&__list{
				@include flex();
				li{
					background: #FFF;
					border: $dark 2px solid;
					width: calc(100% / 6 - 10px);
					text-align: center;
					padding: 8px 15px;
                    font-size: 17px;
                    font-weight: 700;
                    color: darken($dark, 32%);
					box-shadow: 0 0 24px rgba($dark,.32);
					@media screen and (max-width:$tab){
						width: 32%;
						margin: 24px 0 0;
						&:nth-child(-n+3){
							margin-top: 0;
						}
					}
					@media screen and (max-width:$responsive){
						width: 48.5%;
						font-size: 15px;
						margin: 0 0 15px;
						&:nth-last-child(-n+2){
							margin-bottom: 0;
						}
					}
				}
			}
			.lasted{
				padding: 32px 24px;
				text-align: center;
				margin: 48px 0 0;
				background: #FFF;
				box-shadow: 0 8px 24px rgba($dark,.48);
				position: relative;
				@media screen and (max-width:$responsive){
					margin: 40px 0 0;
					padding: 15px;
					text-align: justify;
				}
				&:before{
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 20px 24px 20px;
					border-color: transparent transparent #ffffff transparent;
					position: absolute;
					top: -23px;
					left: 50%;
					transform: translateX(-50%);
				}
				.btnarea{
					margin: 16px 0 0;
				}
			}
		}
	}
	
	.ticket{
		&__title{
			@include min();
			font-size: 32px;
			text-align: center;
			margin: 0 0 32px;
			line-height: 1.7;
			@media screen and (max-width:$responsive){
				font-size: 20px;
				margin: 0 0 24px;
			}
			&:after{
				content: attr(data-eng);
				font-size: 12px;
				display: block;
				@media screen and (max-width:$responsive){
					font-size: 11px;	
				}
			}
		}
		&__list{
			@include flex();
			background: $glay;
			padding: 15px;
			margin: 0 0 24px;
			@media screen and (max-width:$responsive){
				display: block;	
			}
			&:last-of-type{
				margin: 0;
			}
			li{
				width: 32%;
				background: #FFF;
				@include flex();
				align-items: center;
				justify-content: center;
				@media screen and (max-width:$tab){
					width: 32.5%;	
				}
				@media screen and (max-width:$responsive){
					width: auto;
					border-bottom: $glay 2px solid;
					padding: 15px;
				}
				figure{
					box-shadow: 0 0 15px rgba($dark,.88);
					@media screen and (max-width:$responsive){
						box-shadow: none;
					}
				}
				img{
					width: 100%;
				}
				dl{
					text-align: center;
					font-weight: 700;
					dt{
						font-size: 17px;
						margin: 0 0 12px;
						@media screen and (max-width:$tab){
							font-size: 14px;	
						}
						@media screen and (max-width:$responsive){
							margin: 0 0 10px;	
						}
						strong{
							font-size: 22px;
							@media screen and (max-width:$tab){
								font-size: 18px;	
							}
						}
					}
					dd{
						line-height: 1;
						&.str{
							margin: 8px 0 0;
							font-size: 32px;
							@media screen and (max-width:$tab){
								font-size: 24px;
							}
							strong{
								font-size: 40px;
								display: inline-block;
								margin: 0 2px;
								@media screen and (max-width:$tab){
									font-size: 32px;	
								}
							}
						}
					}
				}
			}
		}
	}
	.undertext{
		margin: 32px 0 0;
		text-align: center;
		@media screen and (max-width:$responsive){
			margin: 20px 0 0;	
		}
	}
}
