.meijyu-header{
    width: 100%;
    height: $m-head;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #FFF;
    padding: 0 40px;
    @include flex();
    align-items: center;
    justify-content: center;
	@media screen and (max-width:$tab){
		height: $m-sp-head;
	}
	@media screen and (max-width:$responsive){
		position: relative;
	}
    &.active{
        box-shadow: 0 0 32px rgba($m-normal,.12);
    }
    .logo{
        width: 200px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
		@media screen and (max-width:$tab){
			width: 160px;	
		}
        img{
            width: 100%;
        }
    }
    .gnavi{
		@media screen and (max-width:$responsive){
			display: none;
		}
        ul{
            @include flex();
            align-items: center;
            justify-content: center;
			transform: translateX(10px);
			@media screen and (max-width:$tab){
				transform: translateX(8px);
			}
            li{
                margin: 0 24px 0 0;
				@media screen and (max-width:$tab){
					margin: 0 12px 0 0;	
				}
                &:nth-child(3){
                    margin-right: 320px;
					@media screen and (max-width:$tab){
						margin-right: 220px;
					}
					&:after{
						display: none;
					}
                }
                &:last-child{
                    margin: 0;
					&:after{
						display: none;
					}
                }
                &:after{
                    content: '';
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background: $m-green;
                    display: inline-block;
                    margin: 0 0 0 24px;
                    transform: translateY(-2px);
                    opacity: .42;
					@media screen and (max-width:$tab){
						margin: 0 0 0 12px;	
					}
                }

                a{
                    font-size: 15.5px;
                    color: $m-normal;
					@media screen and (max-width:$tab){
						font-size: 14px;	
					}
                }
            }
        }
    }
}
