#site-header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 24px 40px;
    @include flex();
    align-items: center;
    justify-content: flex-start;
    z-index: 9999;
	@media screen and (max-width:$tab){
		padding: 16px;
	}
    @media screen and (min-width:$tab + 1px){
    	&.active{
    		padding-top: 10px;
    		padding-bottom: 10px;
    		position: fixed;
    		background: #FFF;
    		box-shadow: 0 0 32px rgba($normal, .08);
    		animation: shows .6s forwards;
    		@keyframes shows {
    			0%{
    				transform: translateY(-110%);
    			}
    			100%{
    				transform: translateY(0);
    			}
    		}
    		.gnavi{
    			transform: translateY(1px);
    			&__list{
    				li{
    					a{
    						&:before{
    							bottom: -25px;
    						}
    					}
    				}
    			}
    		}
    		.subnavi{
    			top: 20px;
    			> li{
    				&:first-child{
    					a{
    						border: $normal 1px solid;
    					}
    				}
    			}
    		}
    	}
    }
    .logo{
        width: 100px;
		@media screen and (max-width:$tab){
			width: 72px;
			transform: translateY(-1px);
		}
        img{
            width: 100%;
        }
    }

    .open{
        display: none;
        @media screen and (max-width:$tab){
            display: block;
            position: absolute;
            top: 16px;
            right: 16px;
            $size:44px;
            width: $size;
            height: $size;
            @include flex();
            align-items: center;
            justify-content: center;
            background: $normal;
            border-radius: 50%;
            cursor: pointer;
            z-index: 1;
            &:before,
            &:after{
                content: '';
                width: 18px;
                height: 1px;
                background: $glay;
                position: absolute;
                top: 42%;
                left: 50%;
                transform: translateX(-50%);
            }
            &:after{
				top: auto;
				bottom: 42%;
                transform: translateX(-50%);
            }
        }
    }

    .gnavi{
		@include flex();
		padding: 0 0 0 7vw;
		@media screen and (max-width:$tab){
			padding: 0;
			opacity: 0;
			position: relative;
			z-index: 2;
			transition: opacity 800ms;
			&.active{
				opacity: 1;
			}
		}
		.toggle{
			@media screen and (min-width:$tab + 1px){
				display: block !important;
			}
			@media screen and (max-width:$tab){
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: #FFF;
				z-index: 9000;
			}
			&__close{
				display: none;
				@media screen and (max-width:$tab){
					display: block;
					position: absolute;
					top: 16px;
					right: 16px;
					$size:44px;
					width: $size;
					height: $size;
					@include flex();
					align-items: center;
					justify-content: center;
					background: $glay;
					border-radius: 50%;
					cursor: pointer;
					z-index: 2;
					&:before,
					&:after{
						content: '';
						width: 20px;
						height: 1px;
						background: $normal;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translateX(-50%) translateY(-50%) rotate(45deg);
					}
					&:after{
						transform: translateX(-50%) translateY(-50%) rotate(-45deg);
					}
				}
			}
		}
        &__list{
            @include flex();
            justify-content: flex-start;
			transform: translateY(1px);
			@media screen and (max-width:$tab){
				transform: translateY(0);
				align-items: center;
				justify-content: center;
				padding: 72px 0 0;
				height: calc(100% - 150px);
			}
            li{
                list-style: none;
				position: relative;
				margin: 0 4.4vw 0 0;
				@media screen and (max-width:$tab){
					margin: 0;
					width: 100%;
				}
				&:last-child{
					margin: 0;
				}
				&.none{
					display: none;
					@media screen and (max-width:$tab){
						display: block;
					}
				}
				a{
					@include min;
					display: block;
					color: $normal;
					text-align: center;
					font-size: 13.5px;
					line-height: 1;
					letter-spacing: .032em;
                    position: relative;
					@media screen and (max-width:$tab){
						padding: 0 16px;
						font-size: 18px;
					}
					&:after{
						content: attr(data-eng);
						display: block;
						font-size: 8.5px;
						letter-spacing: .12em;
						margin: 12px 0 0;
						@media screen and (max-width:$tab){
							display: none;
						}
					}
                    &:before{
                        content: '';
                        position: absolute;
                        left: 50%;
                        bottom: -8px;
                        width: 0;
                        height: 2px;
                        background: $normal;
                        @include transition(120ms);
						@media screen and (max-width:$tab){
							display: none;
						}
                    }
                    &:hover{
                        &:before{
                            width: 60px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
				}
            }
        }
		&__sp{
			display: none;
			@media screen and (max-width:$tab){
				padding: 16px;
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				display: block;
				> li{
					margin: 0 0 16px;
					&:last-child{
						margin: 0;
					}
					a{
						display: block;
						background: $glay;
						color: $normal;
						font-size: 15px;
						text-align: center;
						padding: 12px 16px;
						i{
							display: inline-block;
							margin: 0 8px 0 0;
						}
					}
				}
			}
		}
    }
	.subnavi{
		position: absolute;
		top: 34px;
		right: 40px;
		@include flex();
		@media screen and (max-width:$tab){
			display: none;
		}
		> li{
			a{
				@include min();
				background: #FFF;
				border: #FFF 1px solid;
				color: $normal;
				display: block;
				padding: 10px 32px;
				position: relative;
				z-index: 1;
				transition: border-color 200ms linear;
				&:before{
					content: '';
					width: 0;
					height: 100%;
					background: $normal;
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
					@include transition(200ms);
				}
				&:hover{
					color: #FFF;
					border-color: $normal;
					&:before{
						width: 100%;
					}
				}
				&.reserv{
					background: $normal;
					color: #FFF;
					border-color: $normal;
					transition: border-color 200ms linear, background 200ms linear;
					&:before{
						display: none;
					}
					&:hover{
						border-color: lighten($normal, 8%);
						background: lighten($normal, 8%);
					}
				}
				i{
					display: inline-block;
					margin: 0 8px 0 0;
				}
			}
		}
	}
}
