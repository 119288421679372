@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');

$m-normal: #424746;
$m-green: #00b898;
$m-yellow: #fcde15;
$m-red: #f76c82;
$m-glay: #eff5f4;

$m-head: 80px;
$m-sp-head: 64px;

@mixin poppin{
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
}

@import "page/meijyu/header";
@import "page/meijyu/footer";
@import "page/meijyu/firstview";

.meijyu-body{
    background: #FFF;
	color: $m-normal;
	a{
		color: $m-normal;
	}
	.meijyu-module{
		&.wrap{
			padding: $pc-head 0;
			@media screen and (max-width:$tab){
				padding: $m-sp-head 0;	
			}
			@media screen and (max-width:$responsive){
				padding: 32px 0;	
			}
            &.border{
                border-bottom: $border 1px solid;
            }
			&.no-top{
				padding-top: 0;
			}
			&.no-bottom{
				padding-bottom: 0;
			}
			&.green{
				background: $m-green;
				color: #FFF;
				&.tx{
					background: $m-green url("../../../../images/meijyu/white_dotto.png") repeat;
					background-size: 16px;
				}
			}
		}
		&.dotto-bg{
			background: url("../../../../images/meijyu/black_dotto.png") repeat;
			background-size: 16px;
            &--darken{
                background: $m-glay url("../../../../images/meijyu/black_dotto.png") repeat;
    			background-size: 16px;
            }
		}
        &.bg{
            background: $m-glay;
        }
		h2{
			text-align: center;
			font-size: 22px;
			font-weight: 700;
			line-height: 1.6;
			margin: 0 0 24px;
			@media screen and (max-width:$responsive){
				font-size: 16px;
				margin: 0 0 16px;
			}
			strong{
				color: $m-green;
			}
			&:before{
				content: attr(data-eng);
				display: block;
				@include poppin();
				font-size: 12px;
				letter-spacing: .24em;
				color: $m-green;
				font-weight: 600;
				margin: 0 0 8px;
			}
            &.large{
                font-size: 38px;
				@media screen and (max-width:$responsive){
					font-size: 24px;	
				}
				@media screen and (max-width:$responsive){
					font-size: 24px;	
				}
                &:before{
                    margin: 0 0 3px;
                }
            }
		}
		h3{
			font-weight: 700;
			font-size: 40px;
			line-height: 1;
			letter-spacing: .12em;
			text-align: center;
			margin: 0 0 32px;
			@media screen and (max-width:$responsive){
				font-size: 24px;
				white-space: nowrap;
			}
		}
        .tablestyle{
            border: none;
            width: 100%;
            border-collapse: collapse;
			@media screen and (max-width:$responsive){
				display: block;	
			}
            tbody{
				@media screen and (max-width:$responsive){
					display: block;
				}
                tr{
                    border-bottom: $border 1px solid;
					@media screen and (max-width:$responsive){
						display: block;	
					}
                    &:last-child{
                        border: none;
                        th,td{
                            padding-bottom: 0;
                        }
                    }
                    th,td{
                        padding: 32px 0;
                        font-size: 15px;
                        text-align: left;
                        vertical-align: middle;
						@media screen and (max-width:$responsive){
							padding: 8px 0;
							font-size: 13px;
							display: block;
						}
                    }
                    th{
                        font-weight: 700;
                        color: $m-green;
                        font-size: 20px;
                        width: 300px;
						@media screen and (max-width:$responsive){
							font-size: 15px;
							width: auto;
							padding-bottom: 0;
						}
                    }
                    td{
                        padding-left: 40px;
						@media screen and (max-width:$responsive){
							padding-left: 0;	
						}
                        strong{
                            font-weight: 700;
                            font-size: 28px;
                            line-height: 1;
                            color: $m-red;
                            margin: 0 0 8px;
							@media screen and (max-width:$tab){
								font-size: 20px;	
							}
                        }
                        dl{
                            dt{
                                font-weight: 700;
                                font-size: 28px;
                                line-height: 1;
                                color: $m-red;
                                margin: 0 0 8px;
								@media screen and (max-width:$tab){
									font-size: 20px;	
								}
                            }
							&.contact{
								@include flex();
								align-items: center;
								justify-content: flex-start;
								dt,dd{
									font-size: 14.5px;
									font-weight: 400;
									line-height: 1.5;
									margin: 0;
								}
								dt{
									color: $normal;
									margin: 0 20px 0 0;
									width: 130px;
									background: darken($glay,4%);
									padding: 3px 12px;
									text-align: center;
									font-weight: 700;
								}
								dd{
									a{
										color: $m-green;
										text-decoration: underline;
									}
								}
							}
                        }
						dl.timer{
							@include flex();
							align-items: center;
							justify-content: flex-start;
							dt,dd{
								font-size: 16px;
							}
							dt{
								margin: 0;
								width: 100px;
							}
							dd{
								&:after{
									content: '|';
									margin: 0 15px;
									display: inline-block;
									opacity: .24;
								}
								&:last-of-type{
									&:after{
										display: none;
									}
								}
							}
						}
                    }
                }
            }
            &--ss{
                tbody{
                    tr{
                        th{
                            width: 150px;
							@media screen and (max-width:$responsive){
								width: auto;	
							}
                        }
                    }
                }
            }
            &--border{
                border: $border 1px solid;
                tbody{
                    tr{
						@media screen and (max-width:$responsive){
							padding: 5px 0;	
						}
                        &:last-child{
                            th,td{
                                padding-bottom: 32px;
								@media screen and (max-width:$responsive){
									padding-bottom: 5px;	
								}
                            }
                        }
                        th,
                        td{
                            padding: 32px 40px;
							@media screen and (max-width:$responsive){
								padding: 5px 15px;	
							}
                        }
                        th{
                            width: 200px;
							@media screen and (max-width:$responsive){
								width: auto;
								padding-bottom: 0 !important;
							}
                        }
                    }
                }
            }
        }
		.context{
			text-align: center;
			font-size: 15px;
			@media screen and (max-width:$responsive){
				font-size: 13px;	
			}
			&.glay{
				background: $m-glay;
				padding: 15px 24px;
			}
            &--ll{
                font-size: 18px;
				@media screen and (max-width:$responsive){
					font-size: 13px;	
				}
            }
		}
		@for $i from 0 through 11{
			.mt-px-#{$i}{
				margin-top: $i * 8px;
				@media screen and (max-width:$tab){
					margin-top: $i * 6px;
				}
				@media screen and (max-width:$responsive){
					margin-top: $i * 4px;
				}
			}
			.mb-px-#{$i}{
				margin-bottom: $i * 8px;
				@media screen and (max-width:$tab){
					margin-bottom: $i * 6px;
				}
				@media screen and (max-width:$responsive){
					margin-bottom: $i * 4px;
				}
			}
			.pt-px-#{$i}{
				padding-top: $i * 8px;
				@media screen and (max-width:$tab){
					padding-top: $i * 6px;
				}
				@media screen and (max-width:$responsive){
					padding-top: $i * 4px;
				}
			}
			.pb-px-#{$i}{
				padding-bottom: $i * 8px;
				@media screen and (max-width:$tab){
					padding-bottom: $i * 6px;
				}
				@media screen and (max-width:$responsive){
					padding-bottom: $i * 4px;
				}
			}
		}

	}
}

.meijyu-article{
    position: relative;
    top: $m-head;
    left: 0;
	@media screen and (max-width:$tab){
   		top: $m-sp-head;
	}
	@media screen and (max-width:$responsive){
		top: 0;	
	}
	.first{
		position: relative;
		&:after{
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 24px 24px 0 24px;
			border-color: #ffffff transparent transparent transparent;
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%) translateY(100%);
		}
		.clums{
			position: relative;
			&:before{
				content: '';
				width: 40%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 60%;
				background: $glay;
				@media screen and (max-width:$tab){
					left: 50%;
					width: 50%;
				}
				@media screen and (max-width:$responsive){
					display: none;
				}
			}
			&__pic{
				width: 60%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				@media screen and (max-width:$tab){
					width: 50%;
				}
				@media screen and (max-width:$responsive){
					width: auto;
					height: calc(100vw / 16 * 8);
					position: relative;
				}
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: right center;
					@media screen and (max-width:$tab){
						object-position: center;
					}
				}
			}
			&__data{
				width: 40%;
				margin: 0 0 0 50%;
				padding: 8vh 40px;
				padding-left: 0;
				position: relative;
				z-index: 2;
				@media screen and (max-width:$tab){
					width: 50%;
				}
				@media screen and (max-width:$responsive){
					width: auto;
					margin: 32px 20px 0;
					padding: 0;
				}
				&__box{
					padding: 40px;
					max-width: 620px;
					min-width: 520px;
					background: #FFF;
					border-radius: 3px;
					box-shadow: 0 0 32px rgba(#000,.04);
					@media screen and (max-width:$tab){
						max-width: 100%;
						min-width: 1px;
					}
					@media screen and (max-width:$responsive){
						box-shadow: none;
						padding: 0 0 32px;
					}
					dl{
						line-height: 1.3;
						@media screen and (max-width:$responsive){
							text-align: center;	
						}
						dt{
							font-size: 28px;
							font-weight: 700;
							margin: 0 0 2px;
							@media screen and (max-width:$tab){
								font-size: 20px;
								margin: 0 0 4px;
							}
							.plus{
								display: inline-block;
								margin: 0 8px;
								width: 24px;
								height: 24px;
								position: relative;
								vertical-align: middle;
								transform: translateY(-4px);
								@media screen and (max-width:$tab){
									width: 16px;
									height: 16px;
									transform: translateY(-2px);
								}
								&:before,
								&:after{
									content: '';
									width: 100%;
									height: 6px;
									background: $m-green;
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									@media screen and (max-width:$tab){
										height: 4px;	
									}
								}
								&:after{
									transform:translate(-50%, -50%) rotate(90deg);
								}
							}
						}
						dd{
							color: $m-green;
							font-size: 56px;
							font-weight: 700;
							@media screen and (max-width:$tab){
								font-size: 32px;	
							}
						}
					}
					p{
						margin: 16px 0 0;
						font-size: 15.5px;
						text-align: justify;
						@media screen and (max-width:$tab){
							font-size: 14px;	
						}
					}
					ul{
						margin: 24px 0 0;
						background: $glay;
						padding: 20px;
						@include flex();
						@media screen and (max-width:$tab){
							padding: 15px;
							font-size: 12px;
						}
						li{
							width: 50%;
							.in{
								display: inline-block;
								margin: 0 8px 0 0;
								color: $m-green;
								font-weight: 700;
							}
						}
					}
				}
			}
		}
	}
	.second{
		.box{
			border: #FFF 1px dashed;
			color: $m-normal;
			border-radius: 8px;
			padding: 10px;
			position: relative;
			@media screen and (max-width:$responsive){
				padding: 5px;	
			}
			&__inner{
				background: #FFF;
				border-radius: 6px;
				overflow: hidden;
				padding: 24px;
				box-shadow: 0 0 20px rgba($normal,.12);
				@media screen and (max-width:$tab){
					padding: 15px;	
				}
			}
			h4{
				font-size: 36px;
				font-weight: 700;
				position: relative;
				margin: 8px 0 16px;
				@media screen and (max-width:$tab){
					font-size: 24px;
					margin: 4px 0 8px;
				}
				&:before{
					content: '';
					width: 10px;
					height: 10px;
					background: $m-yellow;
					position: absolute;
					top: 18px;
					left: 24px;
					transform: rotate(45deg);
					z-index: 3;
					@media screen and (max-width:$tab){
						width: 8px;
						height: 8px;
						top: 15px;
						left: 16px;	
					}
				}
				i{
					display: inline-block;
					margin: 0 14px 0 0;
					font-size: 32px;
					color: $m-green;
					transform: translateY(-1px);
					@media screen and (max-width:$tab){
						font-size: 24px;	
					}
				}
			}
			.clum{
				@include flex();
				@media screen and (max-width:$responsive){
					display: block;	
				}
				&__pic{
					width: 400px;
					border-radius: 5px;
					overflow: hidden;
					@media screen and (max-width:$tab){
						width: 280px;
					}
					@media screen and (max-width:$responsive){
						width: 100%;
					}
					img{
						width: 100%;
					}
				}
				&__data{
					width: calc(100% - 400px);
					padding: 0 0 0 32px;
					@media screen and (max-width:$tab){
						width: calc(100% - 280px);	
					}
					@media screen and (max-width:$responsive){
						width: auto;
						padding: 15px 0 0;
					}
					&__description{
						background: $m-glay;
						padding: 18px;
						border-radius: 4px;
						font-size: 15px;
						line-height: 1.66;
						a{
							color: $m-green;
							font-weight: 700;
							text-decoration: underline;
							cursor: pointer;
						}
						@media screen and (max-width:$tab){
							padding: 15px;
							font-size: 14px;
						}
						@media screen and (max-width:$responsive){
							font-size: 13px;	
						}
						strong{
							color: $m-red;
							font-weight: 700;
						}
					}
				}
			}
			.kugiri{
				padding: 20px 0 0;
				margin: 20px 0 0;
				border-top: $border 1px solid;
				@media screen and (max-width:$tab){
					margin: 15px 0 0;
					padding: 15px 0 0;
				}
			}
			.catch{
				width: 100%;
				text-align: center;
				img{
					max-width: 100%;
				}
			}
			.flex{
				@include flex();
				@media screen and (max-width:$responsive){
					display: block;	
				}
				&__harf{
					width: calc(50% - 15px);
					@media screen and (max-width:$tab){
						width: calc(50% - 7.5px);	
					}
					@media screen and (max-width:$responsive){
						width: auto;	
					}
				}
			}
			h5{
                background: $m-green;
                color: #FFF;
                text-align: center;
                font-size: 22px;
                font-weight: 700;
                padding: 3px 15px;
				@media screen and (max-width:$tab){
					font-size: 18px;	
				}
			}
			.dataset{
				dt{
					background: $m-green;
					color: #FFF;
					text-align: center;
					font-size: 22px;
					font-weight: 700;
					padding: 3px 15px;
					@media screen and (max-width:$tab){
						font-size: 18px;
					}
				}
				dd{
					line-height: 1.66;
					font-size: 15px;
					@media screen and (max-width:$tab){
						font-size: 14px;	
					}
					ol{
						list-style: none;
						li{
							position: relative;
							padding: 0 0 0 32px;
							font-size: 17px;
							margin: 0 0 8px;
							@media screen and (max-width:$tab){
								font-size: 15px;	
							}
							@for $i from 1 through 10{
								&:nth-child(#{$i}):before{
									content: '#{$i}';
									width: 22px;
									height: 22px;
									line-height: 22px;
									text-align: center;
									background: $m-red;
									color: #FFF;
									position: absolute;
									top: 4px;
									left: 0;
									font-size: 14px;
									border-radius: 50%;
									@media screen and (max-width:$tab){
										width: 20px;
										height: 20px;
										line-height: 20px;
									}
								}
							}
						}
					}
				}
			}
			.dataflow{
				dl{
					border: darken($m-glay, 4%) 4px solid;
					padding: 15px 20px;
					margin: 0 0 32px;
					position: relative;
					&:after{
						content: '';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 24px 32px 0 32px;
						border-color: darken($m-glay, 4%) transparent transparent transparent;
						position: absolute;
						left: 50%;
						bottom: -24px;
						transform: translateX(-50%);
					}
					&:last-of-type{
						margin: 0;
						&:after{
							display: none;
						}
					}
					dt{
						font-size: 17px;
						font-weight: 700;
						color: $m-red;
					}
					dd{
						font-size: 15px;
						line-height: 1.66;
						strong{
							color: $m-green;
							font-size: 20px;
							display: block;
							line-height: 1;
						}
					}
				}
			}
		}
	}
}

.meijyu-contact{
    .views{
        position: fixed;
        bottom: 24px;
        right: 24px;
        background: #FFF;
        box-shadow: 0 0 32px rgba($m-normal,.2);
    	width: 240px;
    	z-index: 1000;
        border-radius: 6px;
		@media screen and (max-width:$tab){
			bottom: 15px;
			right: 15px;
			width: 200px;
		}
		@media screen and (max-width:$responsive){
			width: 160px;	
		}
        &:after{
    		content: '';
    		width: 55px;
    		height: 55px;
    		background: url("../../../../images/meijyu/contact_bnr_p.png") no-repeat;
    		background-size: 55px;
    		position: absolute;
    		top: -12px;
    		left: 32px;
    		z-index: 2;
			@media screen and (max-width:$tab){
				transform: scale(.64);
				top: -24px;
				left: 24px;
			}
			@media screen and (max-width:$responsive){
				left: 16px;	
			}
    	}
    }
	.close{
		$size: 36px;
		width: $size;
		height: $size;
		border-radius: 50%;
		background: #FFF;
		position: absolute;
		@include flex();
		align-items: center;
		justify-content: center;
		top: -8px;
		right: -8px;
		cursor: pointer;
		i{
			opacity: .32;
		}
	}
	figure{
		border-radius: 6px 6px 0 0;
		overflow: hidden;
		img{
			width: 100%;
		}
	}
	a{
		color: $m-normal;
		display: block;
		&:hover{
			img{
				opacity: 1;
			}
		}
	}
	dl{
		padding: 15px 15px 12px;
		line-height: 1;
		text-align: center;
		letter-spacing: 0em;
		dt{
			font-size: 13px;
			margin: 0 0 6px;
			@media screen and (max-width:$tab){
				font-size: 11px;	
			}
			@media screen and (max-width:$responsive){
				font-size: 10px;	
			}
		}
		dd{
			@include poppin();
			font-weight: 700;
			color: $m-normal;
			font-size: 20px;
			@media screen and (max-width:$tab){
				font-size: 16px;	
			}
			@media screen and (max-width:$responsive){
				font-size: 13px;	
			}
			> i{
				transform: rotateY(180deg) translateY(-1px);
				color: $m-green;
				display: inline-block;
				margin: 0 6px 0 0;
			}
		}
	}
}

.meijyu-pricebox{
    border: $m-green 3px solid;
    border-radius: 6px;
    position: relative;
    padding: 40px;
    background: #FFF;
	@media screen and (max-width:$tab){
		padding: 24px;
	}
	@media screen and (max-width:$responsive){
		padding: 15px;	
	}
    h3{
        background: #FFF;
        font-size: 24px !important;
        color: $m-green;
        font-weight: 700;
        position: absolute;
        padding: 0 24px;
        letter-spacing: 0em;
        top: 0;
        left: 50%;
        line-height: 1;
        transform: translateX(-50%) translateY(-50%);
		@media screen and (max-width:$tab){
			font-size: 20px !important;	
		}
    }
    &__alert{
        margin: 40px 0 0;
        padding: 16px 24px;
        font-size: 13.5px;
        background: $m-glay;
		@media screen and (max-width:$tab){
			margin: 20px 0 0;
			padding: 15px;
		}
    }
    &__txt{
        text-align: center;
        font-size: 18px;
		@media screen and (max-width:$tab){
			font-size: 16px;	
		}
    }
}

.meijyu-photolist{
    @include flex();
    margin: 0 0 0 -25px;
	@media screen and (max-width:$tab){
		margin: 0 0 0 -15px;	
	}
    li{
        width: 33.3%;
        padding: 0 0 0 25px;
        margin: 25px 0 0;
		@media screen and (min-width:$tab + 1px){
			&:nth-child(-n+3){
				margin-top: 0;
			}
		}
		@media screen and (max-width:$tab){
			width: 50%;
			padding: 0 0 0 15px;
			margin: 15px 0 0;
			&:nth-child(-n+2){
				margin-top: 0;
			}
		}
        figure{
            background: #FFF;
            padding: 8px;
            box-shadow: 0 5px 20px rgba($m-normal,.08);
            img{
                width: 100%;
            }
            figcaption{
                text-align: center;
                margin: 8px 0 0;
            }
        }
    }
}
.meijyu-map{
    iframe{
        width: 100%;
        vertical-align: bottom;
		@media screen and (max-width:$responsive){
			height: 180px;	
		}
    }
}

.meijyu-news{
    position: relative;
    z-index: 2;
    background: $m-green;
    color: #FFF;
    padding: 80px 0;
    @media screen and (max-width:$tab){
        padding: 56px 0;
    }
    @media screen and (max-width:$responsive){
        padding: 32px 0;
    }
    .display{
        &.flex{
            @include flex();
            @media screen and (max-width:$responsive){
                display: block;
            }
        }
    }
    .newsbox{
        width: 100%;
        @include flex();
        @media screen and (max-width:$responsive){
            width: 100%;
            padding: 0;
            display: block;
        }
    }
    .leftside{
        width: 280px;
        @include min();
        @media screen and (max-width:$responsive){
            width: 100%;
        }
        h3{
            line-height: 1;
            font-size: 14px;
            margin: 0 0 16px;
            text-align: left;
            @media screen and (max-width:$responsive){
                font-size: 12px;
            }
            &:before{
                content: attr(data-eng);
                font-size: 36px;
                display: inline-block;
                margin: 0 24px 0 0;
                @media screen and (max-width:$responsive){
                    font-size: 20px;
                }
            }
        }
        a{
            display: inline-block;
            color: #FFF;
            font-size: 15px;
            @media screen and (max-width:$responsive){
                position: absolute;
                top: 0;
                right: 16px;
                font-size: 13px;
            }
            &:hover{
                text-decoration: underline;
            }
            i{
                display: inline-block;
                font-size: 13px;
                margin: 0 0 0 12px;
            }
        }
    }
    ol{
        width: calc(100% - 280px);
        height: 130px;
        padding: 0 40px 0 0;
        overflow: auto;
        @media screen and (max-width:$tab){
            height: 150px;
        }
        @media screen and (max-width:$responsive){
            width: 100%;
            height: 164px;
            padding: 15px;
            border: rgba(#FFF,.2) 1px solid;
            border-radius: 4px;
        }
        li{
            border-bottom: rgba(#FFF,.06) 1px solid;
            padding: 0 0 24px;
            margin: 0 0 24px;
            @media screen and (max-width:$tab){
                margin: 0 0 15px;
                padding: 0 0 15px;
            }
            &:last-child{
                margin: 0;
            }
            a{
                @include flex();
                align-items: center;
                color: #FFF;
                @media screen and (max-width:$tab){
                    display: block;
                }
                &:hover{
                    h4{
                        text-decoration: underline;
                    }
                }
            }
            span.time{
                width: 14%;
                font-size: 12px;
                background: darken($m-green, 12%);
                color: #FFF;
                padding: 2px 0;
                text-align: center;
                @media screen and (max-width:$tab){
                    width: 115px;
                    display: inline-block;
                    font-size: 12px;
                    padding: 1px 12px;
                    margin: 2px 0 0;
                }
            }
            h4{
                width: 86%;
                font-size: 16px;
                padding: 0 0 0 32px;
                @media screen and (max-width:$tab){
                    width: auto;
                    font-size: 14px;
                    padding: 8px 0 0;
                    clear: both;
                }
            }
        }
    }
}
