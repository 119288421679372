.asunaro-header{
	background: #FFF;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: $asunaro-head;
	@include flex();
	align-items: center;
	padding: 0 40px;
	z-index: 1000;
	@include transition(200ms);
	@media screen and (max-width:$responsive){
		padding: 0 15px;
	}
	&.active{
		box-shadow: 0 0 32px rgba($asunaro-normal,.1);
	}
	.logo{
		height: 42px;
		@media screen and (max-width:$responsive){
			height: 36px;	
		}
		img{
			height: 100%;
		}
	}
	.gnavi{
		ul{
			@include flex();
			align-items: center;
			@media screen and (max-width:$responsive){
				display: block;	
			}
			li{
				list-style: none;
				margin: 0 0 0 48px;
				font-size: 15px;
				@media screen and (max-width:$tab){
					display: none;
					&:nth-last-child(-n+2){
						display: block;
					}
				}
				@media screen and (max-width:$responsive){
					margin: 0 0 4px;	
				}
				&:first-child{
					margin: 0;
				}
				&:last-child{
					margin-left: 8px;
					@media screen and (max-width:$responsive){
						margin: 0;	
					}
				}
				a{
					color: $normal;
					&.btn{
						background: $asunaro-glay;
						padding: 6px 32px;
						display: block;
						@include transition(200ms);
						@media screen and (max-width:$responsive){
							font-size: 12px;
							padding: 2px 15px;
							min-width: 130px;
							text-align: center;
						}
						&:hover{
							color: #FFF;
							background: $asunaro-blue;
							> i{
								color: #FFF;
							}
						}
						i{
							display: inline-block;
							margin: 0 0 0 8px;
							font-size: 11px;
							vertical-align: middle;
							color: $asunaro-blue;
							transform: translateY(-1px);
							@include transition(200ms);
						}
					}
				}
			}
		}
	}
}